/* eslint-disable no-console */
import React, { useCallback, useContext } from 'react'

import { PageIndexesContext, PaymentInfoContext } from 'v3/containers/overhaul-rent-payment/context'
import {
  feeField,
  rentField,
} from 'v3/containers/overhaul-rent-payment/pages/payment-details/constants'
import { FeePaymentComponent } from 'v3/containers/overhaul-rent-payment/pages/payment-details/page-states/add-fee'
import { RentPaymentComponent } from 'v3/containers/overhaul-rent-payment/pages/payment-details/page-states/add-rent'
import { LandingPageComponent } from 'v3/containers/overhaul-rent-payment/pages/payment-details/page-states/landing'

export const selectionField = 'payment-selections'
export const paymentsField = 'payments'
export const handlePaymentDetailsProgression = (
  { increment = false, decrement = false },
  curPageIndexes,
  setPageIndexes,
  pageField,
  curPaymentInfo,
) => {
  const indexChanges = {
    pageL1Index: curPageIndexes.pageL1Index,
    pageL2Index: curPageIndexes.pageL2Index,
    drawerOpen: curPageIndexes.drawerOpen,
  }
  let shouldSaveData = false
  console.log(`${pageField}: ${JSON.stringify({ increment, decrement })}`)
  console.log(`${pageField}: ${JSON.stringify(curPageIndexes)}`)

  const selections = curPaymentInfo?.[pageField]?.[selectionField] || {
    [rentField]: false,
    [feeField]: false,
  }
  const numPagePanels = 1 + selections[rentField] + selections[feeField]

  // shortcut invalid scenarios
  if (increment === decrement) {
    /*
     * logging an error, but this can still be consumer facing, so staying vague
     * need to find direct DD log avenue for debug details if possible
     */
    console.error('Unusual state for Payment Details page progression request')
  } else {
    if (increment) {
      // always start by assuming L2 progression
      indexChanges.pageL2Index += 1
      if (indexChanges.pageL2Index >= numPagePanels) {
        console.log(`${pageField}: progressing the page!`)
        indexChanges.pageL1Index += 1
        shouldSaveData = true
      } else {
        console.log(`${pageField}: progressing the sub-page...`)
      }
      /*
       * TODO: drawer opening?
       * JB and I were talking about controlling that via sub-page logic
       */
    }
    if (decrement) {
      indexChanges.pageL2Index -= 1
      if (indexChanges.pageL2Index < 0) {
        console.log(`${pageField}: going back a page...`)
        indexChanges.pageL1Index -= 1
        // quick don't-send-invalid-data reset
        indexChanges.pageL2Index = 0
      } else {
        console.log(`${pageField}: going back a sub-page...`)
      }
    }
  }

  /*
   * All decrement states for this page involve going back to the root of the flow
   * Absorbing errored-state traversal handling as well
   */
  console.log(`${pageField}: ${JSON.stringify(indexChanges)}`)
  setPageIndexes(indexChanges)
  return shouldSaveData
}

// eslint-disable-next-line no-unused-vars
export const PaymentDetailsPage = ({ pageField, updateDataHandler }) => {
  const [pageIndexes, setPageIndexes] = useContext(PageIndexesContext)
  const [paymentInfo] = useContext(PaymentInfoContext)

  const setDrawerOpen = isOpen => {
    setPageIndexes({ drawerOpen: isOpen })
  }

  const selectionOptions = Object.freeze({
    [rentField]: 'Rent',
    [feeField]: 'Deposits & other fees',
  })

  // eslint-disable-next-line no-unused-vars
  const pageInfo = paymentInfo?.[pageField] || {}
  const payments = pageInfo?.[paymentsField] || []
  const paymentSelections = pageInfo?.[selectionField] || {
    [rentField]: false,
    [feeField]: false,
  }

  console.log(payments)

  const setNextPayments = nextPayments => {
    updateDataHandler({
      ...pageInfo,
      [paymentsField]: nextPayments,
    })
  }

  const setPaymentSelections = (nextSelections = {}) => {
    const filteredNextSelections = {
      [rentField]: nextSelections?.[rentField] || false,
      [feeField]: nextSelections?.[feeField] || false,
    }
    updateDataHandler({
      ...pageInfo,
      [selectionField]: filteredNextSelections,
    })
  }

  const getCurrentPage = useCallback(
    subPageIndex => {
      const landingPageComponent = LandingPageComponent

      const pagesArray = [landingPageComponent]
      if (paymentSelections[rentField]) {
        pagesArray.push(RentPaymentComponent)
      }
      if (paymentSelections[feeField]) {
        pagesArray.push(FeePaymentComponent)
      }

      // default to LandingPageComponent on errors
      return subPageIndex <= pagesArray.length ? pagesArray[subPageIndex] : landingPageComponent
    },
    [paymentSelections[rentField], paymentSelections[feeField]],
  )

  const CurrentPage = getCurrentPage(pageIndexes.pageL2Index)
  return (
    <CurrentPage
      payments={payments}
      pageIndexes={pageIndexes}
      setDrawerOpen={setDrawerOpen}
      selectionOptions={selectionOptions}
      paymentSelections={paymentSelections}
      setPaymentSelections={changes => {
        setPaymentSelections({ ...changes })
      }}
      setPayments={nextPayments => {
        setNextPayments([...nextPayments])
      }}
    />
  )
}
