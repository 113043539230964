import { useEffect } from 'react'
import { useExperiment, useGate } from 'statsig-react'

import { EXPLORE_PAGE } from 'constants/route'
import { amplitudeTracker } from 'tracker'
import {
  NEW_AGENT_DASHBOARD_FOR_NEW_ORGANIC_SIGNUP_EXPERIMENT_ITR_1,
  NEW_DASHBOARD_FOR_EXISTING_USER_WITH_AT_LEAST_ONE_SCREENING,
} from 'utils/feature-flag/constants'
import { PATH_HOME_PAGE } from 'v3/routers/path'

import { useGetMyUserInvitation } from './use-get-my-invitations'

const NEW_AGENT_DASHBOARD_FOR_NEW_ORGANIC_SIGNUP_EXPERIMENT =
  'new_agent_dashboard_for_new_organic_signup_experiment'

export const useDashboard = () => {
  const {
    data: myInvitation,
    isLoading: myInvitationLoading,
    isFetched: myInvitationFetched,
  } = useGetMyUserInvitation()
  const isInvitedUser = !!myInvitation?.result?.registeredUserId

  const { config: experimentConfig } = useExperiment(
    NEW_AGENT_DASHBOARD_FOR_NEW_ORGANIC_SIGNUP_EXPERIMENT,
  )
  const { value: shouldShowNewDashboardToExistingUser } = useGate(
    NEW_DASHBOARD_FOR_EXISTING_USER_WITH_AT_LEAST_ONE_SCREENING,
  )
  const { config } = useExperiment(NEW_AGENT_DASHBOARD_FOR_NEW_ORGANIC_SIGNUP_EXPERIMENT_ITR_1)
  const { config: experiment } = useExperiment(
    NEW_AGENT_DASHBOARD_FOR_NEW_ORGANIC_SIGNUP_EXPERIMENT_ITR_1,
  )
  const newAgentDashboardExperimentValue = experiment.get('experiment_value', null)
  useEffect(() => {
    if (newAgentDashboardExperimentValue) {
      // NOTE: to any feature wanted to have `experiment_value` in this page, please add value in the array
      amplitudeTracker
        .getTracker()
        .setUserProperties({ experiment_value: [newAgentDashboardExperimentValue] })
    }
  }, [newAgentDashboardExperimentValue])

  if (!myInvitationFetched || myInvitationLoading) {
    return { isLoading: myInvitationLoading }
  }

  // The existing user should not aware of any experimentConfig or properties
  if (myInvitationFetched && isInvitedUser) {
    return { isNewHome: true }
  }

  const isNewHomeForAgentITR1 = config.get('landing_page') === PATH_HOME_PAGE
  if (isNewHomeForAgentITR1) {
    return { isNewHome: true }
  }

  switch (experimentConfig.get('path', 'default')) {
    case PATH_HOME_PAGE:
      return { isNewHome: true }
    case EXPLORE_PAGE:
      return { isNewHome: false }
    default:
      return shouldShowNewDashboardToExistingUser ? { isNewHome: true } : { isNewHome: false }
  }
}
