export const REPORT_TYPES = {
  ALL: 'all',
  APPLICATION: 'application',
  CREDIT_REPORT: 'credit-report',
  CRIMINAL: 'criminal',
  EVICTION: 'eviction',
  REFCHECKS: 'reference-checks',
  OTHER_DOCS: 'documents',
  LRA: 'lra',
  INCOME_ESTIMATE: 'income-estimate',
  INCOME_VERIFICATION: 'income',
}

export const SHARED_REPORT_TYPES = {
  APPLICATION: 'application',
  SCREENING_REPORT: 'screeningReport',
  REFERENCE_CHECK: 'referenceCheck',
  OTHER_DOCS: 'otherDocs',
  INCOME_VERIFICATION_REPORT: 'incomeVerificationReport',
}

export const SCREENING_OPTION_TYPES = {
  APPLICATION: 'application',
  CREDIT_REPORT: 'credit_report',
  CRIMINAL: 'criminal',
  EVICTION: 'eviction',
  REFERENCE_CHECKS: 'reference-checks',
  INCOME: 'income',
}

export const SCREENING_REPORT_TYPES = {
  CREDIT_REPORT: 'credit_report',
  CRIMINAL: 'criminal',
  EVICTION: 'eviction',
}

const {
  ALL,
  APPLICATION,
  CREDIT_REPORT,
  CRIMINAL,
  EVICTION,
  REFCHECKS,
  OTHER_DOCS,
  INCOME_ESTIMATE,
  INCOME_VERIFICATION,
} = REPORT_TYPES

export const GRANTING_ROUTE_PATHS = {
  appDetailReportPaths: `${ALL}|${APPLICATION}|${CREDIT_REPORT}|${CRIMINAL}|${EVICTION}|${REFCHECKS}|${OTHER_DOCS}|${INCOME_ESTIMATE}|${INCOME_VERIFICATION}`,
}

export const HIDE_CTA_OVERLAY = [REFCHECKS, OTHER_DOCS, INCOME_ESTIMATE]
export const HIDE_DESKTOP_REPORT_ON_MOBILE = [
  REFCHECKS,
  OTHER_DOCS,
  INCOME_ESTIMATE,
  INCOME_VERIFICATION,
]

export const ROUTE_REPORT = {
  DOCUMENTS: '/documents',
  REFERENCE_CHECKS: '/reference-checks',
  INCOME_ESTIMATE: '/income-estimate',
}

export const FULL_PATH_ROUTE_REPORT = {
  REFERENCE_CHECKS: '/properties/:propertyId/rental/:rentalAppId/reference-checks',
}

export const CONTAINERS = {
  APPLICATION_DETAIL: 'applicationDetail',
}

export const PARTICIPANT_TYPE = {
  PARTICIPANT: 'participant',
  OWNER: 'owner',
}

export const TYPES = {
  SAVE_PDF_CALL: 'SAVE_PDF_CALL',
  SAVE_PDF_REQUEST: 'SAVE_PDF_REQUEST',
  SAVE_PDF_SUCCESS: 'SAVE_PDF_SUCCESS',
  SAVE_PDF_FAILURE: 'SAVE_PDF_FAILURE',
  PRINT_REPORT: 'PRINT_REPORT',
  GET_RENTAL_SUBMISSION_CALL: 'GET_RENTAL_SUBMISSION_CALL',
  GET_RENTAL_BY_ID_REQUEST: 'GET_RENTAL_BY_ID_REQUEST',
  GET_RENTAL_BY_ID_SUCCESS: 'GET_RENTAL_BY_ID_SUCCESS',
  GET_RENTAL_BY_ID_FAILED: 'GET_RENTAL_BY_ID_FAILED',
  GET_SUBMISSION_PARTICIPANT_REQUEST: 'GET_SUBMISSION_PARTICIPANT_REQUEST',
  GET_SUBMISSION_PARTICIPANT_SUCCESS: 'GET_SUBMISSION_PARTICIPANT_SUCCESS',
  GET_SUBMISSION_PARTICIPANT_FAILED: 'GET_SUBMISSION_PARTICIPANT_FAILED',
  GET_SUBMISSION_PARTICIPANT_BY_EMAIL_CALL: 'GET_SUBMISSION_PARTICIPANT_BY_EMAIL_CALL',
  GET_SUBMISSION_PARTICIPANT_BY_EMAIL_REQUEST: 'GET_SUBMISSION_PARTICIPANT_BY_EMAIL_REQUEST',
  GET_SUBMISSION_PARTICIPANT_BY_EMAIL_SUCCESS: 'GET_SUBMISSION_PARTICIPANT_BY_EMAIL_SUCCESS',
  GET_SUBMISSION_PARTICIPANT_BY_EMAIL_FAILURE: 'GET_SUBMISSION_PARTICIPANT_BY_EMAIL_FAILURE',
}

export const CLICK_REPORT_FROM = {
  HEADER: 'report_header',
  OVERLAY_BUTTON: 'float_icon',
}

export const mapReportType = reportType => {
  const mapType = {
    [REPORT_TYPES.APPLICATION]: 'application-report',
    [REPORT_TYPES.CREDIT_REPORT]: 'credit-report',
    [REPORT_TYPES.CRIMINAL]: 'criminal-report',
    [REPORT_TYPES.EVICTION]: 'eviction-report',
    [REPORT_TYPES.ALL]: 'all-report',
    [REPORT_TYPES.REFCHECKS]: 'reference-report',
    [REPORT_TYPES.INCOME_VERIFICATION]: 'income-report',
  }
  return mapType[reportType]
}

export const mapTrackReportProps = ({ 
  reportName,
  clickFrom,
  isPrintPage,
  isMultiShare = undefined,
  pageUrl = undefined,
  hasBeenShared = undefined,
  hasScreeningReport = undefined,
}) => ({
  report_name: reportName,
  click_from: clickFrom,
  page: mapReportPageProps(isPrintPage),
  is_multi_share: isMultiShare ? "yes" : "no",
  page_url: pageUrl,
  has_been_shared: hasBeenShared,
  has_screening_report: hasScreeningReport,
})

export const mapDownloadReportParams = ({
  rentalId,
  applicantEmail,
  device,
  clickFrom,
  isPrintPage,
  propertyId,
  appType,
  envelopeId,
}) => ({
  rentalSubmissionId: rentalId,
  propertyId,
  applicant: applicantEmail,
  device,
  click_from: clickFrom,
  page: mapReportPageProps(isPrintPage),
  appType,
  envelopeId,
})

const mapReportPageProps = isPrintPage => (isPrintPage ? 'print_page' : 'report_page')
