import { FormControl } from '@mui/material'
import React from 'react'

import { StyledTextField } from 'v3/containers/overhaul-rent-payment/components/text/text-field'
import { TitleText } from 'v3/containers/overhaul-rent-payment/components/text/title-text'
import { RECIPIENT_DETAILS } from 'v3/containers/overhaul-rent-payment/text-constants'

export const SelfDetailsDrawer = ({ renterInfo, setRenterInfoPiece }) => {
  const { TITLE, FIRST_NAME_LABEL, LAST_NAME_LABEL } = RECIPIENT_DETAILS.SELF_DETAILS

  return (
    <>
      <TitleText>{TITLE}</TitleText>
      {/* TODO: skip if details can be found elsewhere? */}
      <FormControl defaultValue="" required>
        <StyledTextField
          id="client-first-name"
          label={FIRST_NAME_LABEL}
          placeholder="James"
          variant="outlined"
          defaultValue={renterInfo['first-name']}
          size="normal"
          onChange={e => setRenterInfoPiece({ 'first-name': e.target.value })}
          required
        />
        <StyledTextField
          id="client-last-name"
          label={LAST_NAME_LABEL}
          placeholder="Johnson"
          variant="outlined"
          defaultValue={renterInfo['last-name']}
          size="normal"
          onChange={e => setRenterInfoPiece({ 'last-name': e.target.value })}
          required
        />
      </FormControl>
    </>
  )
}
