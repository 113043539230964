import React from "react"
import { Redirect } from "react-router-dom"
import { Statsig } from "statsig-react"

import { query } from "@rentspree/path"
import { isMailingAddressCompleted } from "helpers/mailing-address-checking"
import * as PATH from "constants/route"
import { SKIP_TU_AGREEMENT_ADDRESS_EXPERIMENT } from "utils/feature-flag/constants"

export const withRequireMailingAddress = WrappedComponent => 
  (props) => {
    const { location, profile } = props
    const isMailingCompleted = isMailingAddressCompleted(profile)
    const shouldBeAbleToSkip = location.pathname === PATH.REQUEST && !isMailingCompleted
    const isSkip = shouldBeAbleToSkip && Statsig.getExperiment(SKIP_TU_AGREEMENT_ADDRESS_EXPERIMENT.VALUE).get(SKIP_TU_AGREEMENT_ADDRESS_EXPERIMENT.KEYS.IS_SKIP)
    if(isSkip) {
      return <WrappedComponent {...props} />
    }

    if (!isMailingCompleted) {
      const queryString = query.parse(location.search)
      const search = query.stringify({
        ...queryString,
        continuePath: location.pathname,
      })
      return (
        <Redirect
          to={{
            pathname: PATH.REQUEST_UPDATE_INFO,
            search,
          }}
        />
      )
    } 
    return <WrappedComponent {...props} />
  
  }

  
