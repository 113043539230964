import { Box, Button } from '@mui/material'
import React, { useEffect } from 'react'

import { TellUsAboutPayment } from 'v3/containers/overhaul-rent-payment/components/payment-forms/tell-us-about-payment'
import { PaymentCategories } from 'v3/containers/overhaul-rent-payment/pages/payment-details/constants'
import { findPaymentCategory } from 'v3/containers/overhaul-rent-payment/pages/utils'
import { PAYMENT_DETAILS } from 'v3/containers/overhaul-rent-payment/text-constants'

export const RentPaymentComponent = ({
  pageIndexes,
  setDrawerOpen,
  _selectionOptions,
  _paymentSelections,
  _setPaymentSelections,
  payments,
  setPayments,
}) => {
  // assume for now we can only have one rent payment
  const [rentPaymentIndex, rentPayment] = findPaymentCategory(payments, PaymentCategories.RENT)

  const setRentPayment = newPayment => {
    const nextPayments = [...payments]
    nextPayments[rentPaymentIndex] = newPayment
    setPayments(nextPayments)
  }

  useEffect(() => {
    // not ideal, but functional for now; prone to bugs, always causes 1 re-render (2+ if bugs), etc.
    if (rentPaymentIndex === -1) {
      setRentPayment(rentPayment)
    }
  }, [payments.length, setPayments, rentPaymentIndex])

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'left',
        textAlign: 'left',
      }}
    >
      {rentPayment !== undefined && (
        <TellUsAboutPayment
          payment={rentPayment}
          setPayment={setRentPayment}
          pageIndexes={pageIndexes}
        />
      )}
      <Button sx={{ color: 'black', width: 'fit-content' }} onClick={() => setDrawerOpen(true)}>
        +
        <span style={{ marginLeft: '1rem', textDecoration: 'underline', textTransform: 'none' }}>
          {PAYMENT_DETAILS.RENT.ADD_LATE_FEE}
        </span>
      </Button>
    </Box>
  )
}
