import React from 'react'
import { withRouter } from 'react-router-dom'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import indexOf from 'lodash/indexOf'
import isObject from 'lodash/isObject'
import Button from '@rentspree/component-v2/dist/button'

import { REPORT_GENERATION_LIMIT_PAGE } from 'constants/route'
import { REPORT_GENERATION_LIMIT_ERROR_CODE } from 'constants/error-messages'

import { withStatsigFeatureGate } from 'hoc/with-statsig'
import {
  Header,
  BodyContent,
  Content,
  LetterImage,
  LoadingProgress,
} from '../../components/credit-report/open-credit'
import tracker from '../../tracker'
import { EVENT_PAY_REPORT } from '../../tracker/const'
import OpenCreditConnect from './open-credit-connect'
import * as CREDIT_REPORT from '../../constants/credit-report-consts'
import * as ERRORS from '../../constants/error-messages'

@withRouter
@OpenCreditConnect
export class OpenCreditReport extends React.Component {
  constructor() {
    super()
    this.COUNT_PROBING = 10
    this.isUnmount = false
    this.init()
  }

  componentWillMount() {
    this.handleGetFirstCreditReport()
    this.handleGenerateIncomeVerificationReport()
    tracker.trackEvent(EVENT_PAY_REPORT.viewOpenReportModal, this.properties())
  }

  componentWillUpdate(nextProps) {
    this.autoFetchCreditReport(nextProps.creditReport)
  }

  componentWillUnmount() {
    this.isUnmount = true
    if (this.timeoutCreditReport) clearTimeout(this.timeoutCreditReport)
    if (this.timeoutProgress) clearTimeout(this.timeoutProgress)
    this.props.actions.clearCreditReport()
  }

  getReportFromCurrentApp(currentApp) {
    const { creditReportDetail } = this.props
    const report = isObject(currentApp.credit_report)
      ? currentApp.credit_report
      : creditReportDetail
    return report
  }

  getCurrentApp() {
    const { currentApp } = this.props
    return currentApp
  }

  properties = () => {
    const { currentApp } = this.props
    const { _id, email, screeningOption } = currentApp
    const properties = {
      applicant: email,
      rental_id: _id,
      report_subdomain: screeningOption?.subdomain,
    }
    return properties
  }

  handleGetFirstCreditReport() {
    const { actions } = this.props
    const currentApp = this.getCurrentApp()
    const rentalId = get(currentApp, '_id')
    this.isProgress = true
    return actions.getCreditReport(rentalId).then(response => {
      if (this.isUnmount) return
      this.countFetchCreditReport = 0
      const creditReport = get(response, 'creditReport', {})
      switch (creditReport.status) {
        case CREDIT_REPORT.STATUS.REQUESTED:
          this.fetchCreditReport()
          break
        case CREDIT_REPORT.STATUS.OPENED:
          this.progressSuccess(creditReport)
          break
        default:
          this.openCreditReport(rentalId)
      }
    })
  }

  handleGenerateIncomeVerificationReport() {
    const { actions } = this.props
    const currentApp = this.getCurrentApp()
    const rentalId = get(currentApp, '_id')
    const { screeningOption, incomeVerificationId } = currentApp
    if (screeningOption?.payerType === 'landlord' && incomeVerificationId) {
      actions.generateIncomeVerificationReport(rentalId)
    }
  }

  openCreditReport(rentalId) {
    const { actions } = this.props
    return actions.openCreditReportWithFetch(rentalId).then(res => {
      if (res.success === false) {
        if (get(res, ['meta', 'code']) === 'idma_unverified') {
          actions.updateCurrentReportStatus(CREDIT_REPORT.STATUS.ATTACH_REAL_ERROR)
        } else {
          tracker.trackEvent(EVENT_PAY_REPORT.openReportFail, this.properties())
          this.progressSuccess()
        }
      } else {
        this.fetchCreditReport()
      }
    })
  }

  init() {
    this.countFetchCreditReport = 0
    this.progressCreditReport = 0
    this.isProgress = false
  }

  fetchCreditReport = (probing = false) => {
    const { actions } = this.props
    const currentApp = this.getCurrentApp()
    const rentalId = get(currentApp, '_id')
    this.isProgress = true
    if (!this.isUnmount) {
      return actions.getCreditReport(rentalId, probing).then(res => {
        if (res.success === false) {
          this.progressSuccess()
          tracker.trackEvent(EVENT_PAY_REPORT.openReportFail, this.properties())
        }
      })
    }
    return null
  }

  isCheckCreditReport(creditReport) {
    if (this.countFetchCreditReport > 0) {
      return !!creditReport.tuApplication
    }
    return !!(
      creditReport.tuApplication &&
      creditReport.tuApplication.Applicants &&
      creditReport.tuApplication.Applicants.length > 0 &&
      creditReport.tuApplication.Applicants[0].CreditReport
    )
  }

  calculatorProgress() {
    const increasePercent = 7 + Math.random() * 5 * (Math.random() >= 0.5 ? 1 : -1)
    this.countFetchCreditReport += 1
    this.progressCreditReport += parseInt(increasePercent, 10)
  }

  progressSuccess(creditReport) {
    this.progressCreditReport = 100
    if (!creditReport) {
      this.forceUpdate()
    }
    const tuApplication = get(creditReport, 'tuApplication', {})
    this.timeoutProgress = setTimeout(() => {
      this.init()
      this.forceUpdate()
      if (creditReport && !isEmpty(tuApplication)) {
        this.props.closeModal()
        if (creditReport.status === CREDIT_REPORT.STATUS.OPENED)
          tracker.trackEvent(EVENT_PAY_REPORT.openReportSuccess, this.properties())
        this.updateStatusToOpened(creditReport)
      }
    }, 1000)
  }

  updateStatusToOpened(creditReport) {
    if (this.isCheckCreditReport(creditReport)) {
      this.props.actions.updateCurrentReportStatus(CREDIT_REPORT.STATUS.OPENED)
    }
  }

  autoFetchCreditReport(creditReport) {
    const { creditReportDetail, isFetchingCreditReport, errorOpen, errorMessage } = creditReport
    if (
      creditReportDetail &&
      this.isProgress &&
      !isFetchingCreditReport &&
      indexOf(
        [CREDIT_REPORT.STATUS.REQUESTED, CREDIT_REPORT.STATUS.OPENED],
        creditReportDetail.status,
      ) >= 0 &&
      !errorOpen &&
      isEmpty(errorMessage)
    ) {
      if (
        !this.isCheckCreditReport(creditReportDetail) &&
        this.countFetchCreditReport <= this.COUNT_PROBING
      ) {
        this.calculatorProgress()
        this.timeoutCreditReport = setTimeout(() => {
          this.fetchCreditReport(true)
        }, 3000)
      } else {
        this.progressSuccess(creditReportDetail)
      }
    }
  }

  renderError(currentApp, errorOpen) {
    if (errorOpen) {
      const { code: errorCode } = errorOpen
      if (errorCode === REPORT_GENERATION_LIMIT_ERROR_CODE) {
        this.props.history.push(REPORT_GENERATION_LIMIT_PAGE)
      }
    }
      return (
        // For now, we shall assume all the error is from outage
        // This will have to wait for datadog threshold to detect TU total outage vs intermittent problem
        // before we can use TU delay error msg
        <BoxCreditReport
          handleTryAgain={() => this.handleGetFirstCreditReport()}
          type="letter-red"
          head={ERRORS.OPEN_CREDIT.TRANSUNION_OUTAGE.TITLE}
          content={ERRORS.OPEN_CREDIT.TRANSUNION_OUTAGE.MESSAGE}
          linkText={ERRORS.OPEN_CREDIT.TRANSUNION_OUTAGE.LINK_TEXT}
          linkAddr={ERRORS.OPEN_CREDIT.TRANSUNION_OUTAGE.LINK_ADDR}
        />
      )
  }

  render() {
    const { creditReport } = this.props
    const currentApp = this.getCurrentApp()
    const { errorOpen } = creditReport

    return (
      <div>
        {this.isProgress ? (
          <BoxCreditReport
            head="Pulling TransUnion Report(s)"
            content="This process may take a few minutes.<br/>Please don't refresh your browser."
            progress={this.progressCreditReport}
          />
        ) : (
          this.renderError(currentApp, errorOpen)
        )}
      </div>
    )
  }
}

export const BoxCreditReport = props => (
  <div>

    <BodyContent>
      <LetterImage type={props.type} />
      <Header>{props.head}</Header>
      <Content dangerouslySetInnerHTML={{ __html: props.content}} />
      {props.type === 'letter-red' ? (
        props.handleTryAgain && (
          <Button
            google
            small
            fontSize="16px"
            margin="auto"
            id="tryAgainCreditReport"
            onClick={props.handleTryAgain}
          >
            Retry
          </Button>
        )
      ) : (
        <LoadingProgress progress={props.progress} />
      )}
    </BodyContent>
  </div>
)
