import React, { useEffect } from 'react'
import { compose } from 'redux'
import get from 'lodash/get'
import OnImagesLoaded from 'react-on-images-loaded'

import { ReportLoading } from 'components/reports/loading'
import IncomeEstimateComponent from 'components/reports/screening-reports/shareable/income-estimate/income-estimate'
import {
  IncomeEstimateHeaderWrapper,
  IncomeEstimateWrapper,
} from 'components/reports/screening-reports/shareable/income-estimate/styles'
import { S28 } from 'components/typography'
import tracker from 'tracker'
import { INCOME_ESTIMATE_EVENT } from 'tracker/const'
import { MOBILE_TABS } from 'legacy/containers/application/application-detail-const'
import { I, ProBadge } from 'legacy/components/typography'
import { shouldGetCreditReport } from '../helper'
import { REPORT_TYPES } from '../../constants'
import withConnect from '../income-estimate/connect'
import withReportAvailable from '../../with-report-available'
import withScreeningReportError from '../with-error'

export const ShareableIncomeEstimate = ({
  getCreditReport,
  creditReportDetail,
  isFetchingCreditReport,
  rentalDetail,
  fetchedCreditReportImage,
  externalRenterDetail,
  incomeEstimate,
  getIncomeEstimateReport,
  openIncomeEstimateReport,
  history,
  location,
  changeMobileActiveTab,
}) => {
  useEffect(() => {
    // Condition: to prevent auto redirect when landing into Income Estimate page while no content and waiting for fetching
    if (!incomeEstimate.id && !incomeEstimate.isFetching) {
      const allPath = location.pathname.replace('income-estimate', 'all')
      history.replace(allPath)
    }
  }, [incomeEstimate])

  // For getting renter info from credit report. We can remove this later on when we have renter info in IE
  useEffect(() => {
    if (shouldGetCreditReport(rentalDetail, creditReportDetail)) {
      getCreditReport(get(rentalDetail, '_id'))
    }
  }, [rentalDetail])

  if (incomeEstimate.isFetching || isFetchingCreditReport) {
    return (
      <IncomeEstimateWrapper>
        <ReportLoading />
      </IncomeEstimateWrapper>
    )
  }

  const trackOpenReport = () => {
    tracker.trackEvent(INCOME_ESTIMATE_EVENT.EVENT_NAME.CLICK_UNDERSTAND, {
      rental_id: rentalDetail.id,
      applicant: rentalDetail.email,
    })
  }

  const handleOpenReport = () => {
    trackOpenReport()
    openIncomeEstimateReport({ incomeEstimateId: incomeEstimate.id })
  }

  const onOtherDocsLinkClick = () => {
    changeMobileActiveTab(MOBILE_TABS.OTHER_DOCS)
  }

  const shouldShowProBadge = !get(rentalDetail, 'screeningOption.premium')

  return (
    <OnImagesLoaded
      onLoaded={fetchedCreditReportImage}
      onTimeout={fetchedCreditReportImage}
      timeout={30000}
    >
      <IncomeEstimateHeaderWrapper>
        <S28 margin="0px 10px 0px 0px">Income Estimate</S28>
        {shouldShowProBadge && (
          <ProBadge>
            <I>PRO</I>
          </ProBadge>
        )}
      </IncomeEstimateHeaderWrapper>
      <IncomeEstimateComponent
        renter={externalRenterDetail}
        incomeEstimate={incomeEstimate}
        getIncomeEstimateReport={getIncomeEstimateReport}
        openIncomeEstimateReport={handleOpenReport}
        location={location}
        onOtherDocsLinkClick={onOtherDocsLinkClick}
      />
    </OnImagesLoaded>
  )
}

export default compose(
  withReportAvailable(REPORT_TYPES.INCOME_ESTIMATE),
  withScreeningReportError,
  withConnect,
)(ShareableIncomeEstimate)
