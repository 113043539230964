import { FormControl } from '@mui/material'
import React from 'react'

import { StyledTextField } from 'v3/containers/overhaul-rent-payment/components/text/text-field'
import { TitleText } from 'v3/containers/overhaul-rent-payment/components/text/title-text'
import { RECIPIENT_DETAILS } from 'v3/containers/overhaul-rent-payment/text-constants'

export const ClientDetailsDrawer = ({ renterInfo, setRenterInfoPiece }) => {
  const { TITLE, SUBTITLE, FIRST_NAME_LABEL, LAST_NAME_LABEL, EMAIL_LABEL } =
    RECIPIENT_DETAILS.CLIENT_DETAILS

  return (
    <>
      <TitleText>{TITLE}</TitleText>
      <p>{SUBTITLE}</p>
      <FormControl fullWidth required>
        <StyledTextField
          id="client-first-name"
          label={FIRST_NAME_LABEL}
          placeholder="James"
          variant="outlined"
          defaultValue={renterInfo['first-name']}
          size="normal"
          onChange={e => setRenterInfoPiece({ 'first-name': e.target.value })}
          required
        />
        <StyledTextField
          id="client-last-name"
          label={LAST_NAME_LABEL}
          placeholder="Johnson"
          variant="outlined"
          defaultValue={renterInfo['last-name']}
          size="normal"
          onChange={e => setRenterInfoPiece({ 'last-name': e.target.value })}
          required
        />
        {/* TODO: remove email if user navigates back to "Me or my business" option */}
        <StyledTextField
          id="client-email"
          label={EMAIL_LABEL}
          placeholder="jj@email.com"
          variant="outlined"
          defaultValue={renterInfo.email}
          size="normal"
          onChange={e => setRenterInfoPiece({ email: e.target.value })}
          required={false}
        />
      </FormControl>
    </>
  )
}
