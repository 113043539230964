/* eslint-disable no-console */
import { debounce, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material'
import TextField from '@rentspree/component-2023.components.atoms.text-input'
import dayjs from 'dayjs'
import timezonePlugin from 'dayjs/plugin/timezone'
import utcPlugin from 'dayjs/plugin/utc'
import React, { useCallback, useEffect } from 'react'

import { DatePickerInput } from 'v3/containers/rent-payment/setup-page/set-up-for-myself/steps/payment-details/one-time/date-picker-input'

dayjs.extend(timezonePlugin)
dayjs.extend(utcPlugin)

export const EditPayment = ({ payment, setPayment }) => {
  const isRecurring = payment?.type === 'recurring'
  const setPaymentComponent = useCallback(
    debounce(({ nextFields }) => {
      setPayment({
        ...payment,
        ...nextFields,
      })
    }, 200),
  )

  useEffect(() => {
    // this is a cross-field dependent value, so we'll watch them for changes ot update vs baking that into each field's setting logic in both places
    let term = null
    if (isRecurring) {
      term = payment?.endDate === undefined ? 'perpetual' : 'fixed'
    }
    setPaymentComponent({ term })
  }, [payment?.endDate])

  return (
    <>
      <Typography variant="h4" sx={{ marginBottom: '30px' }}>
        Edit payment
      </Typography>
      <Typography sx={{ fontSize: '1.5rem', marginBottom: '12px' }} variant="p">
        Which fee?
      </Typography>
      <TextField
        label="Fee type"
        sx={{ fontSize: '1.5rem', marginBottom: '15px' }}
        defaultValue={payment?.type || ''}
        required
        disabled
      />

      {isRecurring && (
        <TextField
          label="Fee name"
          sx={{ fontSize: '1.5rem', marginBottom: '15px' }}
          defaultValue={payment?.name || ''}
          onChange={e => {
            setPaymentComponent({ nextFields: { name: e.target.value } })
          }}
          required
        />
      )}

      <Typography sx={{ fontSize: '1.5rem', marginBottom: '12px' }} variant="p">
        How often?
      </Typography>
      <RadioGroup sx={{ marginLeft: '5px', marginBottom: '15px' }}>
        <FormControlLabel
          disabled
          value="recurring"
          checked={payment?.type === 'recurring'}
          control={<Radio sx={{ padding: '4px 9px' }} size="large" />}
          label={<Typography variant="h5">Monthly</Typography>}
        />
        <FormControlLabel
          disabled
          value="oneTime"
          checked={payment?.type !== 'recurring'}
          control={<Radio sx={{ padding: '4px 9px' }} size="large" />}
          label={<Typography variant="h5">One-time</Typography>}
        />
      </RadioGroup>

      <Typography sx={{ fontSize: '1.5rem', marginBottom: '15px' }} variant="p">
        How much?
      </Typography>
      {/* TODO: ui.rentspree.com TextInput isn't doing floating labels like mocks want */}
      <TextField
        label="Amount"
        startAdornment="$"
        onChange={e => {
          const nextVal = parseFloat(e.target.value).toFixed(2)
          setPaymentComponent({ nextFields: { amount: nextVal } })
        }}
        sx={{ fontSize: '1.5rem', marginBottom: '15px' }}
        defaultValue={parseFloat(payment?.amount || '0.00').toFixed(2)}
        required
      />

      {isRecurring && (
        <>
          <Typography sx={{ fontSize: '1.5rem', marginBottom: '15px' }} variant="p">
            For how long?
          </Typography>
          <DatePickerInput
            label="Starts on"
            sx={{ fontSize: '1.5rem', marginBottom: '15px' }}
            name="startDate"
            value={payment?.startDate}
            minDate={dayjs().date()}
            error={
              payment?.endData !== undefined && payment?.endData < dayjs().date()
                ? 'Please enter a date from today onwards'
                : undefined
            }
            onChange={e => {
              setPaymentComponent({ nextFields: { startDate: e.target.value } })
            }}
            required
          />
          <DatePickerInput
            label="Ends on"
            sx={{ fontSize: '1.5rem', marginBottom: '15px' }}
            helperText="If no end date, payments will continue month-to-month"
            name="endDate"
            value={payment?.endDate}
            minDate={payment?.startDate}
            onChange={e => {
              setPaymentComponent({ nextFields: { endDate: e.target.value } })
            }}
            error={
              payment?.endData !== undefined && payment?.endData < dayjs(payment?.startDate).date()
                ? 'Please enter a valid end date after the start date, or none'
                : undefined
            }
          />
        </>
      )}
      {!isRecurring && (
        <>
          <Typography sx={{ fontSize: '1.5rem', marginBottom: '15px' }} variant="p">
            When&apos;s the due date?
          </Typography>
          <DatePickerInput
            label="Due on"
            sx={{ fontSize: '1.5rem', marginBottom: '15px' }}
            name="startDate"
            value={payment?.dueDate}
            minDate={dayjs().date()}
            error={
              payment?.endData !== undefined && payment?.endData < dayjs().date()
                ? 'Please enter a date from today onwards'
                : undefined
            }
            onChange={e => {
              setPaymentComponent({ nextFields: { dueDate: e.target.value } })
            }}
            required
          />
        </>
      )}

      <TextField
        label="Note"
        helperText="We'll show this info to the tenant"
        defaultValue={payment?.description}
        onChange={e => setPaymentComponent({ nextFields: { description: e.target.value } })}
        sx={{ fontSize: '1.5rem', marginBottom: '15px' }}
        multiline
      />
    </>
  )
}
