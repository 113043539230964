import React, { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useExperiment, useGate } from 'statsig-react'

import { EXPLORE_PAGE, PROPERTY, RENT_PAYMENT, TENANT_SCREENING } from 'constants/route'
import tracker, { amplitudeTracker } from 'tracker'
import { AGENT_INCENTIVE_PROGRAM_PAYMENT_ADOPTION_EVENT } from 'tracker/const'
import {
  AGENT_INCENTIVE_PROGRAM_PAYMENT_ADOPTION,
  AGENT_INCENTIVE_PROGRAM_PAYMENT_ADOPTION_ITR2_EXPERIMENT,
} from 'utils/feature-flag/constants'
import history from 'utils/history'
import { openRewardPage } from 'v3/components/incentive-reward-page/redux/actions'
import { SEEN_ANNOUNCEMENT_TYPE } from 'v3/containers/page-dashboard/const'
import { useGetMyIncentiveTrackRecord } from 'v3/hooks/use-get-my-incentive-track'
import { useMediaQuery } from 'v3/hooks/use-media-query'
import { useMutateMySeenAnnouncement } from 'v3/hooks/use-mutate-seen-announcement'
import { PATH_HOME_PAGE } from 'v3/routers/path'
import { AGENT_INCENTIVE_CAMPAIGN_NAME } from 'v3/services/incentive'

import { openInformationPage } from '../incentive-information/redux/actions'

import { BannerWithTwoImage } from './banner-with-two-image'
import {
  AGENT_INCENTIVE_PROGRAM_PAYMENT_ADOPTION_BANNER,
  AGENT_INCENTIVE_PROGRAM_PAYMENT_ADOPTION_SEEN_GROUP,
} from './constants'

export const AgentIncentivePaymentAdoptionBanner = ({ margin }) => {
  const dispatch = useDispatch()
  const { value: isAgentIncentivePaymentAdoptionEnabled } = useGate(
    AGENT_INCENTIVE_PROGRAM_PAYMENT_ADOPTION,
  )

  const { config: experiment } = useExperiment(
    AGENT_INCENTIVE_PROGRAM_PAYMENT_ADOPTION_ITR2_EXPERIMENT,
  )
  const agentIncentiveExperimentValue = experiment.get('experiment_value', null)
  useEffect(() => {
    if (isAgentIncentivePaymentAdoptionEnabled && agentIncentiveExperimentValue) {
      // NOTE: to any feature wanted to have `experiment_value` in this component, please add value in the array
      amplitudeTracker
        .getTracker()
        .setUserProperties({ experiment_value: [agentIncentiveExperimentValue] })
    }
  }, [isAgentIncentivePaymentAdoptionEnabled, agentIncentiveExperimentValue])

  const bannerTitle = experiment.get('bannerTitle')
  const bannerSubtitle = experiment.get('bannerSubtitle')
  const userIncentiveTrack = useGetMyIncentiveTrackRecord(AGENT_INCENTIVE_CAMPAIGN_NAME)
  const isMobile = useMediaQuery('(max-width: 1016px)')
  const mutateSeenAnnouncement = useMutateMySeenAnnouncement({
    announcementType: SEEN_ANNOUNCEMENT_TYPE.NOTICES,
    groups: [AGENT_INCENTIVE_PROGRAM_PAYMENT_ADOPTION_SEEN_GROUP],
  })

  // eligibility has been checked on <AgentIncentiveEligible /> wrapper
  const shouldHideBanner = !isAgentIncentivePaymentAdoptionEnabled || isMobile
  const viewOn = useMemo(() => {
    switch (history.location.pathname) {
      case PATH_HOME_PAGE:
      case EXPLORE_PAGE:
        return 'home'
      case TENANT_SCREENING:
        return 'screening_l1'
      case PROPERTY:
        return 'property_l1'
      case RENT_PAYMENT:
        return 'payment_l1'
      default:
        return ''
    }
  }, [history.location.pathname])

  useEffect(() => {
    if (!shouldHideBanner) {
      tracker.trackEvent(
        AGENT_INCENTIVE_PROGRAM_PAYMENT_ADOPTION_EVENT.EVENT_NAME.SEE_INCENTIVE_ANNOUNCEMENT,
        {
          view_on: viewOn,
          version: AGENT_INCENTIVE_PROGRAM_PAYMENT_ADOPTION_EVENT.EVENT_PROPERTY.VERSION,
        },
      )
      mutateSeenAnnouncement.mutate()
    }
  }, [shouldHideBanner])

  if (shouldHideBanner) {
    return null
  }

  const secondaryButtonProps =
    userIncentiveTrack?.data?.trackRecords?.length > 0
      ? {
          secondaryButtonText:
            AGENT_INCENTIVE_PROGRAM_PAYMENT_ADOPTION_BANNER.SECONDARY_BUTTON_TEXT,
          onClickSecondaryButton: () => {
            dispatch(
              openRewardPage({
                isShowAlert: false,
                fromPathname: history.location.pathname,
                eventTracking: {
                  clickFrom:
                    AGENT_INCENTIVE_PROGRAM_PAYMENT_ADOPTION_EVENT.EVENT_PROPERTY.CLICK_FROM
                      .INCENTIVE_ANNOUNCEMENT,
                },
              }),
            )
          },
        }
      : {}

  return (
    <BannerWithTwoImage
      margin={margin}
      leftImageUrl={AGENT_INCENTIVE_PROGRAM_PAYMENT_ADOPTION_BANNER.LEFT_IMAGE_URL}
      rightImageUrl={AGENT_INCENTIVE_PROGRAM_PAYMENT_ADOPTION_BANNER.RIGHT_IMAGE_URL}
      title={bannerTitle}
      subtitle={bannerSubtitle}
      primaryButtonText={AGENT_INCENTIVE_PROGRAM_PAYMENT_ADOPTION_BANNER.PRIMARY_BUTTON_TEXT}
      onClickPrimaryButton={() => {
        dispatch(
          openInformationPage({
            fromPathname: history.location.pathname,
            isAutoOpened: false,
            isOpenedFromNavBarClick: false,
          }),
        )
      }}
      {...secondaryButtonProps}
    />
  )
}
