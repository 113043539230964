/* eslint-disable no-console */
import { debounce } from '@mui/material'
import React, { useContext } from 'react'

import { TitleText } from 'v3/containers/overhaul-rent-payment/components/text/title-text'
import { PaymentInfoContext } from 'v3/containers/overhaul-rent-payment/context'
import { PropertySelectAndForm } from 'v3/containers/overhaul-rent-payment/pages/property-and-tenant/property-select-and-form'
import { TenantSelectAndForm } from 'v3/containers/overhaul-rent-payment/pages/property-and-tenant/tenant-select-and-form'
import { isEmptyOrNotObject, isObject } from 'v3/containers/overhaul-rent-payment/pages/utils'
import { PROP_AND_TENANT } from 'v3/containers/overhaul-rent-payment/text-constants'

const tenantField = 'tenant'
const propertyField = 'property'
const isPropertyDataValid = pageData => {
  const propertyData = pageData?.[propertyField]
  const isPropertyPopulated = isObject(propertyData) && !isEmptyOrNotObject(propertyData)

  // TODO: leaving room for populated data validation
  return isPropertyPopulated
}
const isTenantDataValid = pageData => {
  const tenantData = pageData?.[tenantField]
  const isTenantPopulated = isObject(tenantData) && !isEmptyOrNotObject(tenantData)

  // TODO: leaving room for populated data validation
  return isTenantPopulated
}
const isPageDataAllValid = pageData => {
  return isPropertyDataValid(pageData) && isTenantDataValid(pageData)
}

export const handlePropertyAndTenantProgression = (
  { increment = false, decrement = false },
  curPageIndexes,
  setPageIndexes,
  pageField,
  curPaymentInfo,
) => {
  const indexChanges = {
    pageL1Index: curPageIndexes.pageL1Index,
    drawerOpen: curPageIndexes.drawerOpen,
  }
  console.log(`${pageField}: ${{ increment, decrement }}`)
  console.log(`${pageField}: ${curPageIndexes}`)
  // if all the data is entered and is valid, then return true
  let shouldSaveData = false
  // shortcut invalid scenarios
  if (increment === decrement) {
    /*
     * logging an error, but this can still be consumer facing, so staying vague
     * need to find direct DD log avenue for debug details if possible
     */
    console.error('Unusual state for Payment Details page progression request')
  } else {
    if (increment) {
      const pageInfo = curPaymentInfo[pageField]
      const allDataEnteredAndValid = isPageDataAllValid(pageInfo)
      if (allDataEnteredAndValid) {
        console.log(`${pageField}: progressing the page!`)
        // if all info is filled out, we can progress to the next page
        indexChanges.pageL1Index += 1
        // we have no prior page to go back to in this flow, so only the drawer can be closed
        indexChanges.drawerOpen = false
        shouldSaveData = true
      }
    }
    if (decrement) {
      if (curPageIndexes.drawerOpen) {
        console.log(`${pageField}: closing the drawer...`)
        // we have no prior page to go back to in this flow, so only the drawer can be closed
        indexChanges.drawerOpen = false
      } else {
        console.log(`${pageField}: going back a page...`)
        // this page can step back to the prior one if the drawer is closed
        indexChanges.pageL1Index -= 1
        // drawer resetting should be handled by the smart index progression method, but add safety in redundancy
        indexChanges.drawerOpen = false
      }
    }
  }

  /*
   * All decrement states for this page involve going back to the root of the flow
   * Absorbing errored-state traversal handling as well
   */
  console.log(`${pageField}: ${indexChanges}`)
  setPageIndexes(indexChanges)
  return shouldSaveData
}

export const PropertyAndTenantPage = ({ pageField, updateDataHandler }) => {
  const [paymentInfo] = useContext(PaymentInfoContext)

  const pageInfo = paymentInfo?.[pageField] || {}
  // eslint-disable-next-line no-unused-vars
  const { [tenantField]: tenantInfo, [propertyField]: propertyInfo } = pageInfo
  // don't spam state variable changes & page re-rendering while someone's typing in the drawers
  const debouncedSetPropertyPiece = debounce(nextPropertyInfo => {
    updateDataHandler({
      // leave the other non-recipient-info sub-fields for the page's other info alone
      ...pageInfo,
      // change only what we care about
      [propertyField]: nextPropertyInfo,
    })
  }, 200)

  // don't spam state variable changes & page re-rendering while someone's typing in the drawers
  const debouncedSetTenantPiece = debounce(nextTenantInfo => {
    updateDataHandler({
      // leave the other non-recipient-info sub-fields for the page's other info alone
      ...pageInfo,
      // change only what we care about
      [tenantField]: nextTenantInfo,
    })
  }, 200)

  return (
    <>
      <TitleText>{PROP_AND_TENANT.TITLE}</TitleText>
      <PropertySelectAndForm
        selectedProperty={pageInfo?.[propertyField]}
        setProperty={debouncedSetPropertyPiece}
      />
      {isPropertyDataValid(pageInfo) && (
        <TenantSelectAndForm
          selectedTenant={pageInfo?.[tenantField]}
          setTenant={debouncedSetTenantPiece}
        />
      )}
    </>
  )
}
