import { createSelector } from 'reselect'
import get from 'lodash/get'
import { START_POTENTIAL_FRAUD_USER_CREATED_AT } from 'env'

const getUser = state => get(state, 'user', {})
const getAuth = state => get(state, 'persistState.auth', {})

export const selectUser = createSelector(getUser, state => state)

export const selectProfile = createSelector(selectUser, state => get(state, 'profile', {}))

export const selectProfileForStatsig = createSelector(selectProfile, profile => ({
  userID: profile?._id || 'default-id',
  email: profile.email,
}))

export const selectIsFetchedUser = () =>
  createSelector(selectUser, state => get(state, 'isFetched', false))

export const selectIsFetchingUser = () =>
  createSelector(selectUser, state => get(state, 'isFetching', false))

export const selectUserIntegration = createSelector(selectUser, state =>
  get(state, 'userIntegration', []),
)

export const selectIsZiplogixUser = createSelector([selectUserIntegration], userIntegration =>
  userIntegration.includes('ziplogix'),
)

export const selectAccessToken = createSelector(getAuth, state => get(state, 'accessToken'))

export const selectIsPotentialFraudUser = createSelector(selectProfile, profile => {
  const userCreatedAt = profile?.createdAt
  const externalIntegration = !!profile?.externalIntegration
  const isLinkedPartner = !!profile?.isLinkedPartner

  return (
    !externalIntegration &&
    !isLinkedPartner &&
    new Date(userCreatedAt) > new Date(START_POTENTIAL_FRAUD_USER_CREATED_AT)
  )
})
