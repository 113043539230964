import React from 'react'

import { ReportWrapper } from 'components/reports/screening-reports/share/responsive-doc-style'
import { ReportsBody } from 'components/reports/screening-reports/share/responsive-doc-components'

import { IncomeEstimateWrapper } from './styles'
import IncomeEstimateWarningComponent from './warning'
import IncomeEstimateReportComponent from './report'
import IncomeEstimateExpiredComponent from './expired'

const IncomeEstimateComponent = ({
  incomeEstimate,
  getIncomeEstimateReport,
  openIncomeEstimateReport,
  location,
  renter,
  onOtherDocsLinkClick,
}) => {

  const renderReportBody = () => {
    if (incomeEstimate.isExpired) {
      return <IncomeEstimateExpiredComponent incomeEstimate={incomeEstimate} />
    }
    if (!incomeEstimate.opened) {
      return <IncomeEstimateWarningComponent onClickAccept={openIncomeEstimateReport} />
    }
    return (
      <IncomeEstimateReportComponent
        getIncomeEstimateReport={getIncomeEstimateReport}
        reportInformation={incomeEstimate}
        onOtherDocsLinkClick={onOtherDocsLinkClick}
        location={location}
        renter={renter}
      />
    )
  }
  return (
    <IncomeEstimateWrapper>
      <ReportWrapper>
        <ReportsBody>{renderReportBody()}</ReportsBody>
      </ReportWrapper>
    </IncomeEstimateWrapper>
  )
}

export default IncomeEstimateComponent
