import React, { useEffect, useRef } from 'react'

const OutsideClickHandler = ({ children, handleOutsideClick }) => {
  const ref = useRef()

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  /**
   * Alert if clicked on outside of element
   */
  const handleClickOutside = event => {
    if (ref.current && !ref.current.contains(event.target)) {
      handleOutsideClick(event)
    }
  }

  return <div ref={ref}>{children}</div>
}

export default OutsideClickHandler
