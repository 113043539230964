export const warningText = {
  headerLogo: 'Income Estimate',
  title: 'Please Read',
  subtitle1: 'Income estimate can help you determine when to request proof of income.',
  subtitle2: 'Income estimate',
  subtitle2BoldText: 'cannot be used to deny an applicant.',
  acceptButton: 'I understand, view report',
}

export const INCOME_ESTIMATE_REPORT_DESCRIPTION =
  "Income Insights is based on a predictive estimate of a consumer's total income and is intended to help flag applicants who require additional documentation, such as a pay stub. Adverse action based on this estimate is not permitted."

export const INCOME_ESTIMATE_REPORT_FEEDBACK_TITLE = `Did you find this information useful?`

export const INCOME_ESTIMATE_REPORT_FEEDBACK_VALUE = {
  THUMB_UP: 'thumb_up',
  THUMB_DOWN: 'thumb_down',
}
