import Level1Template from '@rentspree/component-2023.components.templates.level-1-template'
import React from 'react'
import { Landing } from 'components/landing'
import { Div } from 'components/layout/main'
import EmptyState from '@rentspree/component-2023.components.templates.empty-state'
import messagingLandingImage from 'images/icons/messaging/messaging-welcome-image.svg'
import Button from '@rentspree/component-2023.components.atoms.button'
import { useGate } from 'statsig-react'
import { ZERO_STATE_MESSAGES } from 'utils/feature-flag/constants'
import { MESSAGING_INTRODUCE_TEXT } from './constants'

export const NewMessageCTA = ({ onNewMessageClick, color = 'primary' }) => (
  <Button
    onClick={onNewMessageClick}
    size="small"
    variant="contained"
    color={color}
    data-testid="new-message-cta-click"
  >
    Send message
  </Button>
)

export const MessageLandings = ({ isMobile, onNewMessageClick }) => {
  const { title, description, buttonName } = MESSAGING_INTRODUCE_TEXT
  const { value: isZeroStateMessage } = useGate(ZERO_STATE_MESSAGES)
  return (
    <>
      {isZeroStateMessage ? (
        <>
          <div className="messaging-landing messaging-landing-container">
            <Level1Template
              title="Messages"
              cta={
                <NewMessageCTA
                  onNewMessageClick={() => onNewMessageClick('title_cta')}
                  color="secondary"
                />
              }
              isMobile={isMobile}
            />
            <div className="messaging-landing messaging-landing-empty-state">
              <EmptyState
                image={
                  <img
                    src="https://cdn.rentspree.com/static-files/zero-state/messaging-empty-state.png"
                    alt="empty state"
                  />
                }
                title="Start a conversation"
                subTitle="Keep your exchanges with applicants, landlords, and other agents organized and in one spot."
                cta={
                  <>
                    <NewMessageCTA
                      onNewMessageClick={() => onNewMessageClick('empty_state')}
                      color="secondary"
                    />
                  </>
                }
              />
            </div>
          </div>
        </>
      ) : (
        <Div width="100%" margin="auto">
          <Landing
            title={title}
            description={description}
            buttonName={buttonName}
            buttonID="messaging-landing-button"
            buttonWidth={153}
            imgSrc={messagingLandingImage}
            imgWidth="218px"
            imgHeight="111px"
            onClick={onNewMessageClick}
            data-testid="new-message-cta-click"
          />
        </Div>
      )}
    </>
  )
}
