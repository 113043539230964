import { Grid } from '@mui/material'
import debounce from 'lodash/debounce'
import React, { useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { CloseBar } from '../components/close-bar/close-bar'
import { RentPaymentFooter } from '../components/footer/rent-payment-footer'
import { RentPaymentStepper } from '../components/stepper/rent-payment-stepper'
import { pages, pageNames } from '../constants'
import {
  pageIndexesTemplate,
  PageIndexesContext,
  BusinessInfoContext,
  PageProgressionContext,
} from '../context'

import { smartNextIndexesCalc } from './utils'

export const OverhaulBankSetupPage = () => {
  /*
   * The root page for the rent-payment flow, orchestrating the other pages within the pages/ subfolders
   * along with the general organization.
   *
   * Pages are broken out separately, and the order-consistent 'pages' variable below + pageIndexes keeps
   * track of the user's page state through the flow.
   */

  const [businessInfo, setBusinessInfo] = useState({})

  const [pageIndexes, setPageIndexes] = useState(pageIndexesTemplate)

  // eslint-disable-next-line no-console
  console.log(pageIndexes)

  // eslint-disable-next-line no-console
  console.log(businessInfo)

  const debouncedSetPageIndexes = useCallback(
    debounce(nextIndexes => {
      setPageIndexes(smartNextIndexesCalc(nextIndexes, pageIndexes))
    }, 200),
    [pageIndexes],
  )
  const key = pageNames[pageIndexes.pageL1Index]
  const { pageField, progressionHandler, Page, buttonLayout } = pages.get(key)
  const backupProgressionHandler = ({ increment = false, decrement = false }) => {
    debouncedSetPageIndexes({
      pageL1Index: Math.min(
        pageNames.length - 1,
        Math.max(0, pageIndexes.pageL1Index + increment - decrement),
      ),
    })
  }

  const contextProgressionHandler =
    progressionHandler === null ? backupProgressionHandler : progressionHandler

  const dispatch = useDispatch()

  /*
   * leveraging useCallback to save some rendering power, slight workaround for inheritance forcing hook vars be passed in
   * Allows rendering callers to just worry about increment/decrement below regardless
   */
  const simplifiedProgressionHandler = useCallback(
    ({ increment = false, decrement = false }) =>
      contextProgressionHandler(
        { increment, decrement },
        pageIndexes,
        debouncedSetPageIndexes,
        pageField,
        businessInfo,
        dispatch,
      ),
    [pageIndexes, debouncedSetPageIndexes, pageField, businessInfo, dispatch],
  )

  return (
    /*
     * Disabling explainer:
     * seems leveraging an array instantiation in general throws a useMemo error, but useMemo would just change every time they changed as well...
     * StackOverflow suggests it's a potentially out of date linting rule: https://stackoverflow.com/q/71454846
     * Open to refactoring if internal best practices prefer the useMemo approach still
     */
    /* eslint-disable react/jsx-no-constructed-context-values */
    <PageIndexesContext.Provider value={[pageIndexes, debouncedSetPageIndexes]}>
      <CloseBar />
      <RentPaymentStepper
        stepTitles={pageNames}
        pageIndex={pageIndexes.pageL1Index}
        handleClick={i => debouncedSetPageIndexes({ pageL1Index: i })}
      />
      <BusinessInfoContext.Provider value={[businessInfo, setBusinessInfo]}>
        <PageProgressionContext.Provider value={simplifiedProgressionHandler}>
          <Grid
            container
            direction="row"
            sx={{
              textAlign: 'center',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            spacing={2}
          >
            <Page pageField={pageField} />
          </Grid>
          <RentPaymentFooter pagesIndexes={pageIndexes} buttonLayout={buttonLayout} />
        </PageProgressionContext.Provider>
      </BusinessInfoContext.Provider>
    </PageIndexesContext.Provider>
  )
}
