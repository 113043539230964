
import injectSaga from "utils/inject-saga"
import injectReducer from "utils/inject-reducer"
import { compose } from "redux"
import incomeEstimateSaga from "legacy/containers/income-estimate/saga"
import incomeEstimateReducer from "legacy/containers/income-estimate/reducer"
import { withConnect } from "../connect"

const withIncomeEstimateSaga = injectSaga({ key: "income-estimate", saga: incomeEstimateSaga })
const withIncomeEstimateReducer= injectReducer({ key: "income-estimate", reducer: incomeEstimateReducer })

export default compose(
  withConnect,
  withIncomeEstimateSaga,
  withIncomeEstimateReducer
)