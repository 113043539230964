import Button from '@rentspree/component-v2/dist/button'
import { BlueGradientButton } from '@rentspree/component-v2/dist/button/gradient'
import Panel from '@rentspree/component-v2/dist/panel'
import React from 'react'
import styled from 'styled-components'

import TagsDropdown from 'components/contacts/molecules/tags-dropdown'
import {
  BULK_ADD_TAG_SELECT_STATE,
  BULK_DELETE_SELECT_STATE,
  BULK_REQUEST_APPLICATION_SELECT_STATE,
} from 'containers/contacts/list/constants'
import { breakpoints, COLOR } from 'styles/settings'

export const PanelWrapper = styled(Panel)`
  height: 50px;
  display: flex;
  align-items: center;
  margin-top: 0;
  border: 1px solid ${COLOR.activeBlue};
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.25);
  z-index: 1;
  > div {
    & :first-child {
      display: none;
    }
    & :nth-child(3) {
      display: flex;
      margin-right: 10px;
    }
  }
`

const PanelInner = styled.span`
  width: 100%;
  max-width: 750px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${COLOR.lightBlueHEX};
  color: ${COLOR.cyanBlue};
  font-size: 16px;
`

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
`

export const DeselectButton = styled(Button)`
  min-width: max-content;
  color: ${COLOR.textBlack};
`

export const SubmitButton = styled(BlueGradientButton)`
  ${({ disabled }) => disabled && `color: ${COLOR.placeholderGrey};`};
`

const StyleTagWrapper = styled(TagsDropdown)`
  font-size: 14px;
  color: ${COLOR.textBlack};
  ${breakpoints.mobile(`
     margin: 0 0 0 10px;
  `)} /* TODO: change to prop width */
  > #tag-input-container {
    width: auto;
  }
  @media (max-width: 345px) {
    margin: 0 0 0 5px;
    /* TODO: change to prop width */
    > #add-tag-button {
      width: 70px;
    }
  }
`

const getSelectedContactsString = n => `${n} contact${n > 1 ? 's' : ''} selected`

const bulkStateSection = {
  [BULK_ADD_TAG_SELECT_STATE]: {
    title: 'Select contacts to manage',
  },
  [BULK_DELETE_SELECT_STATE]: {
    title: 'Select contacts to delete',
  },
  [BULK_REQUEST_APPLICATION_SELECT_STATE]: {
    title: 'Select contacts to send rental application requests.',
  },
}
const ContactDashboardActionPanel = ({
  selectedNumber,
  onSubmit,
  onSendRentalApplication,
  onDeselect,
  onClose,
  bulkState,
  allTags,
  onBulkAddTag,
  onCreateAndBulkAddTag,
  onFocusAddTagOnBulkAddTag,
}) => (
  <PanelWrapper onClose={onClose} status="info">
    <PanelInner>
      <span>
        {selectedNumber
          ? getSelectedContactsString(selectedNumber)
          : bulkStateSection[bulkState]?.title}
      </span>
      <ButtonsContainer>
        {selectedNumber > 0 && (
          <DeselectButton blackText text="Deselect All" onClick={onDeselect} />
        )}
        {bulkState === BULK_ADD_TAG_SELECT_STATE && (
          <StyleTagWrapper
            showAddButton
            height="25px"
            maxWidth="100%"
            margin="0 0 0 20px"
            sortedAllTags={allTags}
            disabledButton={selectedNumber <= 0}
            onTagContact={onBulkAddTag}
            onCreateAndTagContact={onCreateAndBulkAddTag}
            onFocus={onFocusAddTagOnBulkAddTag}
          />
        )}
        {bulkState === BULK_DELETE_SELECT_STATE && (
          <SubmitButton
            small
            text="Delete"
            disabled={!selectedNumber}
            width="75px"
            height="25px"
            margin="0 0 0 20px"
            fontSize="14px"
            onClick={onSubmit}
          />
        )}
        {bulkState === BULK_REQUEST_APPLICATION_SELECT_STATE && (
          <SubmitButton
            small
            text="Send"
            disabled={!selectedNumber}
            width="75px"
            height="25px"
            margin="0 0 0 20px"
            fontSize="14px"
            onClick={onSendRentalApplication}
          />
        )}
      </ButtonsContainer>
    </PanelInner>
  </PanelWrapper>
)

export default ContactDashboardActionPanel
