import produce from 'immer'
import {
  GET_INCOME_ESTIMATE_REPORT,
  GET_INCOME_ESTIMATE_REPORT_ERROR,
  GET_INCOME_ESTIMATE_REPORT_SUCCESS,
  OPEN_INCOME_ESTIMATE_REPORT_SUCCESS,
  RESET_INCOME_ESTIMATE_REPORT,
} from './const'

export const initialState = {
  id: undefined,
  rentalSubmissionId: undefined,
  opened: undefined,
  report: undefined,
  isFetching: false,
  applicant: undefined,
}
/* eslint-disable no-param-reassign */
const reducer = (state = initialState, { type, payload }) =>
  produce(state, incomeEstimate => {
    switch (type) {
      case GET_INCOME_ESTIMATE_REPORT:
        incomeEstimate.isFetching = true
        break
      case GET_INCOME_ESTIMATE_REPORT_SUCCESS:
        incomeEstimate = payload
        incomeEstimate.isFetching = false
        break
      case RESET_INCOME_ESTIMATE_REPORT:
        incomeEstimate = initialState
        break
      case OPEN_INCOME_ESTIMATE_REPORT_SUCCESS:
        incomeEstimate.opened = true
        break
      case GET_INCOME_ESTIMATE_REPORT_ERROR:
        incomeEstimate.isFetching = false
        break
      default:
        break
    }
    return incomeEstimate
  })

export default reducer
