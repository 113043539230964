import React, { useState } from 'react'
import {
  StyledFeedbackContainer,
  StyledFeedbackThumbUpIcon,
  StyledFeedbackThumbDownIcon,
  StyledFeedbackIconWrapper,
  StyledFeedbackTitle,
} from './styles'
import {
  INCOME_ESTIMATE_REPORT_FEEDBACK_TITLE,
  INCOME_ESTIMATE_REPORT_FEEDBACK_VALUE,
} from './const'

const IncomeEstimateReportFeedback = ({ onClickThumbUp, onClickThumbDown }) => {
  const [feedbackValue, setFeedbackValue] = useState()

  const clickFeedbackThumbUp = () => {
    if (feedbackValue !== INCOME_ESTIMATE_REPORT_FEEDBACK_VALUE.THUMB_UP) {
      setFeedbackValue(INCOME_ESTIMATE_REPORT_FEEDBACK_VALUE.THUMB_UP)
      onClickThumbUp()
    }
  }

  const clickFeedbackThumbDown = () => {
    if (feedbackValue !== INCOME_ESTIMATE_REPORT_FEEDBACK_VALUE.THUMB_DOWN) {
      setFeedbackValue(INCOME_ESTIMATE_REPORT_FEEDBACK_VALUE.THUMB_DOWN)
      onClickThumbDown()
    }
  }

  return (
    <StyledFeedbackContainer>
      <StyledFeedbackTitle>{INCOME_ESTIMATE_REPORT_FEEDBACK_TITLE}</StyledFeedbackTitle>
      <StyledFeedbackIconWrapper>
        <StyledFeedbackThumbUpIcon
          width="20"
          height="20"
          viewBox="0 0 20 20"
          role="button"
          aria-label="feedbackThumbUp"
          onClick={clickFeedbackThumbUp}
          isSelected={feedbackValue === INCOME_ESTIMATE_REPORT_FEEDBACK_VALUE.THUMB_UP}
        >
          <path d="M17.5 6.66634H12.2417L13.0333 2.85801L13.0583 2.59134C13.0583 2.24967 12.9167 1.93301 12.6917 1.70801L11.8083 0.833008L6.325 6.32467C6.01667 6.62467 5.83333 7.04134 5.83333 7.49967V15.833C5.83333 16.7497 6.58333 17.4997 7.5 17.4997H15C15.6917 17.4997 16.2833 17.083 16.5333 16.483L19.05 10.608C19.125 10.4163 19.1667 10.2163 19.1667 9.99967V8.33301C19.1667 7.41634 18.4167 6.66634 17.5 6.66634ZM17.5 9.99967L15 15.833H7.5V7.49967L11.1167 3.88301L10.1917 8.33301H17.5V9.99967ZM0.833332 7.49967H4.16667V17.4997H0.833332V7.49967Z" />
        </StyledFeedbackThumbUpIcon>
        <StyledFeedbackThumbDownIcon
          width="20"
          height="20"
          viewBox="0 0 20 20"
          margin="5px 0px 0px 15px"
          role="button"
          aria-label="feedbackThumbDown"
          onClick={clickFeedbackThumbDown}
          isSelected={feedbackValue === INCOME_ESTIMATE_REPORT_FEEDBACK_VALUE.THUMB_DOWN}
        >
          <path d="M2.5 11.3337L7.75834 11.3337L6.96667 15.142L6.94167 15.4087C6.94167 15.7503 7.08334 16.067 7.30834 16.292L8.19167 17.167L13.675 11.6753C13.9833 11.3753 14.1667 10.9587 14.1667 10.5003L14.1667 2.16699C14.1667 1.25032 13.4167 0.500326 12.5 0.500326L5.00001 0.500325C4.30834 0.500325 3.71667 0.916991 3.46667 1.51699L0.950005 7.39199C0.875005 7.58366 0.833339 7.78366 0.833339 8.00032L0.833338 9.66699C0.833338 10.5837 1.58334 11.3337 2.5 11.3337ZM2.5 8.00032L5.00001 2.16699L12.5 2.16699L12.5 10.5003L8.88334 14.117L9.80834 9.66699L2.5 9.66699L2.5 8.00032ZM19.1667 10.5003L15.8333 10.5003L15.8333 0.500326L19.1667 0.500326L19.1667 10.5003Z" />
        </StyledFeedbackThumbDownIcon>
      </StyledFeedbackIconWrapper>
    </StyledFeedbackContainer>
  )
}

export default IncomeEstimateReportFeedback
