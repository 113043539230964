import { B14, S16, S24 } from '@rentspree/component-v2'
import { B12 } from 'components/typography'
import styled from 'styled-components'
import { COLOR } from 'styles/settings'
import NotEnoughDataIcon from 'images/income-estimate/not-enough-data-icon.png'
import CloseOrHigerIncomeIcon from 'images/income-estimate/close-or-higher-income-icon.png'
import LowerIncomeIcon from 'images/income-estimate/lower-income-icon.png'

export const IncomeEstimateHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 10px auto 20px auto;
  max-width: 760px;
  @media (max-width: 991px) {
    margin: 10px 20px 20px 20px;
  }
`

export const IncomeEstimateWrapper = styled.div`
  padding-bottom: 20px;
  @media (max-width: 991px) {
    margin: 0 20px 0 20px;
  }
`

export const IncomeEstimateReportHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  ${props => props.margin && `margin: ${props.margin}`};
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: flex-start;
    margin: 8px 0 50px 0;
  }
`

export const IncomeEstimateWarningWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  height: calc(100vh - 364px);
  min-height: 540px;
  @media (max-width: 991px) {
    height: auto;
    margin-bottom: 100px;
  }
`

export const IncomeEstimateExpiredContainer = styled.div`
  margin-top: 131px;
  @media (max-width: 991px) {
    margin-top: -8px;
  }
`

export const IncomeEstimateWarningImgWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 30px;
  @media (max-width: 991px) {
    margin-top: 20px;
  }
`

export const StyledGradientButtonWrapper = styled.div`
  > button {
    max-width: 600px;
    margin-inline: auto;
    margin-top: 40px;
    padding: 0 23.5px;
    border-radius: 100px;
    font-size: 16px;
    font-weight: 600;
    white-space: nowrap;
  }
`

export const StyledS24 = styled(S24)`
  line-height: 32px;
`

export const DesktopSubtitleWrapper = styled.div`
  display: block;
  @media (max-width: 991px) {
    display: none;
  }
`

export const MobileSubtitleWrapper = styled.div`
  display: none;
  @media (max-width: 991px) {
    display: block;
    line-height: 22px;
    padding: 10px;
  }
`

export const StyledS16 = styled(S16)`
  ${props => props.lineHeight && `line-height: ${props.lineHeight}`};
  ${props => props.margin && `margin: ${props.margin}`};
  ${props => props.padding && `padding: ${props.padding}`};
  ${props => props.borderTop && `border-top: ${props.borderTop}`};
  > b {
    font-weight: 600;
  }
`

export const IncomeEstimateReportWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`

export const IncomeEstimateReportDateWrapper = styled.div`
  text-align: right;
  @media (max-width: 991px) {
    text-align: left;
    margin-top: 5px;
  }
`

export const IncomeEstimateReportApplicantDetailWrapper = styled.div`
  display: block;
  text-align: left;
  margin-left: 28px;
  @media (max-width: 991px) {
    margin-left: 0px;
  }
`

export const IncomeEstimateReportDescription = styled(B12)`
  margin: 22px 28px 0px 28px;
  text-align: left;
  max-width: 579px;
  @media (max-width: 991px) {
    margin: 20px 0px 30px 0px;
  }
`

export const IncomeEstimateReportDataWrapper = styled.div`
  margin: 50px 28px 110px 28px;
  text-align: left;
  @media (max-width: 991px) {
    margin: 0px 0px 80px 0px;
  }
`

export const IncomeEstimateReportFooterWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  border-top: ${COLOR.grayLv3} solid 1px;
  margin: 220px 28px 28px 28px;
  padding-top: 8px;
  @media (max-width: 991px) {
    margin: 50px 0px 0px 0px;
    display: block;
  }
`

export const IncomeEstimateReportTUCompany = styled.div`
  grid-area: 1 / 1 / 2 / 2;
  text-align: left;
  @media (max-width: 991px) {
    margin: 0px 0px 10px 0px;
  }
`

export const IncomeEstimateReportRentSpreeCompany = styled.div`
  grid-area: 1 / 2 / 2 / 3;
  text-align: left;
  @media (max-width: 991px) {
    margin: 0px 0px 0px 0px;
  }
`

export const StyledIncomeEstimateReportData = styled.div`
  #disclaimer,
  #footer,
  #income-reported,
  #income-estimate-title,
  hr,
  #income-rent,
  #income-rent-title {
    display: none;
  }

  #income-estimate-msg {
    font-family: Source Sans Pro, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: ${COLOR.textBlack};
    margin: 0;
  }

  #income-estimate-msg:before {
    display: block;
    content: 'Income Estimate';
    font-family: Source Sans Pro, sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 10px;
    margin-left: 19px;
  }

  .icons-thumbs-up {
    background-image: url('${CloseOrHigerIncomeIcon}');
    background-size: 12px 12px;
    background-repeat: no-repeat;
    background-position: left;
    position: absolute;
    height: 25px;
    width: 25px;
  }

  .icons-mitigate-risk {
    background-image: url('${LowerIncomeIcon}');
    background-size: 12px 12px;
    background-repeat: no-repeat;
    background-position: left;
    position: absolute;
    height: 25px;
    width: 25px;
  }

  .icons-alert {
    background-image: url('${NotEnoughDataIcon}');
    background-size: 12px 12px;
    background-repeat: no-repeat;
    background-position: left;
    position: absolute;
    height: 25px;
    width: 25px;
  }
`

export const StyledFeedbackContainer = styled.div`
  margin: 0px 28px 0px 28px;
  display: flex;
  justify-content: start;
  align-items: center;
  background: ${COLOR.bgGrey};
  min-height: 50px;
  gap: 10px;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: baseline;
    padding: 15px 0px 15px 0px;
    margin: 0px;
  }
`

export const StyledFeedbackTitle = styled(B14)`
  margin: 0px 0px 0px 20px;
  line-height: 20px;
  text-align: left;
`

export const StyledFeedbackIconWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const StyledFeedbackThumbUpIcon = styled.svg`
  cursor: pointer;
  fill: ${props => (props.isSelected ? COLOR.activeBlue : COLOR.grayLv3)} };
  ${props => props.margin && `margin: ${props.margin}`};
  &:hover {
    fill: ${props => (props.isSelected ? COLOR.activeBlue : COLOR.grayscale40)};
  }
`

export const StyledFeedbackThumbDownIcon = styled.svg`
  cursor: pointer;
  fill: ${props => (props.isSelected ? COLOR.activeBlue : COLOR.grayLv3)};
  ${props => props.margin && `margin: ${props.margin}`};
  &:hover {
    fill: ${props => (props.isSelected ? COLOR.activeBlue : COLOR.grayscale40)};
  }
`
