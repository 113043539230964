import React from 'react'
import Styled from 'styled-components'
import {
  APPLICATION_SECTION_TEXT,
  APPLICATION_TYPE,
  CREDIT_REPORT_SUBTITLE,
  PRO_PLAN,
  USER_EXPERIENCE,
} from 'containers/tenant-screening/request-v2.2/constants'
import checkMark from "images/icons/alacarte/check-mark.svg"
import get from "lodash/get"
import {useGate} from "statsig-react"
import CheckboxSvg from '../../../atoms/checkbox-svg'
import BooleanCardWithoutBorderWithPrice from '../boolean-card-without-border-with-price'

const CreditReportAndScoreContainer = Styled.div``

const CustomScreeningBox = props => {
  const {
    userPlan,
    boxControl,
    requestOptions,
    priceSchema,
    userExperience,
    handleSelectCreditReportItems,
    handleSelectIncomeVerification,
    handleViewCustomDocument,
  } = props
  const { applicationType, eviction, criminal, incomeVerificationOption, creditReport } =
    requestOptions

  const isPro = userPlan === PRO_PLAN
  const isShowNewBadge =
    !isPro &&
    userExperience !== USER_EXPERIENCE.FIRST_TIME_SCREENING
  const creditReportPrice = get(priceSchema, 'creditReport.price', 0)
  const criminalPrice = get(priceSchema, 'criminal.price', 0)
  const evictionPrice = get(priceSchema, 'eviction.price', 0)
  const premiumPrice = get(priceSchema, 'premium.price', 0)

  const incomeVerificationText ='Income Verification Report'
  const incomeVerificationSubText = () => (
      <div>
        Real-time bank data showing income and employment Plus reference checks & ID upload.{' '}
      </div>
    )

  const IconImageCheck = () => <img src={checkMark} alt="check-mark" />

  const subTitleMapper = () => {
    if (userPlan === PRO_PLAN || incomeVerificationOption) {
      return 'Includes Applicant Identity Verification.'
    }
    return CREDIT_REPORT_SUBTITLE.Standard
  }

  return (
    <div>
      <CreditReportAndScoreContainer>
        {applicationType === APPLICATION_TYPE.NO_APPLICATION ? (
          <BooleanCardWithoutBorderWithPrice
            testId="credit-report"
            title="Credit Report & Score"
            subtitle={subTitleMapper()}
            isHidden={!boxControl.enabledBoxes.creditReport.isEnabled}
            checked
            canHover={false}
            icon={IconImageCheck}
            alt="creditReport"
            price={creditReportPrice}
          />
        ) : (
          <BooleanCardWithoutBorderWithPrice
            testId="credit-report"
            title="Credit Report & Score"
            subtitle={subTitleMapper()}
            checked={creditReport}
            icon={CheckboxSvg}
            alt="creditReport"
            price={creditReportPrice}
            onClick={() => {
              handleSelectCreditReportItems({
                creditReport: !creditReport,
              })
            }}
          />
        )}
        <BooleanCardWithoutBorderWithPrice
          isShowProBadge={false}
          testId="eviction"
          isHidden={!boxControl.enabledBoxes.creditReport.enabledItems.eviction}
          isDisabled={!creditReport}
          title="Eviction Related Proceedings"
          checked={eviction}
          icon={CheckboxSvg}
          alt="eviction"
          onClick={() => {
            handleSelectCreditReportItems({
              eviction: !eviction,
            })
          }}
          price={evictionPrice}
        />
        <BooleanCardWithoutBorderWithPrice
          isShowProBadge={false}
          testId="criminal"
          isHidden={!boxControl.enabledBoxes.creditReport.enabledItems.criminal}
          isDisabled={!creditReport}
          title="Criminal Background Check"
          checked={criminal}
          icon={CheckboxSvg}
          alt="criminal"
          onClick={() => {
            handleSelectCreditReportItems({
              criminal: !criminal,
            })
          }}
          price={criminalPrice}
        />
      </CreditReportAndScoreContainer>
      <BooleanCardWithoutBorderWithPrice
        isLastItem
        isShowNewBadge={isShowNewBadge}
        isShowProBadge={isPro}
        testId="income-verification"
        title={incomeVerificationText}
        subtitle={incomeVerificationSubText()}
        checked={incomeVerificationOption}
        isHidden={!boxControl.enabledBoxes.creditReport.enabledItems.income_verification}
        isDisabled={!(isPro || creditReport)}
        icon={CheckboxSvg}
        alt="income_verification"
        onClick={handleSelectIncomeVerification}
        price={isPro ? 0 : premiumPrice}
        ctaText="Customize document request"
        handleViewCustomDocument={handleViewCustomDocument}
      />
    </div>
  )
}

export default CustomScreeningBox
