import { useGate } from 'statsig-react'

import { AGENT_INCENTIVE_PROGRAM_PAYMENT_ADOPTION } from 'utils/feature-flag/constants'
import history from 'utils/history'
import { usePaymentIncentiveInformationPageReducer } from 'v3/components/incentive-information/redux/use-payment-incentive-information-page-reducer'
import { useAutoOpenAgentIncentiveInformationPage } from 'v3/components/incentive-information/use-auto-open-incentive-information-page'
import { useGetMyIncentiveProgramEligibility } from 'v3/hooks/use-get-my-incentive-eligibility'

export const AgentIncentiveEligible = ({ children }) => {
  const isEligible = useGetMyIncentiveProgramEligibility()
  const { value: isAgentIncentiveEnabled } = useGate(AGENT_INCENTIVE_PROGRAM_PAYMENT_ADOPTION)
  usePaymentIncentiveInformationPageReducer()
  useAutoOpenAgentIncentiveInformationPage({ pathname: history?.location?.pathname })

  if (!isEligible?.data?.isEligiblePartner || !isAgentIncentiveEnabled) {
    return null
  }

  return children
}
