import { Box } from '@mui/material'
import debounce from 'lodash/debounce'
import React, { useState, useCallback } from 'react'

import { CloseBar } from 'v3/containers/overhaul-rent-payment/components/close-bar/close-bar'
import { RentPaymentFooter } from 'v3/containers/overhaul-rent-payment/components/footer/rent-payment-footer'
import { RentPaymentStepper } from 'v3/containers/overhaul-rent-payment/components/stepper/rent-payment-stepper'
import { pageNames, pages } from 'v3/containers/overhaul-rent-payment/constants'

import {
  PageIndexesContext,
  pageIndexesTemplate,
  PageProgressionContext,
  PaymentInfoContext,
  HistoryContext,
} from '../context'

import { smartNextIndexesCalc } from './utils'

export const OverhaulRentPaymentPageRoot = ({ history }) => {
  /*
   * The root page for the rent-payment flow, orchestrating the other pages within the pages/ subfolders
   * along with the general organization.
   *
   * Pages are broken out separately, and the order-consistent 'pages' variable below + pageIndexes keeps
   * track of the user's page state through the flow.
   */

  const defaultPaymentInfo = {
    'recipient-details': {
      recipient: 'client',
      'recipient-info': {
        'first-name': 'Tim',
        'last-name': 'Timothy',
        email: 'tt@mothy.com',
      },
    },
    'property-and-tenant': {
      property: {
        street: '6403 Martin Luther King Junior Way South',
        city: 'Seattle',
        state: 'WA',
        zip: '98118',
        isAddressVerified: true,
        propertyType: 'N/A',
        origin: 'rentspree',
        userRoleForProperty: 'landlord',
        landlordProfile: {
          email: 'ians+devlandlord@rentspree.com',
        },
        createdAt: '2024-10-21T23:12:47.550Z',
        updatedAt: '2024-10-21T23:12:47.550Z',
        __v: 0,
        _id: '6716dfef132d2500223a5038',
        landlord_id: '66f70e7ad0d927001d3ca8b8',
        total_shared: 0,
        total_requested: 0,
        total_submitted: 0,
        total_unopen: 0,
      },
      tenant: {
        id: '67170b8586950500241656f5',
        renterInfoId: '67170b8586950500241656f5',
        firstName: 'Tim',
        lastName: 'Timothy',
        email: 'tt@reee.com',
        phone: '(513) 555-6464',
        propertyId: null,
      },
    },
    'payment-details': {
      payments: [
        {
          name: 'Rent',
          amount: 500,
          dueOn: 1,
          category: 'Rent',
          paymentType: 'recurring',
          firstPayment: '2024-11-01',
          lastPayment: 'Month-to-month',
          repeat: 'monthly',
          dueDate: '12-12-2024',
          feeCollection: {
            bank: 'payee',
            card: 'payee',
          },
          monthlyDescription: 'This is note',
          term: 'perpetual',
        },
        {
          name: 'Move in fee',
          amount: 50,
          dueDate: '2024-10-24',
          category: 'Move-in fee',
          paymentType: 'oneTime',
          feeCollection: {
            bank: 'payer',
            card: 'payee',
          },
        },
      ],
      'payment-selections': {
        rent: true,
        fees: true,
      },
    },
    review: {
      inviteByText: true,
    },
  }
  const [paymentInfo, setPaymentInfo] = useState(defaultPaymentInfo)

  const [pageIndexes, setPageIndexes] = useState(pageIndexesTemplate)

  const [historyObj] = useState(history)

  const debouncedSetPageIndexes = useCallback(
    debounce(nextIndexes => {
      setPageIndexes(smartNextIndexesCalc(nextIndexes, pageIndexes))
    }, 200),
    [pageIndexes],
  )
  const key = pageNames[pageIndexes.pageL1Index]
  const { pageField, progressionHandler, Page, buttonLayout, textOverrides } = pages.get(key)
  const backupProgressionHandler = ({ increment = false, decrement = false }) => {
    debouncedSetPageIndexes({
      pageL1Index: Math.min(
        pageNames.length - 1,
        Math.max(0, pageIndexes.pageL1Index + increment - decrement),
      ),
    })
  }

  const contextProgressionHandler =
    progressionHandler === null ? backupProgressionHandler : progressionHandler

  /*
   * leveraging useCallback to save some rendering power, slight workaround for inheritance forcing hook vars be passed in
   * Allows rendering callers to just worry about increment/decrement below regardless
   */
  const simplifiedProgressionHandler = useCallback(
    ({ increment = false, decrement = false }) =>
      contextProgressionHandler(
        { increment, decrement },
        pageIndexes,
        debouncedSetPageIndexes,
        pageField,
        paymentInfo,
      ),
    [pageIndexes, debouncedSetPageIndexes, pageField, paymentInfo],
  )

  const updatePageDataHandler = newData => {
    // should only change data under the page's dedicated field, while keeping all page data in one object
    setPaymentInfo(prevState => {
      return { ...prevState, [pageField]: newData }
    })
  }

  return (
    /*
     * Disabling explainer:
     * seems leveraging an array instantiation in general throws a useMemo error, but useMemo would just change every time they changed as well...
     * StackOverflow suggests it's a potentially out of date linting rule: https://stackoverflow.com/q/71454846
     * Open to refactoring if internal best practices prefer the useMemo approach still
     */
    /* eslint-disable react/jsx-no-constructed-context-values */
    <PageIndexesContext.Provider value={[pageIndexes, debouncedSetPageIndexes]}>
      <CloseBar />
      <RentPaymentStepper
        stepTitles={pageNames}
        pageIndex={pageIndexes.pageL1Index}
        handleClick={i => debouncedSetPageIndexes({ pageL1Index: i })}
      />
      <PaymentInfoContext.Provider value={[paymentInfo, setPaymentInfo]}>
        <PageProgressionContext.Provider value={simplifiedProgressionHandler}>
          <HistoryContext.Provider value={historyObj}>
            <Box
              sx={{
                fontFamily: 'Inter',
                marginLeft: 0,
                marginBottom: '108px',

                display: 'flex',
                justifyContent: 'center',
                alignContent: 'flex-start',
                flexDirection: 'column',
                textAlign: 'left',
              }}
              spacing={2}
            >
              <Box
                style={{
                  width: '100%',
                  maxWidth: '450px',
                  margin: 'auto',
                  justifyContent: 'left',
                  alignContent: 'center',
                }}
              >
                <Page pageField={pageField} updateDataHandler={updatePageDataHandler} />
              </Box>
            </Box>
            <RentPaymentFooter
              pagesIndexes={pageIndexes}
              buttonLayout={buttonLayout}
              textOverrides={textOverrides}
            />
          </HistoryContext.Provider>
        </PageProgressionContext.Provider>
      </PaymentInfoContext.Provider>
    </PageIndexesContext.Provider>
  )
}
