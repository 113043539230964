/* eslint-disable no-underscore-dangle */
import { takeLatest, all, put, call, select } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { buildPath, query } from '@rentspree/path'
import * as Sentry from '@sentry/browser'

import { apiInstance, apiInstanceWithErrorHandler } from 'utils/api-interceptor'
import { openSweetAlertBaseError } from 'utils/sweet-alert-actions'

import {
  CREATE_LISTING_SCREENING_REQUEST_API,
  GET_APPLY_LINK_API,
  SCREENING_NEW_TENANT_ACTION_V2,
  PROPERTY_DETAIL_V2,
} from 'constants/route'
import { SCREENING_REQUEST_SOURCE } from 'containers/constants'
import { APPLICATION_TYPE } from 'constants/application-type'
import { PAYER_TYPES } from 'constants/user'
import { selectScreeningRequestResultPath } from 'containers/request/helpers/select-paths'
import {
  getScreeningPlansApiState,
  getUserPreferenceApiState,
  updateUserPreferenceApiState,
  createScreeningRequestApiState,
  getPropertyApiState,
  createScreeningRequestWithoutPropertyApiState,
  updateUserPreference,
  generatePermalinkRequestActions,
  createListingScreeningRequestActions,
  getScreeningRequestLandingActions,
  updateDocument,
} from './actions'
import {
  GET_SCREENING_PLANS_API,
  GET_SCREENING_PLANS_CALL,
  GET_USER_PREFERENCE_API,
  CREATE_SCREENING_REQUEST_API,
  CREATE_SCREENING_REQUEST_CALL,
  GET_PROPERTY_CALL,
  CREATE_SCREENING_REQUEST_WO_PROPERTY_API,
  CREATE_SCREENING_REQUEST_WO_PROPERTY_CALL,
  GET_USER_PREFERENCE_CALL,
  UPDATE_USER_PREFERENCE_CALL,
  UPDATE_USER_PREFERENCE_API,
  GET_SCREENING_REQUEST_LANDING_CALL,
  PRO_PLAN,
  SAVE_CUSTOM_DOCUMENT,
} from './constants'
import {
  selectCreateScreeningRequestPayload,
  selectProperty,
  selectUserPreferencePayload,
  selectPermalink,
} from './selectors'
import { convertScreeningRequestToPreference } from './utils'

export const getScreeningPlansApi = payload =>
  apiInstance.get(
    buildPath(GET_SCREENING_PLANS_API, {}, { state: payload?.state, zipCode: payload?.zip }),
  )

export const getPropertyApi = payload =>
  apiInstance.get(buildPath(PROPERTY_DETAIL_V2, { propertyId: payload }))

export const getUserPreferenceApi = () => apiInstance.get(GET_USER_PREFERENCE_API)

export const updateUserPreferenceApi = preference =>
  apiInstance.put(UPDATE_USER_PREFERENCE_API, preference)

export const createScreeningRequestApi = ({ propertyId, body }) =>
  apiInstance.post(buildPath(CREATE_SCREENING_REQUEST_API, { propertyId }), body)

export const createScreeningRequestWithoutPropertyApi = body =>
  apiInstance.post(CREATE_SCREENING_REQUEST_WO_PROPERTY_API, body)

export function* createScreeningRequestWithoutPropertySaga({ payload = {} }) {
  const { agentType, emails, origin, userPlan } = payload
  yield put(createScreeningRequestWithoutPropertyApiState.request())
  const screeningRequestPayload = yield select(selectCreateScreeningRequestPayload)
  if (userPlan === PRO_PLAN) {
    screeningRequestPayload.premium = false
  }
  try {
    const screeningRequest = yield call(createScreeningRequestWithoutPropertyApi, {
      ...screeningRequestPayload,
      agentType,
    })
    yield put(createScreeningRequestWithoutPropertyApiState.success())
    yield put(updateUserPreference({ userPlan }))
    yield put(
      push(
        buildPath(
          SCREENING_NEW_TENANT_ACTION_V2,
          {},
          { screeningRequestId: screeningRequest.id, origin },
        ),
        { emails: emails || [] },
      ),
    )
  } catch (err) {
    yield put(openSweetAlertBaseError(err.status))
    yield put(createScreeningRequestWithoutPropertyApiState.failure(err))
  }
}

export function* getScreeningPlansSaga({ payload = {} }) {
  yield put(getScreeningPlansApiState.request())
  try {
    const response = yield call(getScreeningPlansApi, payload.property)
    yield put(getScreeningPlansApiState.success(response))
    return response
  } catch (err) {
    yield put(getScreeningPlansApiState.failure(err))
    Sentry.captureException(err)
    return null
  }
}

export function* getPropertySaga({ payload }) {
  yield put(getPropertyApiState.request())
  try {
    const response = yield call(getPropertyApi, payload)
    yield put(getPropertyApiState.success(response))
    return response
  } catch (err) {
    yield put(getPropertyApiState.failure(err))
    Sentry.captureException(err)
    return null
  }
}

export function* getUserPreferenceSaga({ payload }) {
  // TODO: this function will be renamed
  yield put(getUserPreferenceApiState.request())
  try {
    const userPreference = yield call(getUserPreferenceApi)
    const result = {
      ...userPreference.result,
      ...convertScreeningRequestToPreference(payload.screeningRequestPreference),
    }
    yield put(getUserPreferenceApiState.success({ result }))
    return result
  } catch (err) {
    yield put(getUserPreferenceApiState.failure({ err }))
    Sentry.captureException(err)
    return null
  }
}

export function* updateUserPreferenceSaga({ payload }) {
  yield put(updateUserPreferenceApiState.request())
  try {
    const userPreferencePayload = yield select(selectUserPreferencePayload)
    const { userPlan } = payload
    if (userPlan === PRO_PLAN && userPreferencePayload.defaultScreeningOption?.premium) {
      userPreferencePayload.defaultScreeningOption.premium = false
    }
    const response = yield call(updateUserPreferenceApi, {
      ...userPreferencePayload,
      isInDesignConceptExperiment: true,
    })
    yield put(updateUserPreferenceApiState.success(response.result))
  } catch (err) {
    yield put(updateUserPreferenceApiState.failure(err))
    Sentry.captureException(err)
  }
}

export function* createScreeningRequestSaga({ payload }) {
  yield put(createScreeningRequestApiState.request())
  const { requestOptions, agentLicenses, getNextPath, userPlan } = payload
  const property = yield select(selectProperty)

  // eslint-disable-next-line no-underscore-dangle
  const propertyId = property._id
  const screeningRequestPayload = yield select(selectCreateScreeningRequestPayload)
  if (userPlan === PRO_PLAN) {
    screeningRequestPayload.premium = false
  }
  const isScreeningRequestForListing =
    screeningRequestPayload.source === SCREENING_REQUEST_SOURCE.LISTING
  try {
    const screeningRequest = yield call(createScreeningRequestApi, {
      propertyId,
      body: {
        ...screeningRequestPayload,
        agentType: payload?.agentType,
      },
    })
    yield put(createScreeningRequestApiState.success(screeningRequest))
    yield put(updateUserPreference({ userPlan }))

    if (isScreeningRequestForListing) {
      const { location, screeningMethod } = payload
      yield call(createScreeningRequestForListing, {
        agentLicenses,
        location,
        propertyId,
        screeningMethod,
        screeningRequest,
      })
      return
    }

    const nextPath = getNextPath({
      requestOptions,
      propertyId: property._id,
      // screeningMethod,
      screeningRequestId: screeningRequest.id,
      agentLicenses,
    })
    yield put(push(nextPath))
  } catch (err) {
    yield put(openSweetAlertBaseError(err.status))
    yield put(createScreeningRequestApiState.failure(err))
    Sentry.captureException(err)
  }
}

export function* createScreeningRequestForListing({
  agentLicenses,
  location,
  propertyId,
  screeningMethod,
  screeningRequest,
}) {
  const { id: screeningRequestId, screeningOption } = screeningRequest
  const {
    application,
    applicationType,
    credit_report: creditReport,
    criminal,
    eviction,
    payerType,
  } = screeningOption
  if (
    applicationType === APPLICATION_TYPE.RENTSPREE ||
    applicationType === undefined ||
    (applicationType === APPLICATION_TYPE.CAR_LRA && payerType === PAYER_TYPES.RENTER)
  ) {
    try {
      yield call(createGenerateApplyLink, { screeningRequestId })
      yield call(createListingScreeningRequest, {
        propertyId,
        payload: {
          applicationType,
          hasApplication: application,
          hasCreditReport: creditReport,
          payerType,
          hasCriminalRecord: criminal,
          hasEviction: eviction,
        },
      })
    } catch {
      return
    }
  }
  const requestOptions = { applicationType, payer: payerType }
  const screeningMethodSearch = screeningMethod ? { screeningMethod } : {}

  const nextPage = selectScreeningRequestResultPath({
    requestOptions,
    licenses: agentLicenses,
    propertyId,
    screeningRequestId,
    otherQueryString: {
      ...screeningMethodSearch,
      ...query.parse(location.search),
    },
  })
  yield put(push(nextPage))
}

const createListingScreeningRequestEndpoint = propertyId =>
  buildPath(CREATE_LISTING_SCREENING_REQUEST_API, { propertyId })

export const callGenerateApplyLinkAPI = ({ screeningRequestId }) =>
  apiInstanceWithErrorHandler.get(buildPath(GET_APPLY_LINK_API, { screeningRequestId }))

export function* createGenerateApplyLink({ screeningRequestId }) {
  yield put(generatePermalinkRequestActions.request())
  try {
    const { permalink } = yield call(callGenerateApplyLinkAPI, {
      screeningRequestId,
    })
    yield put(generatePermalinkRequestActions.success(permalink))
  } catch (err) {
    yield put(openSweetAlertBaseError(err.status))
    yield put(generatePermalinkRequestActions.failure())
  }
}

export const callCreateListingScreeningRequestAPI = ({ propertyId, payload }) =>
  apiInstanceWithErrorHandler.put(createListingScreeningRequestEndpoint(propertyId), payload)

export function* createListingScreeningRequest({ propertyId, payload }) {
  const permalink = yield select(selectPermalink)
  const reshapedScreeningRequest = { ...payload, url: permalink }
  yield put(createListingScreeningRequestActions.request())
  try {
    yield call(callCreateListingScreeningRequestAPI, {
      propertyId,
      payload: reshapedScreeningRequest,
    })
    yield put(createListingScreeningRequestActions.success())
  } catch (err) {
    yield put(openSweetAlertBaseError(err.status))
    yield put(createListingScreeningRequestActions.failure())
  }
}

export function* getScreeningRequestLandingSaga({ payload }) {
  yield put(getScreeningRequestLandingActions.request())
  try {
    const property = payload.isScreeningWithoutProperty
      ? null
      : yield call(getPropertySaga, { payload: payload.propertyId })
    const screeningPlan = yield call(getScreeningPlansSaga, { payload: { property } })
    const userPreference = yield call(getUserPreferenceSaga, {
      payload: { screeningRequestPreference: payload.screeningRequestPreference },
    })
    yield put(
      getScreeningRequestLandingActions.success({ screeningPlan, userPreference, property }),
    )
  } catch (error) {
    yield put(getScreeningRequestLandingActions.failure())
  }
}

export function* saveCustomDocumentSaga({ payload }) {
  yield put(updateDocument(payload))
  yield put(updateUserPreference(payload))
}

export function* watchGetScreeningPlans() {
  yield takeLatest(GET_SCREENING_PLANS_CALL, getScreeningPlansSaga)
}

export function* watchGetProperty() {
  yield takeLatest(GET_PROPERTY_CALL, getPropertySaga)
}

export function* watchGetUserPreference() {
  yield takeLatest(GET_USER_PREFERENCE_CALL, getUserPreferenceSaga)
}

export function* watchUpdateUserPreference() {
  yield takeLatest(UPDATE_USER_PREFERENCE_CALL, updateUserPreferenceSaga)
}

export function* watchCreateScreeningRequest() {
  yield takeLatest(CREATE_SCREENING_REQUEST_CALL, createScreeningRequestSaga)
}

export function* watchGetScreeningLandingRequest() {
  yield takeLatest(GET_SCREENING_REQUEST_LANDING_CALL, getScreeningRequestLandingSaga)
}

export function* watchCreateScreeningRequestWithoutProperty() {
  yield takeLatest(
    CREATE_SCREENING_REQUEST_WO_PROPERTY_CALL,
    createScreeningRequestWithoutPropertySaga,
  )
}

export function* watchSaveCustomDocument() {
  yield takeLatest(SAVE_CUSTOM_DOCUMENT, saveCustomDocumentSaga)
}

export function* rootSaga() {
  yield all([
    watchGetScreeningPlans(),
    watchGetProperty(),
    watchGetUserPreference(),
    watchUpdateUserPreference(),
    watchCreateScreeningRequest(),
    watchCreateScreeningRequestWithoutProperty(),
    watchGetScreeningLandingRequest(),
    watchSaveCustomDocument(),
  ])
}

export default rootSaga
