import Box from '@mui/material/Box'
import Button from '@rentspree/component-2023.components.atoms.button'
import EmptyState from '@rentspree/component-2023.components.templates.empty-state'
import Level1Template from '@rentspree/component-2023.components.templates.level-1-template'
import React from 'react'
import { useGate } from 'statsig-react'

import LandingV2 from 'components/landing-v2'
import useMobile from 'hooks/use-mobile'
import { useTenantScreeningStepUrl } from 'hooks/use-tenant-screening-step-url'
import tracker from 'tracker'
import { MOBILE_THRESHOLD } from 'utils/constants'
import { ZERO_STATE_SCREENING } from 'utils/feature-flag/constants'

import {
  EXTRA_DETAILS,
  MAIN_CONTENT,
  EXTRA_TITLE,
  BUTTON_TEXT,
  TOP_BUTTON_ID,
  BOTTOM_BUTTON_ID,
} from '../constants'

export const OldLanding = ({ onCTAClick }) => (
  <LandingV2
    handleTopOnClick={onCTAClick}
    handleBottomOnClick={onCTAClick}
    mainContent={MAIN_CONTENT}
    extraTitle={EXTRA_TITLE}
    extraDetails={EXTRA_DETAILS}
    buttonText={BUTTON_TEXT}
    topButtonId={TOP_BUTTON_ID}
    bottomButtonId={BOTTOM_BUTTON_ID}
    buttonMaxWidth="189px"
    width="920px"
    mWidth="100%"
    mPadding="0 20px"
    height="100%"
  />
)

const ZERO_STATE_SCREENING_IMAGE_URL =
  'https://cdn.rentspree.com/static-files/zero-state/screening-page-empty-state.svg'

export const NewLanding = ({ onCTAClick }) => {
  const isMobile = useMobile(MOBILE_THRESHOLD)
  const { getFirstScreeningRequestStepUrl } = useTenantScreeningStepUrl()

  const handleCTAClick = () => {
    const url = getFirstScreeningRequestStepUrl()
    tracker.trackButtonClick({
      click_text: 'Screen tenant',
      click_url: url,
      location: 'empty_state',
    })
    onCTAClick()
  }

  const handleSampleReportClick = () => {
    const url = 'https://support.rentspree.com/en/a-completed-rentspree-application'
    tracker.trackButtonClick({
      click_text: 'View sample report',
      click_url: url,
      location: 'empty_state',
    })
    window.open(url, '_blank')
  }

  return (
    <>
      <Box>
        <Level1Template title="Screening" isMobile={isMobile} />
      </Box>
      <Box sx={{ height: '100%' }}>
        <EmptyState
          title="Screen with confidence"
          subTitle="Quickly identify qualified renters with credit, rental history, and background checks, plus a comprehensive income report."
          image={<img src={ZERO_STATE_SCREENING_IMAGE_URL} alt="empty-state-screening" />}
          cta={
            <Button onClick={handleCTAClick} variant="contained" color="primary" size="small">
              Screen tenant
            </Button>
          }
          secondaryCTA={
            <Button
              onClick={handleSampleReportClick}
              variant="text"
              color="secondary"
              size="small"
              className="underline"
            >
              View sample report
            </Button>
          }
        />
      </Box>
    </>
  )
}

export const TenantScreeningLandingComponent = ({ onCTAClick }) => {
  const { value: isZeroStateTenantScreeningEnabled } = useGate(ZERO_STATE_SCREENING)

  if (isZeroStateTenantScreeningEnabled) {
    return <NewLanding onCTAClick={onCTAClick} />
  }

  return <OldLanding onCTAClick={onCTAClick} />
}
