import {
  GET_INCOME_ESTIMATE_REPORT,
  GET_INCOME_ESTIMATE_REPORT_ERROR,
  GET_INCOME_ESTIMATE_REPORT_SUCCESS,
  RESET_INCOME_ESTIMATE_REPORT,
  OPEN_INCOME_ESTIMATE_REPORT,
  OPEN_INCOME_ESTIMATE_REPORT_SUCCESS,
} from './const'

export const getIncomeEstimateReport = rentalSubmissionId => ({
  type: GET_INCOME_ESTIMATE_REPORT,
  payload: { rentalSubmissionId },
})

export const getIncomeEstimateReportSuccess = payload => ({
  type: GET_INCOME_ESTIMATE_REPORT_SUCCESS,
  payload,
})

export const resetIncomeEstimateReport = () => ({
  type: RESET_INCOME_ESTIMATE_REPORT,
})

export const openIncomeEstimateReport = payload => ({
  type: OPEN_INCOME_ESTIMATE_REPORT,
  payload,
})

export const openIncomeEstimateReportSuccess = () => ({
  type: OPEN_INCOME_ESTIMATE_REPORT_SUCCESS
})

export const getIncomeEstimateReportError = payload => ({
  type: GET_INCOME_ESTIMATE_REPORT_ERROR,
  payload
})
