import { useToAgreementPath, useToFullMailingPath } from 'hooks'
import {
  useToAppDetailPath,
  useToAssignPropertyPath,
  useToPendingPath,
  useToTenantScreeningListFromPendingPath,
  useToTenantScreeningDetailPath,
  useToOpenReportWithoutPropertyPath,
} from './use-to-path'

export const useTenantScreeningOpenRequire = (
  isCombinedAgreementIdvFeatureEnabled = false,
  queryString = {},
) => {
  const tenantScreeningDetailPath = useToTenantScreeningDetailPath()
  const tenantScreeningListPath = useToTenantScreeningListFromPendingPath()
  const pendingPath = useToPendingPath()
  const assignPropertyPath = useToAssignPropertyPath()
  const agreementPath = useToAgreementPath()
  const fullMailingPath = useToFullMailingPath()
  const appDetailPath = useToAppDetailPath(queryString)
  const openReportWithoutPropertyPath = useToOpenReportWithoutPropertyPath(queryString)

  if (tenantScreeningListPath) return tenantScreeningListPath
  if (pendingPath) return pendingPath
  if (agreementPath && !isCombinedAgreementIdvFeatureEnabled) return agreementPath
  if (fullMailingPath && !isCombinedAgreementIdvFeatureEnabled) return fullMailingPath
  if (assignPropertyPath) return assignPropertyPath
  if (openReportWithoutPropertyPath) return openReportWithoutPropertyPath
  if (appDetailPath) return appDetailPath

  return tenantScreeningDetailPath
}
