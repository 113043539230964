import isFinite from 'lodash/isFinite'
import head from 'lodash/head'
import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import cloneDeep from 'lodash/cloneDeep'
import isBoolean from 'lodash/isBoolean'

import {
  PAYER_TYPE,
  APPLICATION_TYPE,
  USER_EXPERIENCE,
  PAYER_TYPE_MAPPER,
  DEFAULT_DOCUMENT_OPTIONS,
} from './constants'

export const getProOptionPayload = ({
  documentProOption = {},
  incomeVerificationOption = false,
  application = false,
}) => {
  const proOptionPayload = {}
  const referencePayload =
    incomeVerificationOption && application
      ? [
          { type: 'personal', arrayIndex: 1 },
          { type: 'personal', arrayIndex: 0 },
          { type: 'previous_landlord' },
          { type: 'current_landlord' },
          { type: 'former_employer' },
          { type: 'current_employer' },
        ]
      : []

  const documentPayload = incomeVerificationOption ? documentProOption : []

  if (!isEmpty(documentPayload))
    proOptionPayload.documents = incomeVerificationOption ? documentProOption : []
  if (!isEmpty(referencePayload)) proOptionPayload.references = referencePayload

  return proOptionPayload
}

export const convertToPrice = value => {
  const parsedValue = parseInt(value, 10)
  const priceText = !isFinite(parsedValue) && 0
  return priceText === 0 || parsedValue === 0 ? 'Free' : `$${value}`
}

export const pricePositionFormat = price =>
  parseInt(price, 10) === parseFloat(price) ? price.toFixed(0) : price.toFixed(2)

export const getCurrentPriceScheme = (payer, itemList) =>
  head(filter(itemList, ['payerType', payer]))

export const convertScreeningOptionToPayload = (screeningOption, source) => {
  const payload = {
    criminal: false,
    credit_report: false,
    eviction: false,
    application: false,
    payerType: '',
    premium: false,
    applicationType: '',
    income: false,
    source,
  }
  switch (screeningOption.payerType) {
    case PAYER_TYPE.APPLICANTS:
      // TODO: USE const instead
      payload.payerType = 'renter'
      break
    case PAYER_TYPE.ME:
      payload.payerType = 'landlord'
      break
    default:
      break
  }

  switch (screeningOption.applicationType) {
    case APPLICATION_TYPE.RENTSPREE:
      payload.application = true
      payload.applicationType = 'rentspree'
      break
    case APPLICATION_TYPE.CAR_LRA:
      payload.application = true
      payload.applicationType = 'car_lra'
      break
    case APPLICATION_TYPE.NO_APPLICATION:
      delete payload.applicationType
      payload.application = false
      break
    default:
      break
  }

  if (screeningOption.creditReport) payload.credit_report = true
  if (screeningOption.eviction) payload.eviction = true
  if (screeningOption.criminal) payload.criminal = true
  if (screeningOption.incomeVerificationOption) {
    payload.premium = true
    payload.income = true
  }

  return payload
}

export const convertPayloadToRequestOption = (
  payload,
  priceSchema,
  userExperience,
  isPro,
  isScreeningWithoutProperty = false,
) => {
  const { premium, creditReport, eviction, criminal } = priceSchema

  const isPremiumDisabled = isBoolean(premium?.disabled) ? premium.disabled : true
  const isCreditReportDisabled = isBoolean(creditReport?.disabled) ? creditReport.disabled : true
  const isEvictionDisabled = isBoolean(eviction?.disabled) ? eviction.disabled : true
  const isCriminalDisabled = isBoolean(criminal?.disabled) ? criminal.disabled : true

  const requestOption = {
    payerType: '',
    applicationType: '',
    creditReport: false,
    eviction: false,
    criminal: false,
    incomeVerificationOption: false,
  }

  switch (payload.payerType) {
    case 'renter':
      requestOption.payerType = PAYER_TYPE.APPLICANTS
      break
    case 'landlord':
      requestOption.payerType = PAYER_TYPE.ME
      break
    default:
      break
  }
  if (userExperience === USER_EXPERIENCE.SCREENING_V2_EXPERIENCED) {
    if (payload.defaultScreeningOption.application) {
      if (payload.applicationType === APPLICATION_TYPE.CAR_LRA && !isScreeningWithoutProperty) {
        requestOption.applicationType = APPLICATION_TYPE.CAR_LRA
      } else {
        requestOption.applicationType = APPLICATION_TYPE.RENTSPREE
      }
    } else {
      requestOption.applicationType = APPLICATION_TYPE.NO_APPLICATION
    }

    requestOption.creditReport = isCreditReportDisabled
      ? false
      : // eslint-disable-next-line
        !!payload?.defaultScreeningOption?.credit_report

    requestOption.criminal = isCriminalDisabled
      ? false
      : !!payload?.defaultScreeningOption?.criminal
    requestOption.eviction = isEvictionDisabled
      ? false
      : !!payload?.defaultScreeningOption?.eviction

    requestOption.incomeVerificationOption =
      isPremiumDisabled || (!requestOption.creditReport && !isPro)
        ? false
        : !!payload?.defaultScreeningOption?.income
    if (requestOption.incomeVerificationOption) {
      if (isEmpty(payload?.defaultScreeningOption?.documentOption)) {
        requestOption.documentOption = DEFAULT_DOCUMENT_OPTIONS
      } else {
        requestOption.documentOption = payload?.defaultScreeningOption?.documentOption
      }
    }
  }

  if (
    userExperience === USER_EXPERIENCE.SCREENING_EXPERIENCED ||
    userExperience === USER_EXPERIENCE.FIRST_TIME_SCREENING
  ) {
    const {
      eviction: newEviction,
      criminal: newCriminal,
      incomeVerificationOption,
    } = preselectCustomScreeningOptionsWithPriceSchema(requestOption, priceSchema)

    requestOption.payerType = PAYER_TYPE.APPLICANTS
    requestOption.creditReport = true
    requestOption.eviction = newEviction
    requestOption.criminal = newCriminal
    requestOption.incomeVerificationOption = incomeVerificationOption
    if (requestOption.incomeVerificationOption) {
      if (isEmpty(payload?.defaultScreeningOption?.documentOption)) {
        requestOption.documentOption = DEFAULT_DOCUMENT_OPTIONS
      } else {
        requestOption.documentOption = payload?.defaultScreeningOption?.documentOption
      }
    }

    if (payload.applicationType === APPLICATION_TYPE.CAR_LRA && !isScreeningWithoutProperty) {
      requestOption.applicationType = APPLICATION_TYPE.CAR_LRA
    } else {
      requestOption.applicationType = APPLICATION_TYPE.RENTSPREE
    }
  }

  if (isPremiumDisabled) {
    requestOption.incomeVerificationOption = false
    requestOption.documentOption = []
  }

  return requestOption
}

export const mapPriceSchema = (screeningPlansState, requestOptionState) => {
  const screeningPlans = cloneDeep(screeningPlansState)
  const requestOption = cloneDeep(requestOptionState)
  const userSelectScreeningPlans =
    screeningPlans.find(plan => PAYER_TYPE_MAPPER[requestOption?.payerType] === plan.payerType) ||
    screeningPlans.find(plan => plan.payerType === 'all')

  if (isEmpty(userSelectScreeningPlans)) {
    return {
      application: {
        price: 0,
        disabled: false,
      },
      creditReport: {
        price: 0,
        disabled: false,
      },
      eviction: {
        price: 0,
        disabled: false,
      },
      criminal: {
        price: 0,
        disabled: false,
      },
      premium: {
        price: 0,
        disabled: false,
      },
    }
  }

  return {
    application: {
      ...userSelectScreeningPlans.application,
      isSelected: requestOption.application,
    },
    creditReport: {
      ...userSelectScreeningPlans.credit_report,
      isSelected: requestOption.credit_report,
    },
    criminal: {
      ...userSelectScreeningPlans.criminal,
      isSelected: requestOption.criminal,
    },
    eviction: {
      ...userSelectScreeningPlans.eviction,
      isSelected: requestOption.eviction,
    },
    premium: {
      ...{
        price: userSelectScreeningPlans.premium?.price,
        disabled: isBoolean(userSelectScreeningPlans.premium?.disabled)
          ? userSelectScreeningPlans.premium.disabled
          : true,
      },
      isSelected: requestOption.premium,
    },
  }
}

export const preselectCustomScreeningOptionsWithPriceSchema = (
  requestOptionState,
  priceSchemaState,
) => {
  const newRequestOption = cloneDeep(requestOptionState)
  const priceSchema = cloneDeep(priceSchemaState)

  const { eviction, criminal, premium } = priceSchema
  const isEvictionDisabled = isBoolean(eviction?.disabled) ? eviction.disabled : true
  const isCriminalDisabled = isBoolean(criminal?.disabled) ? criminal.disabled : true
  const isPremiumDisabled = isBoolean(premium?.disabled) ? premium.disabled : true

  newRequestOption.creditReport = true
  newRequestOption.eviction = !isEvictionDisabled
  newRequestOption.criminal = !isCriminalDisabled
  newRequestOption.incomeVerificationOption = !isPremiumDisabled

  return newRequestOption
}

export const convertScreeningRequestToPreference = screeningRequest => {
  if (!screeningRequest) {
    return {}
  }

  return {
    defaultScreeningOption: {
      criminal: screeningRequest.criminal === 'true',
      eviction: screeningRequest.eviction === 'true',
      credit_report: screeningRequest.creditReport === 'true',
      application: screeningRequest.application === 'true',
      premium: screeningRequest.premium === 'true',
      refCheckOption: screeningRequest.references,
      documentOption: screeningRequest.documents,
    },
    payerType: screeningRequest.payerType,
    applicationType: screeningRequest.applicationType,
  }
}
