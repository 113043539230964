import InfoIcon from '@mui/icons-material/Info'
import { Box, Grid, Radio, RadioGroup, FormControl } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import Select from '@rentspree/component-2023.components.atoms.select'
import Typography from '@rentspree/component-2023.components.atoms.typography'
import React, { useContext } from 'react'

import { COMPONENT_2023_BREAKPOINT_MOBILE } from 'containers/constants'
import useMobile from 'hooks/use-mobile'

import { BUSINESS_RADIO_BUTTON } from '../../../../../components/organisms/tax-information-form/business-type-form/constants'
import { updateAccountInformation } from '../../../identity-verification/actions'
import { BUSINESS_STRUCTURE_OPTIONS } from '../../../identity-verification/business-type-step/constants'
import { BUSINESS_TYPE } from '../../../identity-verification/constants'
import { BusinessInfoContext } from '../../context'

const mobileGridSize = 12
const desktopGridSize = 6

export const handleBusinessInfoProgression = (
  { increment = false, decrement = false },
  curPageIndexes,
  setPageIndexes,
  _pageField,
  _curBusinessInfo,
  dispatch,
) => {
  const indexChanges = {
    pageL1Index: curPageIndexes.pageL1Index,
    drawerOpen: curPageIndexes.drawerOpen,
  }

  // eslint-disable-next-line no-console
  console.log('Progression handler is handling progression...')

  const information = {
    businessType: _curBusinessInfo.businessType,
  }
  if (_curBusinessInfo.businessType === BUSINESS_TYPE.COMPANY) {
    information.businessStructure = _curBusinessInfo.businessStructure
  }

  dispatch(updateAccountInformation({ data: information }))

  // shortcut invalid scenarios
  if (increment === decrement) {
    /*
     * logging an error, but this can still be consumer facing, so staying vague
     * need to find direct DD log avenue for debug details if possible
     */
    console.error('Unusual state for Recipient Details page progression request')
  } else {
    if (increment) {
      indexChanges.pageL1Index += 1
    }
    if (decrement) {
      indexChanges.pageL1Index -= 1
    }
  }

  /*
   * All decrement states for this page involve going back to the root of the flow
   * Absorbing errored-state traversal handling as well
   */
  setPageIndexes(indexChanges)
}

export const BusinessInfoPage = () => {
  const isMobile = useMobile(COMPONENT_2023_BREAKPOINT_MOBILE)
  const gridSize = isMobile ? mobileGridSize : desktopGridSize

  const [businessInfo, setBusinessInfo] = useContext(BusinessInfoContext)

  const setSelectedBusiness = value => {
    setBusinessInfo(prev => ({ ...prev, businessType: value }))
  }

  const setBusinessStructure = value => {
    setBusinessInfo(prev => ({ ...prev, businessStructure: value }))
  }
  const textStyle = {
    textAlign: isMobile ? 'left' : 'center',
  }

  const clickableBoxStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    border: 'thin solid #0000001f',
    borderRadius: '1rem',
    padding: '15px',
    textAlign: 'left',
    marginBottom: '1rem',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
  }

  return (
    <Grid item xs={gridSize}>
      {/* eslint-disable-next-line react/jsx-curly-brace-presence */}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem', marginTop: '1rem' }}>
        <Typography variant="title" sx={textStyle}>
          Next, select a business type
        </Typography>
        <p style={Object.assign(textStyle, { paddingBottom: '10px' })}>
          Are you taxed as an individual or a business?
        </p>
      </Box>
      <FormControl component="fieldset" sx={{ width: '100%' }}>
        <RadioGroup
          value={businessInfo.businessType || ''}
          onChange={e => setSelectedBusiness(e.target.value)}
        >
          <Box sx={clickableBoxStyle} onClick={() => setSelectedBusiness('individual')}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Box sx={{ flex: 1 }}>
                <Typography
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '1.6rem',
                  }}
                >
                  {BUSINESS_RADIO_BUTTON.INDIVIDUAL.subtitle}
                </Typography>
                <Typography
                  sx={{
                    fontSize: '1.3rem',
                  }}
                >
                  {BUSINESS_RADIO_BUTTON.INDIVIDUAL.toolsTipText}
                </Typography>
              </Box>
              <Radio
                checked={businessInfo.businessType === 'individual'}
                value="individual"
                onClick={e => e.stopPropagation()}
                onChange={e => setSelectedBusiness(e.target.value)}
                sx={{
                  '& .MuiSvgIcon-root': {
                    fontSize: '2.4rem',
                  },
                  padding: '12px',
                }}
              />
            </Box>
          </Box>
          <Box sx={clickableBoxStyle} onClick={() => setSelectedBusiness('company')}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Box sx={{ flex: 1 }}>
                <Typography
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '1.6rem',
                  }}
                >
                  Business
                  {/* {BUSINESS_RADIO_BUTTON.COMPANY.subtitle} This is 'Company', we want 'Business'*/}
                </Typography>
                <Typography
                  sx={{
                    fontSize: '1.3rem',
                  }}
                >
                  {BUSINESS_RADIO_BUTTON.COMPANY.toolsTipText}
                </Typography>
              </Box>
              <Radio
                checked={businessInfo.businessType === 'company'}
                value="company"
                onClick={e => e.stopPropagation()}
                onChange={e => setSelectedBusiness(e.target.value)}
                sx={{
                  '& .MuiSvgIcon-root': {
                    fontSize: '2.4rem',
                  },
                }}
              />
            </Box>
          </Box>
        </RadioGroup>
      </FormControl>

      {businessInfo.businessType === BUSINESS_TYPE.COMPANY && (
        <Select
          label="Business type"
          value={businessInfo.businessStructure || ''}
          onChange={event => {
            setBusinessStructure(event.target.value)
          }}
          data-testid="business-structure-select-component"
          style={{
            maxWidth: '504px',
          }}
          MenuProps={{
            style: { zIndex: 5050 },
          }}
          sx={{
            '.MuiSelect-select': {
              textAlign: 'left',
            },
          }}
        >
          {BUSINESS_STRUCTURE_OPTIONS.map(({ value, label }) => (
            <MenuItem
              value={value}
              data-testid="business-structure-option"
              key={`business-structure-option-${value}`}
              sx={{
                width: '100%',
                justifyContent: 'flex-start',
              }}
            >
              <Typography variant="body-medium">{label}</Typography>
            </MenuItem>
          ))}
        </Select>
      )}

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          padding: '15px',
          textAlign: 'left',
          marginBottom: '1rem',
          marginTop: '1rem',
          backgroundColor: '#f5f5f5',
          borderRadius: '1rem',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            gap: '16px',
          }}
        >
          <InfoIcon
            sx={{
              fontSize: '2.5rem',
            }}
          />
          <Box>
            <Typography
              sx={{
                fontWeight: 'bold',
                fontSize: '1.7rem',
              }}
            >
              Note: you won&#39;t be able to change this later
            </Typography>
            <Typography
              sx={{
                fontSize: '1.7rem',
              }}
            >
              Make sure you choose the correct business type now.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Grid>
  )
}
