import React from 'react'

import { S14 } from 'components/typography'

import { TULogo } from 'legacy/components/reports-v2/share/doc-style'
import transImg from 'legacy/images/credit-report/TU-logo.png'
import { getDate } from 'legacy/helpers/expired-credit-report'
import { WrapperCreditReport } from 'legacy/components/reports'

import {
  IncomeEstimateReportHeaderWrapper,
  IncomeEstimateExpiredContainer,
  IncomeEstimateWarningWrapper,
} from './styles'

const REPORT_EXPIRED = require('legacy/images/icons/report-expired.svg')

const IncomeEstimateExpiredComponent = ({ incomeEstimate }) => (
  <IncomeEstimateWarningWrapper>
    <IncomeEstimateReportHeaderWrapper>
      <TULogo src={transImg} alt="tu-logo" />
      <S14 margin={0}>Income Estimate</S14>
    </IncomeEstimateReportHeaderWrapper>
    <IncomeEstimateExpiredContainer>
      <WrapperCreditReport>
        <div>
          <img src={REPORT_EXPIRED} alt="Report expired" />
        </div>
        {`Income estimate expired on ${getDate(incomeEstimate.generatedAt)}.`} <br />
        To maintain accuracy, income estimate remains valid for 30 days.
      </WrapperCreditReport>
    </IncomeEstimateExpiredContainer>
  </IncomeEstimateWarningWrapper>
)

export default IncomeEstimateExpiredComponent
