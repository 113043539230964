import { Grid } from '@mui/material'
import React, { useContext } from 'react'
import { useDispatch } from 'react-redux'

import { useListPropertiesQuery } from 'v3/containers/rent-payment/apis/property'
import {
  selectProperty,
  selectRenterInfo,
} from 'v3/containers/rent-payment/setup-page/set-up-for-myself/redux/actions'
import {
  generateQuotationByType,
  saveDefaultFeeCollection,
} from 'v3/containers/rent-payment/setup-page/set-up-for-myself/steps/payment-details/utils'
import {
  useConfirmDraftRentalPayment,
  useGetOrCreateDraftRentalPayment,
  useUpdateDraftQuotations,
  useGetOrCreateRenterInfo,
} from 'v3/containers/rent-payment/shared/hooks'
import { useSearchRenterInfo } from 'v3/containers/rent-payment/shared/hooks/use-search-renter-info'

import { PaymentInfoContext, HistoryContext } from '../../context'
import { BackButton, NextButton } from '../buttons/footer-buttons'

/*
 * These constants needs to be imported from a shared file.
 * temporary placement to avoid circular dependencies
 */
export const buttonLayoutChoices = Object.freeze({
  // an enum to sync button layout options between createButtons & the footer's parent renderers
  NEXT: 'next',
  NEXT_BACK: 'next-back',
})
const TENANT_FIELD = 'tenant'
const PROPERTY_FIELD = 'property'
const PAYMENTS_FIELD = 'payments'
const RENT_PAYMENT_PATH = '/rent-payments'
const pageNames = ['recipient-details', 'property-and-tenant', 'payment-details', 'review']
const pageOptions = Object.freeze({
  RECIPIENT_DETAILS: 'recipient-details',
  PROPERTY_AND_TENANT: 'property-and-tenant',
  PAYMENT_DETAILS: 'payment-details',
  REVIEW: 'review',
})

/*
 * CamelCasing it because,
 * 1. It is a propoer component returning jsx
 * 2. we need to use hooks from within this component, and without this pre-commit fails.
 *
 * NOTE: Need to discuss and refactor
 */
export const CreateButtons = (
  pageIndexes,
  progressionHandler,
  buttonLayout,
  textOverrides = {},
) => {
  /* A dynamic handler for all of the Rental Payment page button configurations we might need. */
  // while this section defines the the layout style, the user might want different text
  const dispatch = useDispatch()
  const { back: backstepText = 'Back', next: forwardText = 'Next' } = textOverrides
  const [paymentInfo] = useContext(PaymentInfoContext)
  const historyObj = useContext(HistoryContext)
  const { confirmDraftRentalPayment } = useConfirmDraftRentalPayment()
  const { data: quotations, updateDraftQuotations } = useUpdateDraftQuotations()
  const { pageL1Index } = pageIndexes
  const { data: renterInfoSearchList } = useSearchRenterInfo()
  const listPropertiesQuery = useListPropertiesQuery()
  const { getOrCreateRenterInfo } = useGetOrCreateRenterInfo()
  const { getOrCreateDraftRentalPayment, data: draftRentalPayment } =
    useGetOrCreateDraftRentalPayment()

  const handlePropertyAndTenant = async pageInfo => {
    const { id: tenantId } = pageInfo?.[TENANT_FIELD] || {}
    const { _id: propertyId } = pageInfo?.[PROPERTY_FIELD] || {}
    const renterInfoSearch = renterInfoSearchList.find(({ id }) => id === tenantId)
    const property = listPropertiesQuery.data?.data?.find(({ _id }) => _id === propertyId)

    if (renterInfoSearch.renterInfoId) {
      await getOrCreateDraftRentalPayment({
        propertyId,
        renterInfoId: renterInfoSearch.renterInfoId,
      })
      dispatch(selectRenterInfo(renterInfoSearch))
    } else {
      const renterInfo = await getOrCreateRenterInfo(renterInfoSearch)
      await getOrCreateDraftRentalPayment({
        propertyId: property._id,
        renterInfoId: renterInfo.id,
      })
      dispatch(selectRenterInfo(renterInfo))
    }

    dispatch(selectProperty(property))
  }

  const handlePaymentDetails = async pageInfo => {
    const quotes = pageInfo?.[PAYMENTS_FIELD] || []
    const updatedQuotations = quotes.map(quote => {
      const newQuotation = {
        ...generateQuotationByType(quote),
        feeCollection: {
          bank: 'payee',
          card: 'payee',
        },
      }
      saveDefaultFeeCollection(newQuotation.feeCollection)
      return newQuotation
    })

    await updateDraftQuotations({
      rentalPaymentId: draftRentalPayment.id,
      quotations: updatedQuotations,
    })
  }

  const handleReview = async () => {
    const pageInfo = paymentInfo?.[pageOptions.PROPERTY_AND_TENANT] || {}
    const { phone } = pageInfo?.[TENANT_FIELD] || {}
    const { _id: propertyId } = pageInfo?.[PROPERTY_FIELD] || {}
    const { inviteByText } = paymentInfo?.[pageOptions.REVIEW] || {}

    if (!propertyId) {
      /* eslint-disable no-console */
      console.log('property id not found')
      /* eslint-enable no-console */
    }

    if (quotations.length === 0) {
      /* eslint-disable no-console */
      console.log('payment details not found')
      /* eslint-enable no-console */
    }

    await confirmDraftRentalPayment({
      rentalPaymentId: draftRentalPayment?.id,
      propertyId,
      rentInfo: quotations,
      invitationDetail: {
        sms: inviteByText,
        ...(inviteByText && phone ? { phone } : {}),
      },
    })

    historyObj.push(RENT_PAYMENT_PATH)
  }

  const saveAndProgress = async action => {
    try {
      if (await progressionHandler(action)) {
        const pageInfo = paymentInfo?.[pageNames[pageL1Index]] || {}

        switch (pageNames[pageL1Index]) {
          case pageOptions.RECIPIENT_DETAILS:
            break
          case pageOptions.PROPERTY_AND_TENANT:
            await handlePropertyAndTenant(pageInfo)
            break

          case pageOptions.PAYMENT_DETAILS:
            await handlePaymentDetails(pageInfo)
            break

          case pageOptions.REVIEW:
            await handleReview()
            break

          default: {
            /* eslint-disable no-console */
            console.log('Should not reach this state: ', pageNames[pageL1Index])
            /* eslint-enable no-console */
          }
        }
      }
    } catch (error) {
      /* eslint-disable no-console */
      console.error('Something went wrong', error)
      /* eslint-enable no-console */
    }
  }

  // default error state
  let renderedButtons = (
    <Grid item xs={4}>
      <p>Button configuration error?</p>
    </Grid>
  )

  // quick & bulky definition for now, we can refactor later
  if (buttonLayout === buttonLayoutChoices.NEXT) {
    renderedButtons = (
      <>
        {/* This grid offset only helps for the desktop view */}
        <Grid item xs={9} />
        <Grid item xs={3} sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <NextButton onClick={() => saveAndProgress({ increment: true })} variant="contained">
            {forwardText}
          </NextButton>
        </Grid>
        <Grid item xs={1} />
      </>
    )
  } else if (buttonLayout === buttonLayoutChoices.NEXT_BACK) {
    renderedButtons = (
      <>
        {/* right: 0 isn't working for BackButton, so an offset Grid
         * is pushing the button away from the edge
         */}

        <Grid item xs={1} />
        <Grid item xs={8}>
          <BackButton onClick={() => saveAndProgress({ decrement: true })} variant="text">
            {backstepText}
          </BackButton>
        </Grid>
        <Grid item sx={{ display: 'flex', flexDirection: 'row-reverse' }} xs={3}>
          <NextButton onClick={() => saveAndProgress({ increment: true })} variant="contained">
            {forwardText}
          </NextButton>
        </Grid>
        <Grid item xs={1} />
      </>
    )
  } else {
    console.error(`No button configuration for this state! Layout choice '${buttonLayout}'?`)
  }

  return renderedButtons
}
