import isEmpty from 'lodash/isEmpty'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useExperiment } from 'statsig-react'

import { useGetCountRentalSubmission } from 'components/tenant-screening-widget/use-get-count-rental-submission'
import { selectProfile } from 'containers/user/selectors'
import { STATSIG, useFeatureFlag } from 'hooks/use-statsig-feature-flag'
import {
  ONBOARDING_PARTNER_AGENT,
  ONBOARDING_PARTNER_AGENT_EXPERIMENT,
} from 'utils/feature-flag/constants'
import {
  SEEN_ANNOUNCEMENT_TYPE,
  SEEN_ONBOARDING_PARTNER_AGENT,
} from 'v3/containers/page-dashboard/const'

import { useMutateMySeenAnnouncement } from './use-mutate-seen-announcement'
import { useQuerySeenAnnouncements } from './use-query-seen-announcement'

// use global variables for shared state to multiple hooks
let isHighlightScreenTenant = false
let observers = []

// changes global isHighlightScreenTenant state and updates all observers
export const setIsHighlightScreenTenant = highlightScreenTenant => {
  isHighlightScreenTenant = highlightScreenTenant
  observers.forEach(update => update(highlightScreenTenant))
}

export const useOnboardingPartnerAgent = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [isOnboardingVisible, setIsOnboardingVisible] = useState(false)
  const [isHighlightScreenTenantState, setIsHighlightScreenTenantState] = useState(false)

  const userProfile = useSelector(selectProfile)
  const { isUserProfileEmpty, hasExternalIntegration } = useMemo(
    () => ({
      isUserProfileEmpty: isEmpty(userProfile),
      hasExternalIntegration: !!userProfile?.externalIntegration,
    }),
    [userProfile],
  )

  const { data: seenAnnouncements, isFetched: hasFetchedSeenAnnouncements } =
    useQuerySeenAnnouncements()
  const hasSeenOnboarding = useMemo(
    () => !!seenAnnouncements?.['seen_groups']?.[SEEN_ONBOARDING_PARTNER_AGENT],
    [seenAnnouncements?.['seen_groups']?.[SEEN_ONBOARDING_PARTNER_AGENT]],
  )

  // TODO: clean up feature flag and experiment
  const { isEnabled: isOnboardingFeatureFlagEnabled, isLoading: isFeatureFlagLoading } =
    useFeatureFlag({
      statsigFeatureFlagKey: ONBOARDING_PARTNER_AGENT,
      featureFlagProvider: STATSIG,
    })
  const { config: onboardingExperimentConfig, isLoading: isOnboardingExperimentLoading } =
    useExperiment(ONBOARDING_PARTNER_AGENT_EXPERIMENT)
  const DEFAULT_MODAL_TEMPLATE = 'a'
  const modalTemplate = onboardingExperimentConfig.get('template', DEFAULT_MODAL_TEMPLATE)

  const markOnboardingAsSeen = useMutateMySeenAnnouncement({
    announcementType: SEEN_ANNOUNCEMENT_TYPE.GET_STARTED,
    groups: [SEEN_ONBOARDING_PARTNER_AGENT],
  })

  const { data: rentalSubmissionCounts, isLoading: isRentalSubmissionLoading } =
    useGetCountRentalSubmission()
  const hasScreening = useMemo(
    () => rentalSubmissionCounts && rentalSubmissionCounts.total > 0,
    [rentalSubmissionCounts?.total],
  )

  const hideOnboarding = () => {
    setIsOnboardingVisible(false)
    markOnboardingAsSeen.mutate()
  }

  const disableOnboarding = () => {
    setIsLoading(false)
    setIsOnboardingVisible(false)
  }

  const enableOnboarding = () => {
    setIsLoading(false)
    setIsOnboardingVisible(true)
  }

  const shouldHideOnboarding = () => {
    const isFeatureFlagDisabled = !isFeatureFlagLoading && !isOnboardingFeatureFlagEnabled
    const isNotApplicableForUser = !isUserProfileEmpty && !hasExternalIntegration

    return isFeatureFlagDisabled || hasSeenOnboarding || isNotApplicableForUser || hasScreening
  }

  const isLoadingComplete = () => {
    return (
      !isUserProfileEmpty &&
      !isFeatureFlagLoading &&
      !isOnboardingExperimentLoading &&
      !isRentalSubmissionLoading &&
      hasFetchedSeenAnnouncements
    )
  }

  useEffect(() => {
    // add setIsHighlightScreenTenantState to observers list
    observers.push(setIsHighlightScreenTenantState)

    // update setIsHighlightScreenTenantState with latest global isOnline state
    setIsHighlightScreenTenantState(isHighlightScreenTenant)

    // remove this setIsHighlightScreenTenantState from observers, when component unmounts
    return () => {
      observers = observers.filter(update => update !== setIsHighlightScreenTenantState)
    }
  }, [])

  useEffect(() => {
    if (shouldHideOnboarding()) {
      disableOnboarding()
    } else if (isLoadingComplete()) {
      enableOnboarding()
    }
  }, [
    isUserProfileEmpty,
    isFeatureFlagLoading,
    isRentalSubmissionLoading,
    hasFetchedSeenAnnouncements,
    hasSeenOnboarding,
    isOnboardingFeatureFlagEnabled,
    hasExternalIntegration,
    hasScreening,
    isOnboardingExperimentLoading,
  ])

  return {
    isLoading,
    isOnboardingVisible,
    hideOnboarding,
    modalTemplate,
    isHighlightScreenTenant: isHighlightScreenTenantState,
    setIsHighlightScreenTenant,
  }
}
