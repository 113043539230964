export const FETCHED_CREDIT_REPORT_IMAGE = "FETCHED_CREDIT_REPORT_IMAGE"
export const FETCHED_CRIMINAL_IMAGE = "FETCHED_CRIMINAL_IMAGE"
export const FETCHED_EVICTION_IMAGE = "FETCHED_EVICTION_IMAGE"

export const WITH_OPEN_PAGE_STATE = {
  AGREEMENT_PAGE_STATE: "AGREEMENT_PAGE_STATE",
  MAILING_ADDRESS_PAGE_STATE: "MAILING_ADDRESS_PAGE_STATE",
  LRA_SIGN_PAGE_STATE: "LRA_SIGN_PAGE_STATE",
}

export const EXTERNAL_SCREENING_PROVIDER_NAME = {
  SMARTMOVE: "smartmove",
  SHAREABLE: "shareable",
}

export const TU_BUNDLE_ID = {
  CREDIT_PLUS_CRIMINAL: 5002,
  ALL_OPTIONS: 5003,
  CREDIT_PLUS_EVICTION: 5005,
  CREDIT_ONLY: 5009,
  ALL_PLUS_INCOME_ESTIMATE: 5013,
}
