import { combineReducers } from 'redux'
import get from 'lodash/get'

import { LEASE_AGREEMENT_TERM, LEASE_TERM_VERSION } from 'constants/terms'
import { UPDATE_DRE_LICENSE_SUCCESS } from 'containers/request/step-update-dre-license/constants'

import * as Types from '../constants/action-types'
import { sentUserToSentry } from '../helpers/sentry'
import { UPDATE_LEASE_AGREEMENT_CONSENT_SUCCESS } from '../../containers/lease-agreement-details/constants'

export const profile = (state = {}, action) => {
  const terms = {}
  switch (action.type) {
    case Types.GET_PROFILE_SUCCESS:
    case Types.UPDATE_PROFILE_SUCCESS:
      sentUserToSentry(action.profile)
      return { ...action.profile } || {}
    case UPDATE_DRE_LICENSE_SUCCESS:
      // Note: this action come from Saga that has a different payload
      sentUserToSentry(action.payload.result)
      return { ...action.payload.result } || {}
    case UPDATE_LEASE_AGREEMENT_CONSENT_SUCCESS:
      terms[LEASE_AGREEMENT_TERM] = LEASE_TERM_VERSION
      return { ...state, terms }
    default:
      return state
  }
}

const userIntegration = (state = [], action) => {
  switch (action.type) {
    case Types.GET_USER_INTEGRATION_SUCCESS:
      return get(action, 'integration', [])
    default:
      return state
  }
}

const isFetching = (state = false, action) => {
  switch (action.type) {
    case Types.GET_PROFILE_REQUEST:
    case Types.GET_USER_INTEGRATION_REQUEST:
      return true
    case Types.GET_PROFILE_SUCCESS:
    case Types.GET_USER_INTEGRATION_SUCCESS:
    case Types.GET_USER_INTEGRATION_FAIL:
    case Types.GET_PROFILE_FAIL:
      return false
    default:
      return state
  }
}

const isFetched = (state = false, action) => {
  switch (action.type) {
    case Types.GET_PROFILE_REQUEST:
    case Types.GET_PROFILE_FAIL:
      return false
    case Types.GET_PROFILE_SUCCESS:
      return true
    default:
      return state
  }
}

// Separate from isFetching to show different loading
const isUpdating = (state = false, action) => {
  switch (action.type) {
    case Types.UPDATE_PROFILE_REQUEST:
      return true
    case Types.UPDATE_PROFILE_SUCCESS:
    case Types.UPDATE_PROFILE_FAILED:
      return false
    default:
      return state
  }
}

const preference = (state = {}, action) => {
  switch (action.type) {
    case Types.GET_USER_PREFERENCE_SUCCESS:
      return action.data.result
    default:
      return state
  }
}

const isFetchedPreference = (state = false, action) => {
  switch (action.type) {
    case Types.GET_USER_PREFERENCE_REQUEST:
      return false
    case Types.GET_USER_PREFERENCE_SUCCESS:
    case Types.GET_USER_PREFERENCE_FAILED:
      return true
    default:
      return state
  }
}

export default combineReducers({
  profile,
  isFetching,
  isFetched,
  isUpdating,
  isFetchedPreference,
  preference,
  userIntegration,
})
