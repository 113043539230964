import React from 'react'
import get from 'lodash/get'
import isArray from 'lodash/isArray'
import { compose } from 'redux'
import { TopNavbar } from '@rentspree/navbar'
import { BlueGradientButton, Checkbox, S16 } from '@rentspree/component-v2'

import { useGate } from 'statsig-react'
import tracker from 'tracker'
import { REVIEW_REPORT_EVENT } from 'tracker/const'
import {
  ReportReviewingWrapper,
  CheckboxWrapper,
  TitleWrapper,
  SubmitButtonWrapper,
  TermAndConditionLabel,
  StickyFooterWrapper,
} from 'components/reports/reviewing'
import { COLOR } from 'components/constants'
import { ConfirmationAccordion } from 'components/organisms/confirmation-accordion'
import { TitleWithSubtitle } from 'components/molecules/title-with-subtitle'
import { getPartnerLogoDashboardSrc } from 'utils/subdomain'

import useMobile from 'hooks/use-mobile'
import { ButtonSetV2 } from 'components/buttons/button-set'
import { PARTNER_NAME } from 'constants/partner'
import { DOMAIN_NAME, API_URL, USER_API_URL } from '../../../env'
import ShareableCreditReport from '../screening-reports/shareable/credit-report'
import ShareableCriminalReport from '../screening-reports/shareable/criminal'
import ShareableEvictionReport from '../screening-reports/shareable/eviction'
import withOpen from '../screening-reports/with-open'
import {
  MOBILE_THRESHOLD,
  REPORT_ACCORDION_HEADER_TEXT,
  REPORT_REVIEWING_HEADER_TEXT,
  REPORT_REVIEWING_TERMS,
  CREDIT_REPORT,
  EVICTION,
  CRIMINAL,
  SUBMIT,
  DASHBOARD_OVERVIEW,
} from './constants'
import { withReducer, withConnect, withSaga } from './connect'
import { TU_BUNDLE_ID } from '../screening-reports/constants'

const { EVENT_NAME } = REVIEW_REPORT_EVENT
export const ReportReviewing = ({
  rentalDetail,
  profile,
  subdomain,
  isSubmitting,
  isSubmitted,
  submitRentalCall,
  errorCreditReport,
  bundleId,
  reportOrder = {},
}) => {
  const shouldNextToDocUploadForReposition =
    isArray(rentalDetail?.proOption?.documents) && rentalDetail?.proOption?.documents.length > 0
  const shouldNextToDocUploadForIncomeVerification =
    rentalDetail?.screeningOption.income

  const shouldNextToDocumentUpload =
    shouldNextToDocUploadForReposition || shouldNextToDocUploadForIncomeVerification

  const [isCreditReportExpanded, setCreditReportToExpand] = React.useState(true)
  const [isCreditReportConfirmed, setCreditReportToConfirm] = React.useState(false)
  const [isCriminalReportExpanded, setCriminalReportToExpand] = React.useState(false)
  const [isCriminalReportConfirmed, setCriminalReportToConfirm] = React.useState(false)
  const [isEvictionReportExpanded, setEvictionReportToExpand] = React.useState(false)
  const [isEvictionReportConfirmed, setEvictionReportToConfirm] = React.useState(false)
  const [isDisabledSubmitButton, setIsDisabledSubmitButton] = React.useState(true)

  const [isAcceptTerm, setAcceptTerm] = React.useState(false)
  const [isShowError, setIsShowError] = React.useState(false)

  const screeningOption = get(rentalDetail, 'screeningOption', {})
  const isCriminalRequested = !!screeningOption.criminal
  const isEvictionRequested = !!screeningOption.eviction

  const isMobile = useMobile(MOBILE_THRESHOLD)
  const [currentReportStep, setCurrentReportStep] = React.useState(CREDIT_REPORT)

  React.useEffect(() => {
    tracker.trackEvent(EVENT_NAME.REVIEW_REPORT, {
      screening_option: screeningOption,
      payer_type: screeningOption.payerType,
      template: rentalDetail?.application?.type || 'No application',
      template_version: rentalDetail?.application?.version || 'N/A',
      // eslint-disable-next-line no-underscore-dangle
      rental_id: rentalDetail?._id,
    })
  }, [])

  React.useEffect(() => {
    let disabled = true

    if (isCriminalRequested && isEvictionRequested) {
      disabled =
        !isCreditReportConfirmed || !isCriminalReportConfirmed || !isEvictionReportConfirmed
    } else if (!isCriminalRequested && isEvictionRequested) {
      disabled = !isCreditReportConfirmed || !isEvictionReportConfirmed
    } else if (isCriminalRequested && !isEvictionRequested) {
      disabled = !isCreditReportConfirmed || !isCriminalReportConfirmed
    } else if (!isCriminalRequested && !isEvictionRequested) {
      disabled = !isCreditReportConfirmed
    }

    if (!disabled) {
      setIsDisabledSubmitButton(false)
      setCurrentReportStep(SUBMIT)
    }
  }, [isCreditReportConfirmed, isCriminalReportConfirmed, isEvictionReportConfirmed])

  const toggleAcceptTerm = () => {
    setAcceptTerm(!isAcceptTerm)
    if (!isAcceptTerm) {
      setIsShowError(false)
    }
  }

  const redirectDocumentUploadPage = applyLink => {
    window.location.replace(`${applyLink}/documents`)
  }
  const redirectIncomeVerificationPage = applyLink => {
    window.location.replace(`${applyLink}/income-verification`)
  }

  const handleSubmit = () => {
    if (!isAcceptTerm) {
      setIsShowError(true)
    } else if (shouldNextToDocumentUpload) {
      if (screeningOption.income) {
        if (bundleId === TU_BUNDLE_ID.ALL_PLUS_INCOME_ESTIMATE) {
          redirectDocumentUploadPage(rentalDetail.applyLink)
        } else {
          redirectIncomeVerificationPage(rentalDetail.applyLink)
        }
      } else {
        redirectDocumentUploadPage(rentalDetail.applyLink)
      }
    } else {
      submitRentalCall(rentalDetail.id)
      tracker.trackEvent(EVENT_NAME.SUBMIT_REPORT, {
        screening_option: screeningOption,
        payer_type: screeningOption.payerType,
        template: rentalDetail?.application?.type || 'No application',
        template_version: rentalDetail?.application?.version || 'N/A',
        // eslint-disable-next-line no-underscore-dangle
        rental_id: rentalDetail._id,
      })
    }
  }

  const setExpand = section => {
    if (!isMobile) {
      switch (section) {
        case CREDIT_REPORT:
          setCreditReportToExpand(!isCreditReportExpanded)
          setCriminalReportToExpand(false)
          setEvictionReportToExpand(false)
          break
        case CRIMINAL:
          setCriminalReportToExpand(!isCriminalReportExpanded)
          setCreditReportToExpand(false)
          setEvictionReportToExpand(false)
          break
        case EVICTION:
          setEvictionReportToExpand(!isEvictionReportExpanded)
          setCreditReportToExpand(false)
          setCriminalReportToExpand(false)
          break
        default:
          break
      }
    }
  }

  const setConfirm = section => {
    if (section !== SUBMIT) {
      tracker.trackEvent(EVENT_NAME.CONFIRM_INFORMATION, {
        report: section.toLowerCase().split('_').join(' '),
        // eslint-disable-next-line no-underscore-dangle
        rental_id: rentalDetail._id,
      })
    }
    switch (section) {
      case CREDIT_REPORT:
        setCreditReportToExpand(false)
        setCreditReportToConfirm(true)
        if (!isCriminalReportConfirmed && isCriminalRequested) {
          setCriminalReportToExpand(true)
          setCurrentReportStep(CRIMINAL)
        } else if (!isEvictionReportConfirmed && isEvictionRequested) {
          setEvictionReportToExpand(true)
          setCurrentReportStep(EVICTION)
        }
        break
      case CRIMINAL:
        setCriminalReportToExpand(false)
        setCriminalReportToConfirm(true)
        if (!isCreditReportConfirmed) {
          setCreditReportToExpand(true)
          setCurrentReportStep(CREDIT_REPORT)
        } else if (!isEvictionReportConfirmed && isEvictionRequested) {
          setEvictionReportToExpand(true)
          setCurrentReportStep(EVICTION)
        }
        break
      case EVICTION:
        setEvictionReportToExpand(false)
        setEvictionReportToConfirm(true)
        if (!isCreditReportConfirmed) {
          setCreditReportToExpand(true)
          setCurrentReportStep(CREDIT_REPORT)
        } else if (!isCriminalReportConfirmed && isCriminalRequested) {
          setCriminalReportToExpand(true)
          setCurrentReportStep(CRIMINAL)
        }
        break
      case SUBMIT:
        handleSubmit()
        break
      default:
        break
    }
  }

  const handleBackButton = section => {
    switch (section) {
      case CRIMINAL:
        setCriminalReportToConfirm(false)
        setCriminalReportToExpand(false)
        setCreditReportToExpand(true)
        setCreditReportToConfirm(false)
        setCurrentReportStep(CREDIT_REPORT)
        break
      case EVICTION:
        setEvictionReportToConfirm(false)
        setEvictionReportToExpand(false)
        if (isCriminalRequested) {
          setCriminalReportToExpand(true)
          setCriminalReportToConfirm(false)
          setCurrentReportStep(CRIMINAL)
        } else {
          setCreditReportToExpand(true)
          setCreditReportToConfirm(false)
          setCurrentReportStep(CREDIT_REPORT)
        }
        break
      case SUBMIT:
        if (isEvictionRequested) {
          setEvictionReportToConfirm(false)
          setEvictionReportToExpand(true)
          setCurrentReportStep(EVICTION)
        } else if (isCriminalRequested) {
          setCriminalReportToExpand(true)
          setCriminalReportToConfirm(false)
          setCurrentReportStep(CRIMINAL)
        } else {
          setCreditReportToExpand(true)
          setCreditReportToConfirm(false)
          setCurrentReportStep(CREDIT_REPORT)
        }
        break
      default:
        break
    }
  }

  const handleBackToDashboard = () => {
    window.location.href = DASHBOARD_OVERVIEW
  }

  const getContinueButtonText = (stepReport, shouldNext) => {
    if (stepReport === SUBMIT) {
      if (shouldNext) {
        return 'Next'
      }

      return 'Submit'
    }
    return 'Confirm'
  }
  return (
    <>
      <TopNavbar
        configApi={{
          baseURL: USER_API_URL,
          domain: DOMAIN_NAME,
          apiURL: API_URL,
        }}
        partnerLogoSrc={subdomain === PARTNER_NAME.RENTALS && getPartnerLogoDashboardSrc(subdomain)}
        profile={profile}
        isShowRenterSupport
        domain={DOMAIN_NAME}
        authBaseURL={USER_API_URL}
        tracker={tracker}
      />
      <ReportReviewingWrapper>
        <TitleWrapper>
          <TitleWithSubtitle
            title={
              isMobile
                ? REPORT_REVIEWING_HEADER_TEXT.TITLE_MOBILE
                : REPORT_REVIEWING_HEADER_TEXT.TITLE
            }
            subtitle={REPORT_REVIEWING_HEADER_TEXT.SUBTITLE}
            mb="8"
          />
        </TitleWrapper>
        <ConfirmationAccordion
          dataTestKey="review-credit"
          title={`${reportOrder.credit || REPORT_ACCORDION_HEADER_TEXT.CREDIT.ORDER}. ${
            REPORT_ACCORDION_HEADER_TEXT.CREDIT.TITLE
          }`}
          subtitle={
            isMobile
              ? REPORT_ACCORDION_HEADER_TEXT.CREDIT.SUB_TITLE_MOBILE
              : REPORT_ACCORDION_HEADER_TEXT.CREDIT.SUB_TITLE
          }
          confirmedText={REPORT_ACCORDION_HEADER_TEXT.CREDIT.CONFIRM_TEXT}
          confirmBtnText={REPORT_ACCORDION_HEADER_TEXT.CREDIT.CONFIRM_BTN_TEXT}
          isExpanded={isCreditReportExpanded}
          isConfirmed={isCreditReportConfirmed}
          isDisabledConfirmBtn={errorCreditReport}
          onExpand={() => {
            setExpand(CREDIT_REPORT)
          }}
          onConfirm={() => {
            setConfirm(CREDIT_REPORT)
          }}
          isMobile={isMobile}
        >
          <ShareableCreditReport showHeaderComponent={false} customMargin="0" />
        </ConfirmationAccordion>
        {isCriminalRequested && (
          <ConfirmationAccordion
            dataTestKey="review-criminal"
            title={`${reportOrder.criminal || REPORT_ACCORDION_HEADER_TEXT.CRIMINAL.ORDER}. ${
              REPORT_ACCORDION_HEADER_TEXT.CRIMINAL.TITLE
            }`}
            subtitle={
              isMobile
                ? REPORT_ACCORDION_HEADER_TEXT.CRIMINAL.SUB_TITLE_MOBILE
                : REPORT_ACCORDION_HEADER_TEXT.CRIMINAL.SUB_TITLE
            }
            confirmedText={REPORT_ACCORDION_HEADER_TEXT.CRIMINAL.CONFIRM_TEXT}
            confirmBtnText={REPORT_ACCORDION_HEADER_TEXT.CRIMINAL.CONFIRM_BTN_TEXT}
            isExpanded={isCriminalReportExpanded}
            isConfirmed={isCriminalReportConfirmed}
            isDisabledConfirmBtn={errorCreditReport}
            onExpand={() => {
              setExpand(CRIMINAL)
            }}
            onConfirm={() => {
              setConfirm(CRIMINAL)
            }}
            isMobile={isMobile}
          >
            <ShareableCriminalReport showHeaderComponent={false} customMargin="0" />
          </ConfirmationAccordion>
        )}
        {isEvictionRequested && (
          <ConfirmationAccordion
            dataTestKey="review-eviction"
            title={`${reportOrder.eviction || REPORT_ACCORDION_HEADER_TEXT.EVICTION.ORDER}. ${
              REPORT_ACCORDION_HEADER_TEXT.EVICTION.TITLE
            }`}
            subtitle={
              isMobile
                ? REPORT_ACCORDION_HEADER_TEXT.EVICTION.SUB_TITLE_MOBILE
                : REPORT_ACCORDION_HEADER_TEXT.EVICTION.SUB_TITLE
            }
            confirmedText={REPORT_ACCORDION_HEADER_TEXT.EVICTION.CONFIRM_TEXT}
            confirmBtnText={REPORT_ACCORDION_HEADER_TEXT.EVICTION.CONFIRM_BTN_TEXT}
            isExpanded={isEvictionReportExpanded}
            isConfirmed={isEvictionReportConfirmed}
            isDisabledConfirmBtn={errorCreditReport}
            onExpand={() => {
              setExpand(EVICTION)
            }}
            onConfirm={() => {
              setConfirm(EVICTION)
            }}
            isMobile={isMobile}
          >
            <ShareableEvictionReport showHeaderComponent={false} customMargin="0" />
          </ConfirmationAccordion>
        )}
        <CheckboxWrapper data-testid="review-report-checkbox-wrapper">
          <Checkbox
            dataTestId="review-report-accept-term-checkbox"
            id="accept-term-checkbox"
            onClick={() => toggleAcceptTerm()}
            isSelected={isAcceptTerm}
            simple
            error={isShowError}
          />
          <TermAndConditionLabel
            dataTestKey="review-report"
            infoText={
              shouldNextToDocumentUpload
                ? REPORT_REVIEWING_TERMS.INFO_TEXT_NEXT
                : REPORT_REVIEWING_TERMS.INFO_TEXT_SUBMIT
            }
            link={REPORT_REVIEWING_TERMS.LINK}
            termsText={REPORT_REVIEWING_TERMS.TERMS_TEXT}
            errorText={REPORT_REVIEWING_TERMS.ERROR_TEXT}
            isError={isShowError}
          />
        </CheckboxWrapper>
        {!isMobile && (
          <SubmitButtonWrapper data-testid="review-report-submit-btn-wrapper">
            <BlueGradientButton
              data-testid="review-report-submit-btn"
              hasIcon={shouldNextToDocumentUpload}
              small
              width="100.81px"
              id="report-reviewing-submit-btn-id"
              onClick={() => handleSubmit()}
              loading={isSubmitting}
              loaded={isSubmitted}
              disabled={isDisabledSubmitButton}
            >
              <S16 color={COLOR.white} style={{ margin: 0 }}>
                {shouldNextToDocumentUpload ? 'Next' : 'Submit'}
              </S16>
            </BlueGradientButton>
          </SubmitButtonWrapper>
        )}
      </ReportReviewingWrapper>
      {isMobile && (
        <StickyFooterWrapper
          data-testid="mobile-review-report-footer-wrapper"
          shouldPositionFixed={currentReportStep === SUBMIT}
        >
          <ButtonSetV2
            dataTestKey="mobile-review-report"
            onClickPrevBtn={() => handleBackButton(currentReportStep)}
            onSaveAndNext={() => setConfirm(currentReportStep)}
            withSystemLayout
            hidePrevButton={currentReportStep === CREDIT_REPORT}
            prevText="Back"
            continueText={getContinueButtonText(currentReportStep, shouldNextToDocumentUpload)}
            isLoading={isSubmitting}
            isSuccess={isSubmitted}
            onClickBackToDashboardBtn={() => handleBackToDashboard()}
          />
        </StickyFooterWrapper>
      )}
    </>
  )
}

export default compose(withOpen, withReducer, withConnect, withSaga)(ReportReviewing)
