import { useExperiment, useGate } from 'statsig-react'

export const STATSIG = 'statsig'

export const useStatsigExperiment = (featureFlagKey, statsigFeatureParam) => {
  const { config: experiment, isLoading } = useExperiment(featureFlagKey)
  const isEnabled = experiment.get(statsigFeatureParam)
  return { isEnabled, isLoading }
}

export const useStatsigFeatureGate = featureFlagKey => {
  const { value, isLoading } = useGate(featureFlagKey)
  return { isEnabled: value, isLoading }
}

export const useFeatureFlag = ({
  launchDarklyFeatureFlagKey,
  statsigFeatureFlagKey,
  statsigFeatureParam,
  featureFlagProvider,
}) => {
  switch (featureFlagProvider) {
    case STATSIG:
      return statsigFeatureParam
        ? useStatsigExperiment(statsigFeatureFlagKey, statsigFeatureParam)
        : useStatsigFeatureGate(statsigFeatureFlagKey)
    default:
      console.error(
        `Unsupported provider for ${launchDarklyFeatureFlagKey} or ${statsigFeatureFlagKey} feature flag ${featureFlagProvider}`,
      )
      return false
  }
}
