import React from 'react'
import { useSelector } from 'react-redux'
import { StatsigProvider } from 'statsig-react'
import isEmpty from 'lodash/isEmpty'

import { STATSIG_API, STATSIG_ENVIRONMENT, STATSIG_SDK_KEY } from 'env'
import { selectProfile } from 'containers/user/selectors'
import { parseProfileToStatsigUser } from 'helpers/statsig-user-profile-parser'
import { CenterLoading } from 'components/organisms/center-loading'
import { SUBSCRIPTION_PLAN } from 'constants/subscription'
import { querySubscriptionData } from 'utils/subscription/query-subscription-data'
import { queryUserPreferenceData } from 'utils/query-user-preference-data'
import { selectSubdomain } from 'containers/wrapper/selectors'
import { CenterContent, Loading } from 'components/layout/main'
import { useStatsigFeatureGate } from 'hooks/use-statsig-feature-flag'
import { useGetCleanUserMeta } from 'v3/hooks/use-get-clean-user-metadata'

export const withStatsigProvider = Component => props => {
  const profile = useSelector(selectProfile)
  const subdomain = useSelector(selectSubdomain) || 'rentspree'
  const userSubscriptionData = querySubscriptionData()
  const userPreferenceData = queryUserPreferenceData()
  const isProUser = userSubscriptionData.data?.userPlan === SUBSCRIPTION_PLAN.PRO
  const isInDesignConceptExperiment = !!(
    isEmpty(userPreferenceData?.data?.defaultScreeningOption) ||
    userPreferenceData?.data?.isInDesignConceptExperiment
  )
  const { data: cleanUserMeta, isFetched: isCleanUserMetaFetched } = useGetCleanUserMeta()

  if (!userSubscriptionData.isFetched || !isCleanUserMetaFetched) {
    return (
      <CenterContent>
        <Loading />
      </CenterContent>
    )
  }

  return (
    <StatsigProvider
      sdkKey={STATSIG_SDK_KEY}
      waitForInitialization
      initializingComponent={<CenterLoading />}
      user={parseProfileToStatsigUser(
        {
          ...profile,
          is_pro: isProUser,
          isInDesignConceptExperiment,
          isInvited: cleanUserMeta?.isInvitedUser,
          hasPropertyOrScreening: cleanUserMeta?.hasPropertyOrScreening
        },
        subdomain,
      )}
      options={{
        api: STATSIG_API,
        environment: {
          tier: STATSIG_ENVIRONMENT,
        },
      }}
    >
      <Component {...props} />
    </StatsigProvider>
  )
}

export const withStatsigFeatureGate =
  (featureGateName, isShowLoading = true) =>
    WrappedComponent =>
      props => {
        const { isEnabled, isLoading } = useStatsigFeatureGate(featureGateName)
        if (isLoading && isShowLoading) {
          return (
            <CenterContent>
              <Loading />
            </CenterContent>
          )
        }

        return (
          <WrappedComponent
            {...props}
            isFeatureEnabled={{ ...props.isFeatureEnabled, [featureGateName]: isEnabled }}
            isFeatureFlagLoading={{ ...props.isFeatureEnabled, [featureGateName]: isLoading }}
          />
        )
      }
