import PropTypes from 'prop-types'
import React from 'react'

import FormattedInput from '@rentspree/component-v2/dist/input/formatted'
import TextInput from '@rentspree/component-v2/dist/input/text'
import B14 from '@rentspree/component-v2/dist/typography/b14'

import { Div } from 'components/layout/main'
import ScrollToError from 'components/scroll/scroll-to-error'
import { InputWrapper } from 'components/shared/form'
import { FlexBox } from 'legacy/components/layout/section'
import { ADD_PROPERTY_HEADLINE } from 'constants/property'

const LandlordInformationForm = ({ margin, mMargin, ...props }) => {
  const { values, touched, errors, setFieldValue, handleChange, handleBlur } = props

  const handleOnBlur = (event, fieldName) => {
    // eslint-disable-next-line no-param-reassign
    event.target.value = event.target.value.trim()
    setFieldValue(fieldName, event.target.value)
    handleBlur(event)
  }

  return (
    <Div margin={margin} mMargin={mMargin}>
      <B14 mb="15">{ADD_PROPERTY_HEADLINE}</B14>
      <ScrollToError {...props} />
      <FlexBox>
        <InputWrapper width="146px" bgTransparent>
          <TextInput
            name="firstName"
            id="landlordInformationFirstName"
            label="First Name"
            value={values?.firstName}
            onChange={handleChange}
            onBlur={event => {
              handleOnBlur(event, "firstName")
            }}
            error={touched?.firstName && errors?.firstName}
          />
        </InputWrapper>
        <InputWrapper width="146px" ml="20" bgTransparent>
          <TextInput
            name="middleName"
            id="landlordInformationMiddleName"
            label="Middle Name"
            value={values?.middleName}
            onChange={handleChange}
            onBlur={event => {
              handleOnBlur(event, "middleName")
            }}
            error={touched?.middleName && errors?.middleName}
          />
        </InputWrapper>
        <InputWrapper width="146px" ml="20" bgTransparent>
          <TextInput
            name="lastName"
            id="landlordInformationLastName"
            label="Last Name"
            value={values?.lastName}
            onChange={handleChange}
            onBlur={event => {
              handleOnBlur(event, "lastName")
            }}
            error={touched?.lastName && errors?.lastName}
          />
        </InputWrapper>
        <InputWrapper width="476px" bgTransparent>
          <TextInput
            id="landlordInformationEmail"
            name="email"
            label="Email"
            value={values?.email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched?.email && errors?.email}
          />
        </InputWrapper>
        <InputWrapper width="476px" bgTransparent>
          <FormattedInput
            formatType="phone"
            id="landlordInformationMobileNumber"
            name="mobileNumber"
            label="Mobile"
            value={values?.mobileNumber}
            onValueChange={e => setFieldValue('mobileNumber', e.value)}
            onBlur={handleBlur}
            error={touched?.mobileNumber && errors?.mobileNumber}
          />
        </InputWrapper>
      </FlexBox>
    </Div>
  )
}

LandlordInformationForm.defaultProps = {
  margin: '0 10px',
  mMargin: '0',
}

LandlordInformationForm.propTypes = {
  margin: PropTypes.string,
  mMargin: PropTypes.string,
}

export default LandlordInformationForm
