import React from 'react'

import { BlueGradientButton, S16 } from '@rentspree/component-v2'
import incomeEstimateWarning from 'images/income-estimate/income-estimate-warning.png'
import { TULogo } from 'legacy/components/reports-v2/share/doc-style'
import transImg from 'legacy/images/credit-report/TU-logo.png'
import { S14 } from 'components/typography'
import {
  IncomeEstimateReportHeaderWrapper,
  IncomeEstimateWarningWrapper,
  IncomeEstimateWarningImgWrapper,
  StyledGradientButtonWrapper,
  StyledS24,
  DesktopSubtitleWrapper,
  MobileSubtitleWrapper,
  StyledS16,
} from './styles'
import { warningText } from './const'

const IncomeEstimateWarningComponent = ({ onClickAccept }) => (
  <IncomeEstimateWarningWrapper>
    <IncomeEstimateReportHeaderWrapper margin="36px 24px 70px 24px">
      <TULogo src={transImg} alt="tu-logo" />
      <S14 margin="0">{warningText.headerLogo}</S14>
    </IncomeEstimateReportHeaderWrapper>
    <IncomeEstimateWarningImgWrapper>
      <img src={incomeEstimateWarning} alt="income-estimate-warning" />
    </IncomeEstimateWarningImgWrapper>
    <StyledS24>{warningText.title}</StyledS24>
    <DesktopSubtitleWrapper>
      <S16 weight={400}>{warningText.subtitle1}</S16>
      <StyledS16 weight={400} lineHeight="22px">
        {warningText.subtitle2} <b>{warningText.subtitle2BoldText}</b>
      </StyledS16>
    </DesktopSubtitleWrapper>
    <MobileSubtitleWrapper>
      <StyledS16 weight={400} lineHeight="22px">
        {warningText.subtitle1} {warningText.subtitle2} <b>{warningText.subtitle2BoldText}</b>
      </StyledS16>
    </MobileSubtitleWrapper>
    <StyledGradientButtonWrapper>
      <BlueGradientButton text={warningText.acceptButton} onClick={onClickAccept} />
    </StyledGradientButtonWrapper>
  </IncomeEstimateWarningWrapper>
)

export default IncomeEstimateWarningComponent
