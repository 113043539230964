import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useGate } from 'statsig-react'

import { useGetCountRentalSubmission } from 'components/tenant-screening-widget/use-get-count-rental-submission'
import { EXPLORE_PAGE, PROPERTY, RENT_PAYMENT, TENANT_SCREENING } from 'constants/route'
import { AGENT_INCENTIVE_PROGRAM_PAYMENT_ADOPTION } from 'utils/feature-flag/constants'
import { SEEN_ANNOUNCEMENT_TYPE } from 'v3/containers/page-dashboard/const'
import { useGetMyIncentiveProgramEligibility } from 'v3/hooks/use-get-my-incentive-eligibility'
import { useMediaQuery } from 'v3/hooks/use-media-query'
import { useMutateMySeenAnnouncement } from 'v3/hooks/use-mutate-seen-announcement'
import { useQuerySeenAnnouncements } from 'v3/hooks/use-query-seen-announcement'
import { PATH_HOME_PAGE } from 'v3/routers/path'

import { AGENT_INCENTIVE_PROGRAM_PAYMENT_ADOPTION_SEEN_GROUP } from '../banners/constants'

import { openInformationPage } from './redux/actions'
import { makeSelectIsIncentiveInformationOpen } from './redux/selectors'

const automaticallyOpenInformationPageWhitelistPage = [
  EXPLORE_PAGE,
  PROPERTY,
  RENT_PAYMENT,
  TENANT_SCREENING,
  PATH_HOME_PAGE,
]

export const useAutoOpenAgentIncentiveInformationPage = ({ pathname }) => {
  const isMobile = useMediaQuery('(max-width: 1016px)')
  const isOpen = useSelector(makeSelectIsIncentiveInformationOpen())
  const { data: seenAnnouncements, isLoading: isSeenAnnouncementLoading } =
    useQuerySeenAnnouncements()
  const { data: counts, isLoading: isGetCountRentalSubmissionLoading } =
    useGetCountRentalSubmission({
      enabled: isMobile && !isOpen,
    })
  const mutateSeenAnnouncement = useMutateMySeenAnnouncement({
    announcementType: SEEN_ANNOUNCEMENT_TYPE.NOTICES,
    groups: [AGENT_INCENTIVE_PROGRAM_PAYMENT_ADOPTION_SEEN_GROUP],
  })
  const { data: isAgentEligible } = useGetMyIncentiveProgramEligibility()
  const { value: isAgentIncentiveProgramAdoptionEnabled } = useGate(
    AGENT_INCENTIVE_PROGRAM_PAYMENT_ADOPTION,
  )

  const dispatch = useDispatch()

  useEffect(() => {
    const isPathnameInWhitelisting =
      automaticallyOpenInformationPageWhitelistPage.includes(pathname)
    if (!isPathnameInWhitelisting) {
      return
    }

    const shouldOpenAutomatically =
      isAgentIncentiveProgramAdoptionEnabled &&
      isMobile &&
      isAgentEligible?.isEligiblePartner &&
      !isOpen &&
      !seenAnnouncements?.lazyCacheSeenGroups?.[
        AGENT_INCENTIVE_PROGRAM_PAYMENT_ADOPTION_SEEN_GROUP
      ] &&
      !seenAnnouncements?.['seen_groups']?.[AGENT_INCENTIVE_PROGRAM_PAYMENT_ADOPTION_SEEN_GROUP] &&
      counts?.total !== counts?.unread
    if (!shouldOpenAutomatically) {
      return
    }

    dispatch(
      openInformationPage({
        fromPathname: pathname,
        isAutoOpened: true,
        isOpenedFromNavBarClick: false,
      }),
    )
    mutateSeenAnnouncement.mutate()
  }, [
    isSeenAnnouncementLoading,
    isGetCountRentalSubmissionLoading,
    pathname,
    counts?.total,
    counts?.unread,
    isAgentEligible?.isEligiblePartner,
    isAgentIncentiveProgramAdoptionEnabled,
  ])
}
