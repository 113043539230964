/* eslint-disable no-console */
import { debounce, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material'
import { TextInput } from '@rentspree/component-2023.components.atoms.text-input'
import dayjs from 'dayjs'
import timezonePlugin from 'dayjs/plugin/timezone'
import utcPlugin from 'dayjs/plugin/utc'
import React, { useCallback, useEffect, useState } from 'react'
import { isDecimal } from 'validator'

import { DatePickerInput } from 'v3/components/date-picker-input/index'
import { TitleText } from 'v3/containers/overhaul-rent-payment/components/text/title-text'
import { ABOUT_PAYMENT } from 'v3/containers/overhaul-rent-payment/text-constants'
// import { generateQuotationByType } from 'v3/containers/rent-payment/setup-page/set-up-for-myself/steps/payment-details/utils'

dayjs.extend(timezonePlugin)
dayjs.extend(utcPlugin)

// out of render method to enable future consolidation refactor; we don't have a "styles constants" file atm
const inputStyles = { fontSize: '1.5rem', marginBottom: '15px' }
export const TellUsAboutPayment = ({ payment, setPayment }) => {
  const [errors, setErrors] = useState({})
  // might be a better way to set an on-render defaultValue
  const [localAmount, setLocalAmount] = useState(parseFloat(payment?.amount || 0).toFixed(2))

  const setPaymentComponent = useCallback(
    debounce(nextFields => {
      setPayment({
        ...payment,
        ...nextFields,
      })
    }, 400),
  )

  useEffect(() => {
    // this is a cross-field dependent value, so we'll watch them for changes ot update vs baking that into each field's setting logic in both places
    let term = null
    if (payment !== undefined && payment?.type === 'recurring') {
      term = payment?.endDate === undefined ? 'perpetual' : 'fixed'
    }
    // console.log(`term: ${term}`)
    setPaymentComponent({ term })
  }, [payment?.type, payment?.endDate])

  const endDateDisabled = ['oneTime', undefined].includes(payment?.type)

  useEffect(() => {
    // unify null & undefined states via default value
    const { startDate = null, endDate = null } = payment
    let startDateError
    let endDateError

    if (startDate !== null && dayjs(startDate).isBefore(dayjs(), 'day')) {
      startDateError = 'Please choose a starting date today or later'
    }
    if (!endDateDisabled && endDate !== null) {
      if (dayjs(endDate).isBefore(dayjs(), 'day')) {
        endDateError = 'Please choose an end date later than today'
      } else if (startDate !== null && dayjs(endDate).isBefore(dayjs(startDate), 'day')) {
        endDateError = 'Please choose an end date later than the start date'
      }
    }

    setErrors({ startDate: startDateError, endDate: endDateError })
  }, [payment?.type, payment?.endDate, payment?.startDate])

  console.log(`Start Date: ${payment?.startDate}`)
  console.log(`End Date: ${payment?.endDate}`)
  return (
    <>
      <TitleText sx={{ marginBottom: '30px' }}>{ABOUT_PAYMENT.TELL_US_ABOUT_RENT}</TitleText>

      <Typography sx={{ fontSize: '1.5rem', marginBottom: '12px' }} variant="p">
        {ABOUT_PAYMENT.HOW_OFTEN}
      </Typography>
      <RadioGroup
        value={payment?.type || ''}
        onChange={e => {
          let howOftenFields = { type: e.target.value, repeat: undefined }
          if (e.target.value === 'recurring') {
            howOftenFields = { ...howOftenFields, repeat: 'monthly' }
          }
          setPaymentComponent(howOftenFields)
        }}
        sx={{ marginLeft: '5px', marginBottom: '15px' }}
        required
      >
        <FormControlLabel
          value="recurring"
          control={<Radio sx={{ padding: '4px 9px' }} size="large" />}
          label={<Typography variant="h5">{ABOUT_PAYMENT.MONTHLY}</Typography>}
        />
        <FormControlLabel
          value="oneTime"
          control={<Radio sx={{ padding: '4px 9px' }} size="large" />}
          label={<Typography variant="h5">{ABOUT_PAYMENT.ONE_TIME}</Typography>}
        />
      </RadioGroup>

      <Typography sx={inputStyles} variant="p">
        {ABOUT_PAYMENT.HOW_MUCH}
      </Typography>
      {/* TODO: ui.rentspree.com TextInput isn't doing floating labels like mocks want */}
      <TextInput
        label="Amount"
        startAdornment="$"
        sx={inputStyles}
        error={errors?.amount !== undefined}
        helperText={errors?.amount}
        // inputProps={{ inputMode: 'decimal', pattern: '\\d{1,3}(,?\\d{3})*(\\.\\d{0,2})?', defaultValue: parseFloat(localAmount).toFixed(2) }}
        value={localAmount}
        onChange={e => setLocalAmount(e.target.value)}
        onBlur={e => {
          let errorMessage
          const val = e.target.value
          if (isDecimal(val)) {
            const nextVal = parseFloat(val).toFixed(2)
            console.log(e.target)
            console.log(e.target.value, nextVal)
            setPaymentComponent({ amount: nextVal })
            setLocalAmount(nextVal)
          } else {
            setPaymentComponent({ amount: undefined })
            errorMessage = ABOUT_PAYMENT.ERROR_AMOUNT
          }

          setErrors({ ...errors, amount: errorMessage })
        }}
        required
      />

      <Typography sx={inputStyles} variant="p">
        {ABOUT_PAYMENT.HOW_LONG}
      </Typography>
      <DatePickerInput
        label={ABOUT_PAYMENT.START_DATE_HELPER_TEXT}
        sx={inputStyles}
        name="startDate"
        value={payment?.startDate}
        minDate={dayjs()}
        error={Boolean(errors?.startDate)}
        helperText={typeof errors?.startDate === 'string' ? errors.startDate : undefined}
        onDateChange={value => {
          setPaymentComponent({ startDate: value })
        }}
        onBlur={e => {
          // the input field triggers separately from the DateInput's onDateChange handler
          setPaymentComponent({ startDate: e.target.value })
        }}
        required
      />
      <DatePickerInput
        sx={inputStyles}
        label={ABOUT_PAYMENT.END_DATE_HELPER_TEXT}
        name="endDate"
        minDate={payment?.startDate ? dayjs(payment?.startDate) : undefined}
        value={!endDateDisabled ? payment?.endDate : null}
        error={Boolean(errors?.endDate) && !endDateDisabled}
        helperText={
          errors?.endDate === undefined ? ABOUT_PAYMENT.HELPER_TEXT_END_DATE : errors.endDate
        }
        onDateChange={date => {
          setPaymentComponent({ endDate: date || null })
        }}
        onBlur={e => {
          // the input field triggers separately from the DateInput's onDateChange handler
          const date = e.target.value
          setPaymentComponent({ endDate: date || null })
        }}
        disabled={endDateDisabled}
      />

      <TextInput
        label="Note"
        helperText={ABOUT_PAYMENT.HELPER_TEXT_NOTE}
        onChange={e => setPaymentComponent({ description: e.target.value })}
        sx={inputStyles}
        multiline
      />
    </>
  )
}
