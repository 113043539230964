import { Grid, debounce, Typography } from '@mui/material'
import React, { useState, useContext, useMemo, useCallback } from 'react'

import { TitleWithSubtitle } from 'components/molecules/title-with-subtitle'
import {
  MONTHLY_PAYMENTS,
  ONE_TIME_PAYMENTS,
  PAYMENT_DETAILS,
  RECIPIENT_DETAILS,
  REVIEW,
  TENANT_AND_PROPERTY,
} from 'v3/containers/overhaul-rent-payment/pages/review/constants'
import {
  useGetOrCreateDraftRentalPayment,
  useUpdateDraftQuotations,
} from 'v3/containers/rent-payment/shared/hooks'

import { saveDefaultFeeCollection } from '../../../rent-payment/setup-page/set-up-for-myself/steps/payment-details/utils'
import { PaymentsDrawer } from '../../components/drawer/drawer'
import { DrawerFooter } from '../../components/footer/drawer-footer'
import { buttonLayoutChoices } from '../../components/footer/utils'
import { PaymentInfoContext, PageIndexesContext } from '../../context'
import { paymentsField } from '../payment-details/page'

import { EditPayment } from './components/edit-payment'
import { PaymentsCard } from './payments-card'
import { RecipientDetailsCard } from './recipient-details-card'
import { TenantAndPropertyCard } from './tenant-and-property-card'

/*
 * These constants needs to be imported from a shared file.
 * temporary placement to avoid circular dependencies
 */
export const recipientDetailsOptions = Object.freeze({
  ME: 'me',
})
const PROPERTY = 'property'
const TENANT = 'tenant'
const INVITE_BY_TEXT = 'inviteByText'
// Add these payment type constants
const PAYMENT_TYPES = Object.freeze({
  RECURRING: 'recurring',
  ONE_TIME: 'oneTime',
})
// Add these recipient info field constants
const RECIPIENT_INFO_FIELDS = Object.freeze({
  FIRST_NAME: 'first-name',
  LAST_NAME: 'last-name',
  RECIPIENT_INFO: 'recipient-info',
})
const pageOptions = Object.freeze({
  RECIPIENT_DETAILS: 'recipient-details',
  PROPERTY_AND_TENANT: 'property-and-tenant',
  PAYMENT_DETAILS: 'payment-details',
  REVIEW: 'review',
})

export const handleReviewProgression = async (
  { increment = false, decrement = false },
  curPageIndexes,
  setPageIndexes,
  pageField,
  curPaymentInfo,
) => {
  const propertyAndTenant = curPaymentInfo?.[pageOptions.PROPERTY_AND_TENANT] || {}
  const tenant = propertyAndTenant?.[TENANT] || {}
  const reviewDetails = curPaymentInfo?.[pageOptions.REVIEW] || {}
  const inviteByTextEnabled = reviewDetails[INVITE_BY_TEXT]

  if (increment && inviteByTextEnabled) {
    if (!tenant.phone) {
      /* eslint-disable no-console */
      console.log('Phone number is required')
      /* eslint-enable no-console */
      return false
    }

    const phoneDigits = tenant.phone.replace(/\D/g, '')
    if (phoneDigits.length < 10) {
      /* eslint-disable no-console */
      console.log('Invalid phone number format')
      /* eslint-enable no-console */
      return false
    }
  }

  const indexChanges = {
    pageL1Index: curPageIndexes.pageL1Index,
  }
  // shortcut invalid scenarios
  if (!(decrement || increment) || (increment && decrement)) {
    /*
     * logging an error, but this can still be consumer facing, so staying vague
     * need to find direct DD log avenue for debug details if possible
     */
    console.error('Unusual state for Review page progression request')
  } else {
    if (increment) {
      return true
    }

    if (decrement) {
      setPageIndexes(indexChanges)
      indexChanges.pageL1Index -= 1
    }
  }

  return false
}

// eslint-disable-next-line no-inline-comments
export const ReviewPage = () => {
  const [paymentInfo, setPaymentInfo] = useContext(PaymentInfoContext)
  const [pageIndexes, setPageIndexes] = useContext(PageIndexesContext)
  const [selectedPayment, setSelectedPayment] = useState()
  const paymentDetails = paymentInfo?.[pageOptions.PAYMENT_DETAILS]?.[paymentsField] || []
  const reviewDetails = paymentInfo?.[pageOptions.REVIEW] || {}
  const monthlyPayments = useMemo(
    () => paymentDetails.filter(payment => payment.type === PAYMENT_TYPES.RECURRING),
    [paymentDetails],
  )
  const onetimePayments = useMemo(
    () => paymentDetails.filter(payment => payment.type === PAYMENT_TYPES.ONE_TIME),
    [paymentDetails],
  )
  const hasRecipientDetails = paymentDetails.RECIPIENT_INFO === recipientDetailsOptions.ME
  const recipientInfo = paymentDetails[RECIPIENT_INFO_FIELDS.RECIPIENT_INFO] || {}
  const recipientName = `${recipientInfo[RECIPIENT_INFO_FIELDS.FIRST_NAME]} ${
    recipientInfo[RECIPIENT_INFO_FIELDS.LAST_NAME]
  }`
  const propertyAndTenant = paymentInfo?.[pageOptions.PROPERTY_AND_TENANT] || {}

  const property = propertyAndTenant?.[PROPERTY] || {}
  const tenant = propertyAndTenant?.[TENANT] || {}
  const { phone } = tenant
  const { street, city, state, zip } = property
  const propertyAddress = `${street}, ${city}, ${state} ${zip}`
  const inviteByTextCheckbox =
    INVITE_BY_TEXT in reviewDetails ? reviewDetails[INVITE_BY_TEXT] : Boolean(tenant.phone)
  const { data: draftRentalPayment } = useGetOrCreateDraftRentalPayment()

  const { updateDraftQuotations, data: quotations } = useUpdateDraftQuotations()

  const setDrawerOpen = useCallback(
    ({ isOpen, payment }) => {
      setPageIndexes({ drawerOpen: isOpen })
      setSelectedPayment(payment)
    },
    [setSelectedPayment, setPageIndexes],
  )

  const setInviteByText = useCallback(
    checked => {
      setPaymentInfo(prevState => ({
        ...prevState,
        [pageOptions.REVIEW]: {
          ...prevState[pageOptions.REVIEW],
          [INVITE_BY_TEXT]: checked,
        },
      }))
    },
    [setPaymentInfo],
  )

  const debouncedSetPaymentInfo = useMemo(
    () =>
      debounce(newPaymentInfo => {
        const updatedPayments = paymentDetails.map(p =>
          p.id === newPaymentInfo.id ? { ...p, ...newPaymentInfo } : p,
        )

        setPaymentInfo(prevState => ({
          ...prevState,
          [pageOptions.PAYMENT_DETAILS]: {
            ...prevState[pageOptions.PAYMENT_DETAILS],
            [paymentsField]: updatedPayments,
          },
        }))
      }, 200),
    [paymentDetails, setPaymentInfo],
  )

  const onDrawerSaveHandler = useCallback(
    async ({ increment = false, decrement = false }) => {
      try {
        if (decrement) {
          setDrawerOpen({ isOpen: false, payment: null })
          return false
        }

        if (!increment || !quotations) {
          /* eslint-disable no-console */
          console.log('Missing data')
          /* eslint-enable no-console */
          return false
        }

        debouncedSetPaymentInfo(selectedPayment)
        saveDefaultFeeCollection(selectedPayment.feeCollection)

        const mappedQuotations = quotations.map(quotation =>
          selectedPayment.id === quotation.id ? { ...quotation, ...selectedPayment } : quotation,
        )

        updateDraftQuotations({
          rentalPaymentId: draftRentalPayment.id,
          quotations: mappedQuotations,
        })

        setPageIndexes({ drawerOpen: false })
        return true
      } catch (error) {
        console.error('Error saving payment', error)
        return false
      }
    },
    [
      quotations,
      selectedPayment,
      draftRentalPayment?.id,
      updateDraftQuotations,
      setPageIndexes,
      debouncedSetPaymentInfo,
    ],
  )

  const setPhoneNumber = useCallback(
    newPhoneNumber => {
      setPaymentInfo(prevState => ({
        ...prevState,
        [pageOptions.PROPERTY_AND_TENANT]: {
          ...prevState[pageOptions.PROPERTY_AND_TENANT],
          [TENANT]: {
            ...prevState[pageOptions.PROPERTY_AND_TENANT][TENANT],
            phone: newPhoneNumber,
          },
        },
      }))
    },
    [setPaymentInfo],
  )

  return (
    <>
      <TitleWithSubtitle
        title={REVIEW.TITLE}
        subtitle={REVIEW.DESCRIPTION}
        mTitleMargin="0 0 0px"
        titleMargin="0 0 0px"
        titleSize="24px"
        subTitleSize="16px"
      />

      {hasRecipientDetails && (
        <>
          <Typography variant="title">{RECIPIENT_DETAILS}</Typography>
          <RecipientDetailsCard name={recipientName} email={recipientInfo.email} />
        </>
      )}

      <Typography variant="title">{TENANT_AND_PROPERTY}</Typography>
      <TenantAndPropertyCard
        name={`${tenant.firstName || ''} ${tenant.lastName || ''}`.trim()}
        address={propertyAddress}
        phoneNumber={phone}
        inviteByText={inviteByTextCheckbox}
        onSetInviteByText={setInviteByText}
        onSetPhoneNumber={setPhoneNumber}
      />
      <TitleWithSubtitle
        title={PAYMENT_DETAILS.title}
        subtitle={PAYMENT_DETAILS.description}
        mTitleMargin="0 0 0px"
        titleMargin="0 0 0px"
        titleSize="20px"
        subTitleSize="16px"
      />
      {monthlyPayments.length > 0 && (
        <Grid item xs={12}>
          <Typography variant="title">{MONTHLY_PAYMENTS}</Typography>
          {monthlyPayments.map(payment => (
            <PaymentsCard key={payment.id} payment={payment} setDrawerOpen={setDrawerOpen} />
          ))}
        </Grid>
      )}
      {onetimePayments.length > 0 && (
        <Grid item xs={12}>
          <Typography variant="title">{ONE_TIME_PAYMENTS}</Typography>
          {onetimePayments.map(payment => (
            <PaymentsCard key={payment.id} payment={payment} setDrawerOpen={setDrawerOpen} />
          ))}
        </Grid>
      )}
      <PaymentsDrawer drawerOpen={pageIndexes.drawerOpen}>
        <EditPayment
          payment={selectedPayment}
          setPayment={setSelectedPayment}
          pageIndexes={pageIndexes}
        />
        <DrawerFooter
          buttonLayout={buttonLayoutChoices.NEXT_BACK}
          textOverrides={{ back: 'Cancel', next: 'Save' }}
          onProgress={onDrawerSaveHandler}
        />
      </PaymentsDrawer>
    </>
  )
}
