import { Grid } from '@mui/material'
import StickyFooterTemplate from '@rentspree/component-2023.components.templates.sticky-footer-template'
import React, { useContext } from 'react'

import { PageIndexesContext, PageProgressionContext } from '../../context'

import { CreateButtons } from './utils'

export const DrawerFooter = ({ buttonLayout, textOverrides, onProgress }) => {
  /*
   * Leverages StickyFooterTemplate for the bottom sticky bar, and then a Grid/flexbox
   * layout for the various button configurations.
   */
  const [pageIndexes] = useContext(PageIndexesContext)
  const contextProgressHandler = useContext(PageProgressionContext)
  const progressionHandler = typeof onProgress === 'function' ? onProgress : contextProgressHandler

  return (
    <StickyFooterTemplate
      position="absolute"
      variant="navigation"
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        // StickyFooterTemplate overrides 'left: 88px' unless we force importance, unfortunately
        left: '0!important',
        width: '100%',
        bottom: 0,
        zIndex: 1300,
      }}
      className="PaymentDrawerFooter"
    >
      <Grid container direction="row" spacing={2}>
        {CreateButtons(pageIndexes, progressionHandler, buttonLayout, textOverrides)}
      </Grid>
    </StickyFooterTemplate>
  )
}
