import React, { useEffect } from 'react'

import { TULogo } from 'legacy/components/reports-v2/share/doc-style'
import transImg from 'legacy/images/credit-report/TU-logo.png'
import { B12, S14, S28 } from 'components/typography'
import parse from 'html-react-parser'
import moment from 'moment'
import { COLOR } from 'styles/settings'
import TextLink from 'components/atoms/text-link'
import { COMPANY_INFO } from 'legacy/constants/report-consts'
import { convertNumberFormat } from 'utils/convert'
import tracker from 'tracker'
import { INCOME_ESTIMATE_EVENT } from 'tracker/const'
import {
  IncomeEstimateReportApplicantDetailWrapper,
  IncomeEstimateReportTUCompany,
  IncomeEstimateReportDataWrapper,
  IncomeEstimateReportDateWrapper,
  IncomeEstimateReportDescription,
  IncomeEstimateReportFooterWrapper,
  IncomeEstimateReportHeaderWrapper,
  IncomeEstimateReportRentSpreeCompany,
  IncomeEstimateReportWrapper,
  StyledS16,
  StyledIncomeEstimateReportData,
} from './styles'
import { INCOME_ESTIMATE_REPORT_DESCRIPTION } from './const'
import IncomeEstimateReportFeedback from './feedback'

const IncomeEstimateReportComponent = ({ getIncomeEstimateReport, reportInformation, location, renter, onOtherDocsLinkClick }) => {
  useEffect(() => {
    if (!reportInformation.report) {
      getIncomeEstimateReport(reportInformation.rentalSubmissionId)
    }
  }, [reportInformation.report])

  const { applicant, generatedAt, report } = reportInformation
  const reportData = report?.reportResponseModelDetails[0]?.reportData || ''
  const cssLinkPattern = /<link[^>]*>/gi
  const trimReportData = reportData.replace(cssLinkPattern, '')
  const reportsExpireNumberOfDays = 30
  const otherDocsPath = location.pathname.replace('income-estimate', 'documents')
  const transUnionCompanyDetail = COMPANY_INFO[0]
  const rentSpreeCompanyDetail = COMPANY_INFO[1]
  const annualIncome = applicant?.income * 12
  const selfReportedIncome = annualIncome ? convertNumberFormat(annualIncome) : 0 

  const onTextLinkClick = () => {
    onOtherDocsLinkClick()

    tracker.trackEvent(INCOME_ESTIMATE_EVENT.EVENT_NAME.CLICK_ASK_MORE_DOCUMENTS, {
      rental_id: reportInformation.rentalSubmissionId,
      applicant: renter.email,
      click_from: INCOME_ESTIMATE_EVENT.CLICK_FROM.INCOME_ESTIMATE,
    })
  }

  const trackFeedbackCollection = (option) => {
    let clickOn = INCOME_ESTIMATE_EVENT.FEEDBACK_COLLECTION.CLICK_ON.NOT_ENOUGH_CREDIT_DATA

    if (trimReportData.includes('icons-thumbs-up')) {
      clickOn = INCOME_ESTIMATE_EVENT.FEEDBACK_COLLECTION.CLICK_ON.CLOSE_TO_OR_HIGHER
    }

    if (trimReportData.includes('icons-mitigate-risk')) {
      clickOn = INCOME_ESTIMATE_EVENT.FEEDBACK_COLLECTION.CLICK_ON.OR_MORE_LOWER
    }

    tracker.trackEvent(INCOME_ESTIMATE_EVENT.EVENT_NAME.CLICK_THUMB_UP_THUMB_DOWN, {
      rental_id: reportInformation.rentalSubmissionId,
      applicant: renter.email,
      click_from: INCOME_ESTIMATE_EVENT.CLICK_FROM.INCOME_ESTIMATE,
      click_on: clickOn,
      option
    })
  }

  const trackFeedbackThumbUp =  () => {
    trackFeedbackCollection(INCOME_ESTIMATE_EVENT.FEEDBACK_COLLECTION.OPTION.THUMB_UP)
  }

  const trackFeedbackThumbDown =  () => {
    trackFeedbackCollection(INCOME_ESTIMATE_EVENT.FEEDBACK_COLLECTION.OPTION.THUMB_DOWN)
  }

  return (
    <IncomeEstimateReportWrapper>
      <IncomeEstimateReportHeaderWrapper margin="36px 24px 50px 24px">
        <TULogo src={transImg} alt="tu-logo" />
        <IncomeEstimateReportDateWrapper>
          <S14 margin="0px 0px 5px 0px">Income Estimate</S14>
          <B12 margin="0px">Generated on {moment(generatedAt).format('MMM D, YYYY')}</B12>
          <B12 margin="0px">
            Expires on{' '}
            {moment(generatedAt).add(reportsExpireNumberOfDays, 'days').format('MMM D, YYYY')}
          </B12>
        </IncomeEstimateReportDateWrapper>
      </IncomeEstimateReportHeaderWrapper>
      <IncomeEstimateReportApplicantDetailWrapper>
        <S28 margin="0px 0px 5px 0px">
          {applicant?.firstName} {applicant?.lastName}
        </S28>
        <StyledS16 weight={400} lineHeight="22px">
          *Self reported income: <b>{`$${selfReportedIncome}`}</b> per year
        </StyledS16>
      </IncomeEstimateReportApplicantDetailWrapper>
      <IncomeEstimateReportDescription color={COLOR.grayscale50}>
        {INCOME_ESTIMATE_REPORT_DESCRIPTION}
      </IncomeEstimateReportDescription>
      <IncomeEstimateReportDataWrapper>
        <StyledIncomeEstimateReportData className="income-estimate-report">
          {parse(trimReportData)}
        </StyledIncomeEstimateReportData>
        <StyledS16
          weight={400}
          lineHeight="22px"
          margin="20px 0px 0px 0px"
          padding="10px 0px 0px 0px"
          borderTop={`solid ${COLOR.grayLv4} 1px`}
        >
          Looking for proof of income? Try asking for documents{' '}
          <TextLink
            to={otherDocsPath}
            onClick={onTextLinkClick}
            fontWeight={600}
            color={COLOR.activeBlue}
            hovercolor={COLOR.linkHoverBlue}
            focuscolor={COLOR.linkHoverBlue}
          >
            here
          </TextLink>
        </StyledS16>
      </IncomeEstimateReportDataWrapper>
      <IncomeEstimateReportFeedback onClickThumbUp={trackFeedbackThumbUp} onClickThumbDown={trackFeedbackThumbDown}/>
      <IncomeEstimateReportFooterWrapper>
        <IncomeEstimateReportTUCompany>
          <S14 margin="0px 0px 5px 0px">{transUnionCompanyDetail.title}</S14>
          <B12 margin="0px">{transUnionCompanyDetail.phone}</B12>
          <B12 margin="0px">
            6430 South Fiddlers Green Circle,
            <br /> Greenwood Village, CO 80111
          </B12>
        </IncomeEstimateReportTUCompany>
        <IncomeEstimateReportRentSpreeCompany>
          <S14 margin="0px 0px 5px 0px">{rentSpreeCompanyDetail.title}</S14>
          <B12 margin="0px">{rentSpreeCompanyDetail.phone}</B12>
          <B12 margin="0px">
            15303 Ventura Blvd
            <br /> Suite 1150, Sherman Oaks, CA 91403
          </B12>
        </IncomeEstimateReportRentSpreeCompany>
      </IncomeEstimateReportFooterWrapper>
    </IncomeEstimateReportWrapper>
  )
}

export default IncomeEstimateReportComponent
