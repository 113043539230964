import React, { useEffect } from 'react'
import dayjs from 'dayjs'
import { convert } from 'helpers/convert'
import capitalize from 'lodash/capitalize'
import tracker from 'tracker'
import { useGate } from 'statsig-react'
import { INCOME_VERIFICATION_EVENT } from 'tracker/const'
import HeaderPrint from 'containers/reports/header'
import MasterCardLogoImg from 'images/logos/mastercard-logo.svg'
import { generateAddress } from '@rentspree/helper/build/generate-address'
import { INCOME_VERIFICATION_SHARE_REPORT } from 'utils/feature-flag/constants'
import { ReportHeader, ReportsBody } from '../share/responsive-doc-components'
import {
  IncomeVerificationWrapper,
  ReportTitle,
  SectionTitle,
  TableRow,
  ReportTable,
  TableData,
  TableHeaderRow,
  SectionTitleNoBg,
  ReportLogo,
  ReportFooterStyle,
  ReportProviderMessageStyle,
  ReportWrapper,
  TableWrapper,
} from './styles'
const DATE_FORMAT = 'DD MMM YYYY'
const formatMonetaryAmount = num => convert(num, '$', '0,0.00')

export const ApplicantDetail = ({ firstName, lastName, address, dateRange }) => (
  <>
    <SectionTitle>Verification of income</SectionTitle>
    <TableWrapper>
      <ReportTable>
        <TableRow borderBottom>
          <TableData>Applicant name</TableData>
          <TableData textAlign="right">{`${firstName} ${lastName}`}</TableData>
        </TableRow>
        {dateRange ? (
          <>
            <TableRow borderBottom>
              <TableData>Address</TableData>
              <TableData textAlign="right">{address}</TableData>
            </TableRow>
            <TableRow sectionEnd>
              <TableData>Date range</TableData>
              <TableData textAlign="right">{dateRange}</TableData>
            </TableRow>
          </>
        ) : (
          <TableRow sectionEnd>
            <TableData>Address</TableData>
            <TableData textAlign="right">{address}</TableData>
          </TableRow>
        )}
      </ReportTable>
    </TableWrapper>
  </>
)

export const IncomeStreamSummary = ({ historicalIncomeNet, historicalIncomeGross }) => (
  <>
    <SectionTitle>Income stream summary</SectionTitle>
    <TableWrapper>
      <ReportTable>
        <TableHeaderRow borderBottom>
          <TableData>Historical annual income</TableData>
          <TableData></TableData>
        </TableHeaderRow>
        <TableRow borderBottom>
          <TableData>Historical annual income (net)</TableData>
          <TableData textAlign="right">{formatMonetaryAmount(historicalIncomeNet)}</TableData>
        </TableRow>
        <TableRow sectionEnd>
          <TableData>Historical annual income (gross)</TableData>
          <TableData textAlign="right">{formatMonetaryAmount(historicalIncomeGross)}</TableData>
        </TableRow>
      </ReportTable>
    </TableWrapper>
  </>
)

export const IncomeStreams = ({ incomeStreams }) => (
  <>
    <TableWrapper>
      <ReportTable>
        <TableHeaderRow>
          <TableData>Stream name</TableData>
          <TableData textAlign="right">12 mo average monthly amount</TableData>
          <TableData textAlign="right">Historical annual amount (net)</TableData>
        </TableHeaderRow>
        {incomeStreams.map((incomeStream, index) =>
          index !== incomeStreams.length - 1 ? (
            <TableRow borderBottom>
              <TableData>{capitalize(incomeStream.name)}</TableData>
              <TableData textAlign="right">
                {formatMonetaryAmount(incomeStream.averageMonthlyAmount)}
              </TableData>
              <TableData textAlign="right">
                {formatMonetaryAmount(incomeStream.historicalAnnualAmount)}
              </TableData>
            </TableRow>
          ) : (
            <TableRow sectionEnd>
              <TableData>{capitalize(incomeStream.name)}</TableData>
              <TableData textAlign="right">
                {formatMonetaryAmount(incomeStream.averageMonthlyAmount)}
              </TableData>
              <TableData textAlign="right">
                {formatMonetaryAmount(incomeStream.historicalAnnualAmount)}
              </TableData>
            </TableRow>
          ),
        )}
      </ReportTable>
    </TableWrapper>
  </>
)

export const SummaryByAccount = ({ summaryByAccounts }) => (
  <>
    <SectionTitle>Summary by account</SectionTitle>
    <TableWrapper>
      <ReportTable>
        <TableHeaderRow>
          <TableData>Institution</TableData>
          <TableData>Account</TableData>
          <TableData>Account owner</TableData>
          <TableData textAlign="right">Average monthly bal.</TableData>
          <TableData textAlign="right">Current balance</TableData>
        </TableHeaderRow>
        {summaryByAccounts.map((summaryByAccount, index) =>
          index !== summaryByAccounts.length - 1 ? (
            <TableRow borderBottom>
              <TableData>{summaryByAccount.institutionName}</TableData>
              <TableData>{summaryByAccount.accountName}</TableData>
              <TableData>{summaryByAccount.accountOwner}</TableData>
              <TableData textAlign="right">
                {formatMonetaryAmount(summaryByAccount.averageMonthlyBalance)}
              </TableData>
              <TableData textAlign="right">
                {formatMonetaryAmount(summaryByAccount.currentBalance)}
              </TableData>
            </TableRow>
          ) : (
            <TableRow sectionEnd>
              <TableData>{summaryByAccount.institutionName}</TableData>
              <TableData>{summaryByAccount.accountName}</TableData>
              <TableData>{summaryByAccount.accountOwner}</TableData>
              <TableData textAlign="right">
                {formatMonetaryAmount(summaryByAccount.averageMonthlyBalance)}
              </TableData>
              <TableData textAlign="right">
                {formatMonetaryAmount(summaryByAccount.currentBalance)}
              </TableData>
            </TableRow>
          ),
        )}
      </ReportTable>
    </TableWrapper>
  </>
)

export const DepositTransactions = ({ depositTransactions }) => (
  <>
    <SectionTitleNoBg>Deposit transactions</SectionTitleNoBg>
    {depositTransactions.map(transaction => (
      <>
        <SectionTitle>{`${capitalize(transaction.incomeStreamName)} - ${
          transaction.institutionName
        } ${transaction.accountName}`}</SectionTitle>
        <TableWrapper>
          <ReportTable>
            <TableHeaderRow>
              <TableData>Date</TableData>
              <TableData>Category</TableData>
              <TableData>Description</TableData>
              <TableData textAlign="right">Amount</TableData>
            </TableHeaderRow>
            {transaction.depositTransactionItems.map((item, index) =>
              index !== transaction.depositTransactionItems.length - 1 ? (
                <TableRow borderBottom>
                  <TableData>{dayjs(item.transactionDate).format(DATE_FORMAT)}</TableData>
                  <TableData>{item.category}</TableData>
                  <TableData>{item.description}</TableData>
                  <TableData textAlign="right">{formatMonetaryAmount(item.amount)}</TableData>
                </TableRow>
              ) : (
                <TableRow sectionEnd>
                  <TableData>{dayjs(item.transactionDate).format(DATE_FORMAT)}</TableData>
                  <TableData>{item.category}</TableData>
                  <TableData>{item.description}</TableData>
                  <TableData textAlign="right">{formatMonetaryAmount(item.amount)}</TableData>
                </TableRow>
              ),
            )}
          </ReportTable>
        </TableWrapper>
      </>
    ))}
  </>
)

export const ReportFooter = () => (
  <>
    <ReportFooterStyle>
      <ReportProviderMessageStyle>
        This report is provided by Finicity, a Mastercard Company
      </ReportProviderMessageStyle>
    </ReportFooterStyle>
  </>
)
export const IncomeVerificationReportComponent = ({ reportData, isFromAllTab }) => {
  const {
    generatedDate,
    expirationDate,
    startDate,
    endDate,
    firstName,
    lastName,
    address,
    incomeStreamSummary,
    incomeStreams,
    summaryByAccounts,
    depositTransactions,
    _id: reportId,
  } = reportData
  const reportApplicantDetail = {
    firstName,
    lastName,
    address: generateAddress(address),
  }

  const { value: isShareIncomeVerificationReportEnabled } = useGate(
    INCOME_VERIFICATION_SHARE_REPORT,
  )
  const { historicalIncomeNet, historicalIncomeGross } = incomeStreamSummary
  const formattedGeneratedDate = dayjs(generatedDate).format('MMM DD YYYY')
  const formattedExpirationDate = dayjs(expirationDate).format('MMM DD YYYY')
  const formattedDateRange = `${dayjs(startDate).format(DATE_FORMAT)} - ${dayjs(endDate).format(
    DATE_FORMAT,
  )}`
  const Logo = () => <ReportLogo data-testid="mastercard-logo" src={MasterCardLogoImg} />

  useEffect(() => {
    tracker.trackEvent(INCOME_VERIFICATION_EVENT.EVENT_NAME.VIEW_INCOME_REPORT_STATE, {
      income_report_state: INCOME_VERIFICATION_EVENT.INCOME_REPORT_STATE.REPORT_AVAILABLE,
      view_from: isFromAllTab
        ? INCOME_VERIFICATION_EVENT.VIEW_FROM.ALL_REPORT_TAB
        : INCOME_VERIFICATION_EVENT.VIEW_FROM.INCOME_VERIFICATION_TAB,
    })
  }, [])

  // TODO page number
  return (
    <IncomeVerificationWrapper>
      <ReportWrapper>
        {isShareIncomeVerificationReportEnabled && <HeaderPrint />}
        <ReportsBody>
          <ReportHeader
            label="Income Verification"
            generatedOn={formattedGeneratedDate}
            expiresOn={formattedExpirationDate}
            reportId={reportId}
            CustomLogo={Logo}
          />
          <ReportTitle>Income verification</ReportTitle>
          <ApplicantDetail {...reportApplicantDetail} dateRange={formattedDateRange} />
          <IncomeStreamSummary
            historicalIncomeNet={historicalIncomeNet}
            historicalIncomeGross={historicalIncomeGross}
          />
          <IncomeStreams incomeStreams={incomeStreams} />
          <SummaryByAccount summaryByAccounts={summaryByAccounts}></SummaryByAccount>
          <DepositTransactions depositTransactions={depositTransactions} />
          <ReportFooter />
        </ReportsBody>
      </ReportWrapper>
    </IncomeVerificationWrapper>
  )
}
