import { Grid } from '@mui/material'
import StickyFooterTemplate from '@rentspree/component-2023.components.templates.sticky-footer-template'
import React, { useContext } from 'react'

import { COMPONENT_2023_BREAKPOINT_MOBILE } from 'containers/constants'
import useMobile from 'hooks/use-mobile'

import { PageIndexesContext, PageProgressionContext } from '../../context'

import { CreateButtons } from './utils'

export const RentPaymentFooter = ({ buttonLayout, textOverrides, alsoOnProgressionFunc }) => {
  /*
   * Leverages StickyFooterTemplate for the bottom sticky bar, and then a Grid/flexbox
   * layout for the various button configurations.
   */
  const isMobile = useMobile(COMPONENT_2023_BREAKPOINT_MOBILE)
  const [pageIndexes] = useContext(PageIndexesContext)
  const progressionHandler = useContext(PageProgressionContext)

  /*
   * sometimes drawer data needs to be processed or cleared, depending on increment/decrement values
   * allow the caller to pass a method to call before the page index progressionHandler, and assume
   * it wants to call the progressionHandler as a callback (to avoid race conditions)
   */
  let hybridProgressionHandler = progressionHandler
  if (typeof onNext === 'function') {
    hybridProgressionHandler = args => {
      alsoOnProgressionFunc(args, progressionHandler)
    }
  }

  return (
    <StickyFooterTemplate
      position="fixed"
      variant="navigation"
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        width: isMobile ? '100%' : 'calc(100% - 88px)',
      }}
      className="PaymentBottomFooter"
    >
      <Grid container direction="row" spacing={2}>
        {CreateButtons(pageIndexes, hybridProgressionHandler, buttonLayout, textOverrides)}
      </Grid>
    </StickyFooterTemplate>
  )
}
