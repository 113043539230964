import { takeLatest, put, call } from 'redux-saga/effects'

import { GET_RENTAL_PAYMENT_BY_ID, getRentalPaymentByIdAPI } from '../../actions'
import { getRentalPaymentByIdInstance } from '../../api-instances'

export function* getRentalPaymentByIdSaga({ payload }) {
  yield put(getRentalPaymentByIdAPI.request())
  try {
    const response = yield call(getRentalPaymentByIdInstance, payload)
    yield put(getRentalPaymentByIdAPI.success(response))
  } catch (err) {
    yield put(getRentalPaymentByIdAPI.failure(err))
  }
}

export function* watchGetRentalPaymentByIdSaga() {
  yield takeLatest(GET_RENTAL_PAYMENT_BY_ID, getRentalPaymentByIdSaga)
}
