/* eslint-disable no-underscore-dangle */
import React from 'react'
import { compose } from 'redux'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { Redirect } from 'react-router-dom'
import { query, buildPath } from '@rentspree/path'

import { LoadingWithCenter } from 'components/layout/main'
import { Landing } from 'components/landing'
import EmailVerification from 'containers/email-verification'
import { isMailingAddressCompleted } from 'helpers/mailing-address-checking'
import { REPORT_STEP } from 'legacy/constants/report-consts'
import reportSvg from 'legacy/images/credit-report/report.svg'
import {
  AGREEMENT_PAGE,
  REQUEST_UPDATE_INFO,
  LRA_ESIGN_SIGN_PAGE,
} from 'constants/route'
import { USER_TYPE } from 'constants/user'
import { AGREEMENT_TYPE } from 'containers/agreement/constants'
import { withAgreementReducerAndSaga } from 'containers/agreement/connect'
import { withLraSignReducerAndSaga } from 'containers/application/lra/sign-page/connect'
import PaymentRequire from 'containers/landlord-pay'
import RefundRequire from 'containers/landlord-refund'
import PaymentUpdating from 'containers/landlord-pay/payment-updating'
import { LandlordSkipPaymentPage } from 'containers/landlord-pay/skip-payment'
import locationHref from 'legacy/helpers/location-href'

import { useStatsigFeatureGate } from 'hooks/use-statsig-feature-flag'
import { COMBINED_AGREEMENT_IDV_ADDRESS } from 'utils/feature-flag/constants'
import { AUTH_WEB_VERIFICATION_V2 } from 'env'
import { REPORT_TYPES } from '../../constants'
import { WITH_OPEN_PAGE_STATE } from '../constants'
import { withConnect } from './connect'

import OpenRequiredContainer from './open-required'
import ReportUnavailablePage from './report-unavailable'
import OpenError from './open-error'
import { ReportRequireVerification, ReportVerificationFailed } from './verification'

const { AGREEMENT_PAGE_STATE, MAILING_ADDRESS_PAGE_STATE } =
  WITH_OPEN_PAGE_STATE

export const withOpen = WrappedComponent => props => {
  const {
    currentReportStep,
    isLandlord,
    isRentalSubmissionStatusSubmitted,
    match,
    noneReportPage,
    profile,
    history,
    agreement,
    actions,
    rentalDetail,
    isLoading,
    isRenter,
  } = props
  const { isAccepted, isFetchingIsAccepted } = agreement

  React.useEffect(() => {
    actions.getIsWhitelisting()
    // this condition use for work around that event tracking call twice
    if (!isAccepted) actions.getAcceptAgreementCall({ type: AGREEMENT_TYPE.TU })

    return () => {
      actions.clearAgreementAcceptedStatus()
    }
  }, [])

  const isTypeApplicationMatch = get(match, 'params.type', false) === REPORT_TYPES.APPLICATION
  const isUserTypeLandlord = get(profile, 'userType') === USER_TYPE.LANDLORD
  const isRenterViewReportFirst = rentalDetail?.isRenterViewReportFirst
  const {
    isEnabled: isCombinedAgreementIdvFeatureEnabled,
    isLoading: isCombinedAgreementIdvFeatureLoading,
  } = useStatsigFeatureGate(COMBINED_AGREEMENT_IDV_ADDRESS)
  const shouldRedirectToCombinedPage =
    isCombinedAgreementIdvFeatureEnabled &&
    isUserTypeLandlord &&
    (!isAccepted || !isMailingAddressCompleted(profile)) &&
    !profile.requireVerification

  const { from } = get(history, 'location.state', '')

  const canRenterViewReport = isRenterViewReportFirst && isRenter

  let RenderComponent

  if (
    isLoading ||
    isCombinedAgreementIdvFeatureLoading ||
    currentReportStep === null ||
    isFetchingIsAccepted
  ) {
    RenderComponent = LoadingWithCenter
  } else if (!isRentalSubmissionStatusSubmitted && !canRenterViewReport) {
    RenderComponent = ReportUnavailablePage
  } else if (isTypeApplicationMatch && !isLandlord) {
    // renter can view application when credit report is not opened
    RenderComponent = WrappedComponent
  } else if (
    !isEmpty(from) &&
    (from === AGREEMENT_PAGE_STATE || from === MAILING_ADDRESS_PAGE_STATE)
  ) {
    /** For back to report ready page on TransUnion new flow */
    return (
      <Landing
        title="Report(s) are ready"
        description="Please click the button below to pull the reports from TransUnion."
        buttonName="View Report(s)"
        imgSrc={reportSvg}
        onClick={() => history.push({ pathname: history.location.pathname })}
      />
    )
  } else if (shouldRedirectToCombinedPage) {
    // Redirect to Combined page if not require IDV but not complete TU agreement or mailing address
    locationHref(
      buildPath(AUTH_WEB_VERIFICATION_V2, null, {
        continue: window.location.href,
      }),
    )
  } else if (!isCombinedAgreementIdvFeatureEnabled && !isAccepted && isUserTypeLandlord) {
    return (
      <Redirect
        push
        to={{
          pathname: buildPath(AGREEMENT_PAGE, { type: AGREEMENT_TYPE.TU }),
          search: query.stringify({
            continuePath: history.location.pathname,
          }),
          state: { from: AGREEMENT_PAGE_STATE },
        }}
      />
    )
  } else if (
    !isCombinedAgreementIdvFeatureEnabled &&
    isLandlord &&
    !isMailingAddressCompleted(profile)
  ) {
    // redirect to mailing address page for the agent that doesn't have mailing address data
    // for request screening-option case that include credit report only
    return (
      <Redirect
        push
        to={{
          pathname: REQUEST_UPDATE_INFO,
          search: query.stringify({
            continuePath: history.location.pathname,
          }),
          state: { from: MAILING_ADDRESS_PAGE_STATE },
        }}
      />
    )
  } else {
    const {
      EMAIL_VERIFICATION,
      VERIFICATION,
      VERIFICATION_FAIL,
      REFUND,
      PAYMENT,
      PROCESSING_PAYMENT,
      READY,
      OPEN_ERROR,
      OPENED,
      NONE,
      EXPIRED,
      SIGN,
      SKIP_PAYMENT,
    } = REPORT_STEP
    switch (currentReportStep) {
      case EMAIL_VERIFICATION:
        RenderComponent = EmailVerification
        break
      case VERIFICATION:
        RenderComponent = ReportRequireVerification
        break
      case VERIFICATION_FAIL:
        RenderComponent = ReportVerificationFailed
        break
      case REFUND: // refund page for agent, unavailable for renter
        RenderComponent = isLandlord ? RefundRequire : ReportUnavailablePage
        break
      case SKIP_PAYMENT: // skip payment page for agent, unavailable for renter
        RenderComponent = isLandlord ? LandlordSkipPaymentPage : ReportUnavailablePage
        break
      case PAYMENT: // payment page for agent, unavailable for renter
        RenderComponent = isLandlord ? PaymentRequire : ReportUnavailablePage
        break
      case PROCESSING_PAYMENT: // payment page for agent, unavailable for renter
        RenderComponent = isLandlord ? PaymentUpdating : ReportUnavailablePage
        break
      case READY:
        /**
         * Open report conditions
         * For renter: Once the feature flag is on and payer type for the screening fee is only renter
         * For agent: All cases
         */
        if (canRenterViewReport || isLandlord) {
          RenderComponent = OpenRequiredContainer
        } else {
          RenderComponent = ReportUnavailablePage
        }
        break
      case OPEN_ERROR:
        RenderComponent = isLandlord ? OpenError : ReportUnavailablePage
        break
      case OPENED:
      case NONE:
      case EXPIRED:
        RenderComponent = noneReportPage || WrappedComponent
        break
      case SIGN: {
        const params = {
          propertyId: rentalDetail.property._id,
          rentalId: rentalDetail._id,
          applicationId: rentalDetail.application._id,
        }
        const queryParams = {
          continuePath: history.location.pathname,
        }
        window.location.replace(buildPath(LRA_ESIGN_SIGN_PAGE, params, queryParams))
        break
      }
      default:
        return null
    }
  }

  return <RenderComponent {...props} />
}

// withFeatureFlag needs to be the right most in compose(), so that the function can receive featureFlagProvider value.
export default compose(
  withLraSignReducerAndSaga,
  withAgreementReducerAndSaga,
  withConnect,
  withOpen,
)
