import { useDispatch, useSelector } from 'react-redux'

import { useInjectReducer } from 'utils/inject-reducer'
import { useInjectSaga } from 'utils/inject-saga'
import {
  updateAccountInformation,
  resetUpdateAccountInformation,
} from 'v3/containers/identity-verification/actions'
import { KEY, identityVerificationReducer } from 'v3/containers/identity-verification/reducers'
import { identityVerificationRootSaga } from 'v3/containers/identity-verification/sagas'

import {
  makeSelectUpdateAccountInformationStatus,
  makeSelectUpdateAccountInformationError,
} from '../selectors'

export const useUpdateAccountInformation = () => {
  const dispatch = useDispatch()

  useInjectSaga({ key: KEY.IDENTITY_VERIFICATION, saga: identityVerificationRootSaga })
  useInjectReducer({ key: KEY.IDENTITY_VERIFICATION, reducer: identityVerificationReducer })

  const updateAccountInformationStatus = useSelector(makeSelectUpdateAccountInformationStatus())
  const updateAccountInformationErrors = useSelector(makeSelectUpdateAccountInformationError())

  return {
    updateAccountInformation: payload => dispatch(updateAccountInformation(payload)),
    resetUpdateAccountInformation: () => dispatch(resetUpdateAccountInformation()),
    updateAccountInformationStatus,
    updateAccountInformationErrors,
  }
}
