import { matchPath } from "react-router-dom"
import { TENANT_SCREENING, TENANT_SCREENING_PENDING } from "constants/route"
import { useLastLocation } from "react-router-last-location"

export const useToTenantScreeningListFromPendingPath = () => {
  const lastLocation = useLastLocation()

  if (!matchPath(lastLocation?.pathname, TENANT_SCREENING_PENDING)) return null

  return {
    isRedirect: true,
    path: TENANT_SCREENING,
  }
}
