import React from 'react'
import { useGate } from 'statsig-react'
import { STATSIG_LEGACY_SCREENING_GATE } from 'env'

export const withLegacyScreeningFeatureGate = Component => props => {
  const { value: isLegacyScreening } = useGate(STATSIG_LEGACY_SCREENING_GATE)
  const injectedProps = {
    isLegacyScreening,
  }
  return <Component {...props} {...injectedProps} />
}
