import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useInjectReducer } from 'utils/inject-reducer'
import { useInjectSaga } from 'utils/inject-saga'
import {
  fetchAccountInformation,
  resetAccountInformation,
} from 'v3/containers/identity-verification/actions'
import {
  KEY,
  identityVerificationReducer,
  API_STATUS,
} from 'v3/containers/identity-verification/reducers'
import { identityVerificationRootSaga } from 'v3/containers/identity-verification/sagas'
import {
  makeSelectAccountBusinessType,
  makeSelectAccountInformationStatus,
  makeSelectAccountIdentityVerification,
} from 'v3/containers/identity-verification/selectors'

export const useAccountInformation = () => {
  const dispatch = useDispatch()
  useInjectSaga({ key: KEY.IDENTITY_VERIFICATION, saga: identityVerificationRootSaga })
  useInjectReducer({ key: KEY.IDENTITY_VERIFICATION, reducer: identityVerificationReducer })

  const status = useSelector(makeSelectAccountInformationStatus())
  const businessType = useSelector(makeSelectAccountBusinessType())
  const identityVerification = useSelector(makeSelectAccountIdentityVerification())

  React.useEffect(() => {
    if ([API_STATUS.NONE, API_STATUS.ERROR].includes(status)) {
      dispatch(fetchAccountInformation())
    }
  }, [status])

  return {
    data: {
      businessType,
      identityVerification,
    },
    status,
    resetAccountInformation: () => dispatch(resetAccountInformation()),
  }
}
