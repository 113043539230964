import { put, call, takeLatest, all } from 'redux-saga/effects'
import { apiInstance } from 'utils/api-interceptor'
import { buildPath } from '@rentspree/path'
import { INCOME_ESTIMATE_REPORT_PATH, OPEN_INCOME_ESTIMATE_REPORT_PATH } from './routes'
import { GET_INCOME_ESTIMATE_REPORT, OPEN_INCOME_ESTIMATE_REPORT } from './const'
import { getIncomeEstimateReportSuccess, getIncomeEstimateReportError, openIncomeEstimateReportSuccess } from './actions'

export const getIncomeEstimateReportApi = async rentalSubmissionId => {
  const incomeEstimateReport = await apiInstance.get(
    buildPath(INCOME_ESTIMATE_REPORT_PATH, { rentalSubmissionId }),
  )
  return incomeEstimateReport
}

export const openIncomeEstimateReportApi = async incomeEstimateId => {
  const response = await apiInstance.post(
    buildPath(OPEN_INCOME_ESTIMATE_REPORT_PATH, { incomeEstimateId }),
  )
  return response
}

export function* getIncomeEstimateReportSaga({ payload }) {
  try {
    const incomeEstimateReport = yield call(getIncomeEstimateReportApi, payload.rentalSubmissionId)
    yield put(getIncomeEstimateReportSuccess(incomeEstimateReport.incomeEstimate))
  }
  catch (error) {
    console.error(error)
    yield put(getIncomeEstimateReportError(error))
  }
}

export function* openIncomeEstimateReportSaga({ payload }) {
  try {
    yield call(openIncomeEstimateReportApi, payload.incomeEstimateId)
    yield put(openIncomeEstimateReportSuccess())
  } catch (error) {
    console.error(error)
  }
}

export function* watchGetIncomeEstimateReport() {
  yield takeLatest(GET_INCOME_ESTIMATE_REPORT, getIncomeEstimateReportSaga)
}

export function* watchOpenIncomeEstimateReport() {
  yield takeLatest(OPEN_INCOME_ESTIMATE_REPORT, openIncomeEstimateReportSaga)
}

export default function* rootSaga() {
  yield all([watchGetIncomeEstimateReport(), watchOpenIncomeEstimateReport()])
}
