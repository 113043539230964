import React from 'react'
import { useSelector } from 'react-redux'
import { useGate } from 'statsig-react'

import EmptyState from '@rentspree/component-2023.components.templates.empty-state'
import { Button } from '@rentspree/component-2023.components.atoms.button'
import Box from '@mui/material/Box'

import { Landing } from 'components/landing'
import { PROPERTY_OVERVIEW_PAGE } from 'constants/route'
import HomeImg from 'images/no-listing-home.svg'
import tracker from 'tracker'
import { ZERO_STATE_PROPERTIES } from 'utils/feature-flag/constants'
import { useCreatePropertyHook } from 'v3/containers/create-property-form/hooks/use-create-property-hook'

import { buildPath } from '@rentspree/path'
import { makeSelectIsFetching } from '../selectors'

export const OldLanding = ({ handleAddNewListing }) => (
  <Landing
    withBreadcrumb
    withFilter
    noHeightCal
    title="Add your first listing"
    description="Add a listing to screen tenants, manage leases, and get a Rent Estimate report via RentSpree."
    buttonName="Add new listing"
    buttonID="addNewListingLandingPageBtn"
    imgSrc={HomeImg}
    onClick={handleAddNewListing}
    data-testid="property-list-empty-listing-landing"
  />
)

const LANDING_IMAGE_URL =
  'https://cdn.rentspree.com/static-files/zero-state/property-page-empty-state.svg'

export const NewLandingPropertyList = ({ history }) => {
  const { open, setupCreateProperty } = useCreatePropertyHook()

  const handleAddNewPropertyWithGtmTrack = () => {
    const buttonLocation = 'empty state'
    tracker.trackButtonClick({
      click_text: 'Add property',
      location: buttonLocation,
    })
    const options = {
      location: buttonLocation,
      onSuccessCallback: ({ property }) => {
        // eslint-disable-next-line no-underscore-dangle
        history.push(buildPath(PROPERTY_OVERVIEW_PAGE, { propertyId: property._id }))
      },
    }
    setupCreateProperty(options)
    open()
  }

  return (
    <Box data-testid="property-list-empty-listing-landing">
      <EmptyState
        title="Manage listings, leases, and more"
        subTitle={
          <>
            Market your listings, screen tenants, manage leases, and handle deposits and rent
            payments—all in one place.
            <br />
            Add a property to begin.
          </>
        }
        image={<img src={LANDING_IMAGE_URL} alt="property-page-empty-state" />}
        cta={
          <Button
            id="addNewListingLandingPageBtn"
            data-testid="addNewListingLandingPageBtn"
            variant="contained"
            color="secondary"
            size="small"
            onClick={handleAddNewPropertyWithGtmTrack}
          >
            Add property
          </Button>
        }
      />
    </Box>
  )
}

export const PropertyLandingComponent = ({ hasProperty, handleAddNewListing, history }) => {
  const isFetching = useSelector(makeSelectIsFetching())
  const { value: isZeroStatePropertyPageEnabled } = useGate(ZERO_STATE_PROPERTIES)

  if (isFetching || hasProperty) {
    return null
  }

  if (isZeroStatePropertyPageEnabled) {
    return <NewLandingPropertyList history={history} />
  }

  return <OldLanding handleAddNewListing={handleAddNewListing} />
}
