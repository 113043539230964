import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import get from "lodash/get"
import find from "lodash/find"
import isEmpty from "lodash/isEmpty"
import { ANIMATE, COLOR, BORDER_RADIUS } from "styles/settings"
import iconFunnel from "images/icons/funnel.svg"
import { Icon } from "@rentspree/component-v2/dist/icon"
import { TextWithIcon } from "components/molecules/text-with-icon"
import iconAll from "images/icons/application/application-all.png"

import OutsideClickHandler from "../outside-handler/outside-click-handler"

export const DropdownMenuItem = styled.li`
  cursor: pointer;
  font-weight: normal;

  &.-name {
    display: none;
    cursor: auto;
    padding-top: 5px;
    i {
      font-size: 0.8em;
    }
    @media (max-width: 991px) {
      display: none;
    }
  }

  &.-menu {
    display: none;
    @media (max-width: 991px) {
      display: block;
    }
  }

  width: 100%;
  color: ${COLOR.textBlack};
  display: block;
  padding: 6px 15px;
  transition: all 0.25s ease-in-out 0s;

  img {
    width: 20px;
    margin-right: 10px;
  }

  i {
    margin-right: 10px;
  }

  &:hover {
    background-color: ${COLOR.bgGrey};
  }

  @media (max-width: 991px) {
    letter-spacing: 1px;
    font-weight: normal;
    font-size: 16px;
    border-radius: 0;
    @include transition(padding, $anim-fast);
    padding: 10px;
    display: flex;
    align-items: center;

    img {
      width: 20px;
      margin-right: 10px;
    }

    i {
      display: ${props => (props.newIconOption ? "block" : "none")};
    }

    &:hover {
      padding-left: 30px;
    }
  }
`

export const MobileStatusFilter = styled.div`
  height: 100%;
  display: none;
  @media (max-width: 991px) {
    display: block;
  }
`

export const StatusFilterToggle = styled.button`
  background: ${COLOR.grayLight};
  border: none;
  height: 40px;
  width: 100px;
  padding: 0;
  text-align: center;
  line-height: 48px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border: 1px solid ${COLOR.borderGrey};
  border-left: 0px;
  img {
    width: 20px;
  }
`
export const MobileStatusList = styled.ul`
  @media (max-width: 991px) {
    background: ${COLOR.white};
    border: 1px solid ${COLOR.lineGrey};
    border-radius: ${BORDER_RADIUS};
    z-index: ${props => (props.isOpen ? "1" : "-1")};
    opacity: ${props => (props.isOpen ? "1" : "0")};
    transform: ${props =>
    props.isOpen ? "translateY(0)" : "translateY(-10%)"};
    padding: 5px 0;
    position: absolute;
    overflow: hidden;
    margin-top: 5px;
    right: 5px;
    width: 300px;
    transition: all ease-in ${ANIMATE.fast};
  }
`

export const getIconStatus = (status, options) => {
  const item = find(options, { value: status }, {}) // get(options, ["status"], iconAll)
  const selectIconAll =
    get(find(options, { value: "all" }, {}, "icon")) || iconAll
  if (!isEmpty(item)) {
    return get(item, "icon") || selectIconAll
  }
  return selectIconAll
}

// TODO: replace static image to Icon from component V2 once the icons are ready for application list
export const FilterMobile = props => {
  const [statusToggle, setStatusToggle] = useState(false)
  const { status, onChangeStatus, options, newIconOption, icon, noIconOnMobileSelected } = props
  const closeMobileStatusList = () => {
    setStatusToggle(false)
  }

  const toggleMobileStatusList = () => {
    setStatusToggle(!statusToggle)
  }

  const handleClick = value => {
    onChangeStatus({ value })
    closeMobileStatusList()
  }

  const statusLabel = options?.find(option => option.value === status)?.label || ''

  const selectedFilter = noIconOnMobileSelected ? statusLabel : <img src={getIconStatus(status, options)} alt={status} />
  const selectedFilterWithIcon = noIconOnMobileSelected ? statusLabel : <Icon icon={icon} color="black" size="lg" />

  const renderStatusFilterToggle = onClick => (
    <StatusFilterToggle onClick={onClick} type="button" id="statusFilterToggle">
      {status ? (
        selectedFilter
      ) : (
        <img src={iconFunnel} alt="filter rental submission" />
      )}
    </StatusFilterToggle>
  )
  const renderStatusFilterToggleWithIcon = onClick => (
    <StatusFilterToggle onClick={onClick} type="button" id="statusFilterToggle">
      {status ? (
        selectedFilterWithIcon
      ) : (
        <Icon icon="menu-listing" color="black" />
      )}
    </StatusFilterToggle>
  )
  return (
    <MobileStatusFilter>
      {statusToggle ? (
        <div>
          {newIconOption
            ? renderStatusFilterToggleWithIcon()
            : renderStatusFilterToggle()}
          <OutsideClickHandler handleOutsideClick={closeMobileStatusList}>
            <MobileStatusList
              className="list-unstyled"
              id="mobileStatusList"
              isOpen={statusToggle}>
              {options.map(({ value, label, icon: iconOption, iconColor }) => (
                <DropdownMenuItem
                  key={value}
                  newIconOption
                  id={`${value}StatusBtn`}
                  onClick={() => handleClick(value)}>
                  {newIconOption ? (
                    <TextWithIcon
                      text={label}
                      icon={iconOption}
                      iconColor={iconColor}
                    />
                  ) : (
                    <div>
                      <img src={getIconStatus(value, options)} alt={value} />
                      {label}
                    </div>
                  )}
                </DropdownMenuItem>
              ))}
            </MobileStatusList>
          </OutsideClickHandler>
        </div>
      ) : (
        <div>
          {newIconOption
            ? renderStatusFilterToggleWithIcon(toggleMobileStatusList)
            : renderStatusFilterToggle(toggleMobileStatusList)}
        </div>
      )}
    </MobileStatusFilter>
  )
}

FilterMobile.propTypes = {
  onChangeStatus: PropTypes.func.isRequired,
  status: PropTypes.string,
  options: PropTypes.array,
}

FilterMobile.defaultProps = {
  status: "",
  options: [],
}

export default FilterMobile
